module.exports.alerts = {
  ru: {
    processing: "Идет обработка...",
    longProcessing:
      "Обработка займет более одной минуты. Через несколько минут обновите страницу для отображения актуальных данных",
    userExist: "Пользователь с таким Email уже зарегистрирован",
    clientExist: "Клиент с таким номером телефона уже зарегистрирован",
    notAuthorized: "Необходима авторизация",
    tooManyReq: "Превышен лимит запросов. Повторите попытку через",
    badAuth: "Не верный логин или пароль. Проверьте данные и повторите попытку",
    badReq: "Не верный запрос. Проверьте данные и повторите попытку",
    serverError: "Ошибка сервера. Повторите попытку позже",
    userNotFound:
      "Пользователя с таким email не найдено. Проверьте данные и повторите попытку",
    registerErr:
      "Ошибка обработки реестра. Проверьте соответствие serialNumber и passTypeId (templateId при наличии нескольких)",
    cardRegistryEmpty: "Карты не найдены",
    successSend: "Сообщение успешно отправлено",
    formError: "Проверьте правильность заполнения данных.",
    successCreated: "Карта успешно создана.",
    successChanged: "Карта успешно изменена.",
    successDeleted: "Карта успешно удалена.",
    success: "Успех!",
  },
  uk: {
    processing: "Обробка...",
    longProcessing:
      "Обробка займе більше однієї хвилини. Через кілька хвилин поновіть сторінку для відображення актуальних даних",
    userExist: "Користувач з таким Email вже зареєстрований",
    clientExist: "Клієнт з таким номером телефону вже зареєстрований",
    notAuthorized: "Необхідна авторизація",
    tooManyReq: "Перевищено ліміт запитів. Повторіть спробу через",
    badReq: "Невірний запит. Перевірте дані та повторіть спробу",
    badAuth: "Невірний логін або пароль. Перевірте дані та повторіть спробу",
    serverError: "Помилка серверу. Повторіть спробу пізніше",
    userNotFound:
      "Користувача з таким email не знайдено. Перевірте дані та повторіть спробу",
    registerErr:
      "Помилка обробки реєстру. Перевірте відповідність serialNumber та passTypeId (templateId при наявності декількох)",
    cardRegistryEmpty: "Карток не знайдено",
    successSend: "Повідомлення успішно надіслано",
    formError: "Перевірте корректність введення даних.",
    successCreated: "Картку створено.",
    successChanged: "Картку змінено.",
    successDeleted: "Картку видалено.",
    success: "Успіх!",
  },
  en: {
    processing: "Processing...",
    longProcessing:
      "Processing may take more than one minute. Please refresh the page in a few minutes to see the updated data.",
    userExist: "A user with this Email is already registered",
    clientExist: "A client with this phone number is already registered",
    notAuthorized: "Authorization required",
    tooManyReq: "Request limit exceeded. Please try again after",
    badAuth:
      "Incorrect login or password. Please check your credentials and try again",
    badReq: "Incorrect request. Please check your data and try again",
    serverError: "Server error. Please try again later",
    userNotFound:
      "User with this email not found. Please check your data and try again",
    registerErr:
      "Registry processing error. Please check the correspondence of serialNumber and passTypeId (templateId if multiple)",
    cardRegistryEmpty: "No cards found",
    successSend: "Message sent successfully",
    formError: "Please check the correctness of the entered data.",
    successCreated: "Card created successfully.",
    successChanged: "Card changed successfully.",
    successDeleted: "Card deleted successfully.",
    success: "Success!",
  },
  pl: {
    processing: "Przetwarzanie...",
    longProcessing:
      "Przetwarzanie może zająć więcej niż jedną minutę. Proszę odświeżyć stronę za kilka minut, aby zobaczyć aktualne dane.",
    userExist: "Użytkownik o tym adresie e-mail jest już zarejestrowany",
    clientExist: "Klient o tym numerze telefonu jest już zarejestrowany",
    notAuthorized: "Wymagane uwierzytelnienie",
    tooManyReq: "Przekroczono limit żądań. Spróbuj ponownie za",
    badAuth: "Nieprawidłowy login lub hasło. Sprawdź dane i spróbuj ponownie",
    badReq: "Nieprawidłowe żądanie. Sprawdź dane i spróbuj ponownie",
    serverError: "Błąd serwera. Spróbuj ponownie później",
    userNotFound:
      "Nie znaleziono użytkownika o tym adresie e-mail. Sprawdź dane i spróbuj ponownie",
    registerErr:
      "Błąd przetwarzania rejestru. Sprawdź zgodność numeru seryjnego i identyfikatora typu karty (identyfikator szablonu, jeśli istnieje wiele)",
    cardRegistryEmpty: "Nie znaleziono kart",
    successSend: "Wiadomość wysłana pomyślnie",
    formError: "Proszę sprawdzić poprawność wprowadzonych danych.",
    successCreated: "Karta utworzona pomyślnie.",
    successChanged: "Karta zmieniona pomyślnie.",
    successDeleted: "Karta usunięta pomyślnie.",
    success: "Sukces!",
  },
  de: {
    processing: "Verarbeitung...",
    longProcessing:
      "Die Verarbeitung kann länger als eine Minute dauern. Bitte aktualisieren Sie die Seite in ein paar Minuten, um die aktualisierten Daten anzuzeigen.",
    userExist: "Ein Benutzer mit dieser E-Mail-Adresse ist bereits registriert",
    clientExist: "Ein Kunde mit dieser Telefonnummer ist bereits registriert",
    notAuthorized: "Autorisierung erforderlich",
    tooManyReq: "Anforderungslimit überschritten. Bitte versuchen Sie es nach",
    badAuth:
      "Falscher Benutzername oder falsches Passwort. Bitte überprüfen Sie Ihre Zugangsdaten und versuchen Sie es erneut",
    badReq:
      "Ungültige Anforderung. Bitte überprüfen Sie Ihre Daten und versuchen Sie es erneut",
    serverError: "Serverfehler. Bitte versuchen Sie es später erneut",
    userNotFound:
      "Benutzer mit dieser E-Mail-Adresse nicht gefunden. Bitte überprüfen Sie Ihre Daten und versuchen Sie es erneut",
    registerErr:
      "Fehler bei der Registrierungsverarbeitung. Bitte überprüfen Sie die Übereinstimmung von Seriennummer und PassTypeId (TemplateId bei mehreren)",
    cardRegistryEmpty: "Keine Karten gefunden",
    successSend: "Nachricht erfolgreich gesendet",
    formError: "Bitte überprüfen Sie die Richtigkeit der eingegebenen Daten.",
    successCreated: "Karte erfolgreich erstellt.",
    successChanged: "Karte erfolgreich geändert.",
    successDeleted: "Karte erfolgreich gelöscht.",
    success: "Erfolg!",
  },
  es: {
    processing: "Procesando...",
    longProcessing:
      "El procesamiento puede tardar más de un minuto. Por favor, actualiza la página en unos minutos para ver los datos actualizados.",
    userExist: "Ya existe un usuario con este correo electrónico",
    clientExist: "Ya existe un cliente con este número de teléfono",
    notAuthorized: "Se requiere autorización",
    tooManyReq:
      "Se ha excedido el límite de solicitudes. Por favor, inténtalo de nuevo en",
    badAuth:
      "Usuario o contraseña incorrectos. Por favor, verifica tus credenciales e intenta de nuevo",
    badReq:
      "Solicitud incorrecta. Por favor, verifica tus datos e intenta de nuevo",
    serverError: "Error del servidor. Por favor, inténtalo de nuevo más tarde",
    userNotFound:
      "No se encontró ningún usuario con este correo electrónico. Por favor, verifica tus datos e intenta de nuevo",
    registerErr:
      "Error en el procesamiento del registro. Por favor, verifica la correspondencia del número de serie y el tipo de pase (Id de plantilla si hay varios)",
    cardRegistryEmpty: "No se encontraron tarjetas",
    successSend: "Mensaje enviado correctamente",
    formError: "Por favor, verifica la corrección de los datos ingresados.",
    successCreated: "Tarjeta creada correctamente.",
    successChanged: "Tarjeta modificada correctamente.",
    successDeleted: "Tarjeta eliminada correctamente.",
    success: "¡Éxito!",
  },
};

module.exports.header = {
  ru: {
    main: "Главная",
    registers: "Реестры",
    cards: "Карты",
    distribution: "Распространение карт",
    push_message: "Уведомления",
    statistic: "Статистика",
    loyalty: "Лояльность",
    settings: "Настройки",
    users: "Пользователи",
    posts: "Посты",
    exit: "Выход",
  },
  uk: {
    main: "Головна",
    registers: "Реєстри",
    cards: "Картки",
    distribution: "Розповсюдження карток",
    push_message: "Повідомлення",
    statistic: "Статистика",
    loyalty: "Лояльність",
    settings: "Налаштування",
    users: "Користувачі",
    posts: "Пости",
    exit: "Вихід",
  },
  en: {
    main: "Main",
    registers: "Registers",
    cards: "Cards",
    distribution: "Card Distribution",
    push_message: "Notifications",
    statistic: "Statistics",
    loyalty: "Loyalty",
    settings: "Settings",
    users: "Users",
    posts: "Posts",
    exit: "Exit",
  },
  pl: {
    main: "Główna",
    registers: "Rejestry",
    cards: "Karty",
    distribution: "Rozpowszechnianie kart",
    push_message: "Powiadomienia",
    statistic: "Statystyka",
    loyalty: "Lojalność",
    settings: "Ustawienia",
    users: "Użytkownicy",
    posts: "Posty",
    exit: "Wyjście",
  },
  de: {
    main: "Hauptseite",
    registers: "Register",
    cards: "Karten",
    distribution: "Kartenausgabe",
    push_message: "Benachrichtigungen",
    statistic: "Statistik",
    loyalty: "Treue",
    settings: "Einstellungen",
    users: "Benutzer",
    posts: "Beiträge",
    exit: "Beenden",
  },
  es: {
    main: "Principal",
    registers: "Registros",
    cards: "Tarjetas",
    distribution: "Distribución de tarjetas",
    push_message: "Notificaciones",
    statistic: "Estadísticas",
    loyalty: "Lealtad",
    settings: "Configuración",
    users: "Usuarios",
    posts: "Publicaciones",
    exit: "Salir",
  },
};

module.exports.settingsSegment = {
  ru: {
    name: "Наименование сегмента",
    description: "Описание",
    passTypeIdentifier: "Pass Type ID",
    templateId: "Шаблон карт",
    gender: "Пол клиента",
    lastVisit: "Дней с даты последнего визита",
    source: "Источник выпуска карты",
    status: "Статус клиента",
    balance: "Баланс клиента",
    discount: "Размер скидки",
    installed: "Устройство, на которое установлена карта",
    referrer: "Привлек ли клиент новых клиентов",
    createdByUser: "Пользователь, выпустивший карту",
    department: "Отдел / департамент, выпустивший карту",
    indicator: "Показатель",
    operator: "Критерий",
    value: "Значение",
    less: "меньше",
    more: "больше",
    equal: "равно",
    m: "мужской",
    f: "женский",
    active: "активный",
    inactive: "неактивный",
    true: "да",
    false: "нет",
  },
  uk: {
    name: "Назва сегмента",
    description: "Опис",
    passTypeIdentifier: "Pass Type ID",
    templateId: "Шаблон картки",
    gender: "Стать клієнта",
    lastVisit: "Днів з дати останнього візиту",
    source: "Джерело випуску картки",
    status: "Статус клієнта",
    balance: "Баланс клієнта",
    discount: "Розмір знижки",
    installed: "Пристрій, на якому встановлена картка",
    referrer: "Привів клієнт нових клієнтів",
    createdByUser: "Користувач, який випустив картку",
    department: "Відділ / департамент, який випустив картку",
    indicator: "Показник",
    operator: "Критерій",
    value: "Значення",
    less: "менше",
    more: "більше",
    equal: "рівне",
    m: "чоловічий",
    f: "жіночий",
    active: "активний",
    inactive: "неактивний",
    true: "так",
    false: "ні",
  },
  en: {
    name: "Segment Name",
    description: "Description",
    passTypeIdentifier: "Pass Type ID",
    templateId: "Card Template",
    gender: "Customer Gender",
    lastVisit: "Days Since Last Visit",
    source: "Card Issuance Source",
    status: "Customer Status",
    balance: "Customer Balance",
    discount: "Discount Size",
    installed: "Device Installed with Card",
    referrer: "Did the Customer Refer New Customers",
    createdByUser: "User Who Issued the Card",
    department: "Department/Division Who Issued the Card",
    indicator: "Indicator",
    operator: "Operator",
    value: "Value",
    less: "less",
    more: "more",
    equal: "equal",
    m: "male",
    f: "female",
    active: "active",
    inactive: "inactive",
    true: "yes",
    false: "no",
  },
  pl: {
    name: "Nazwa segmentu",
    description: "Opis",
    passTypeIdentifier: "Pass Type ID",
    templateId: "Szablon karty",
    gender: "Płeć klienta",
    lastVisit: "Dni od ostatniej wizyty",
    source: "Źródło wydania karty",
    status: "Status klienta",
    balance: "Saldo klienta",
    discount: "Rozmiar rabatu",
    installed: "Urządzenie, na którym zainstalowana jest karta",
    referrer: "Czy klient polecił nowych klientów",
    createdByUser: "Użytkownik, który wydał kartę",
    department: "Dział/Dział, który wydał kartę",
    indicator: "Wskaźnik",
    operator: "Operator",
    value: "Wartość",
    less: "mniej",
    more: "więcej",
    equal: "równe",
    m: "mężczyzna",
    f: "kobieta",
    active: "aktywny",
    inactive: "nieaktywny",
    true: "tak",
    false: "nie",
  },
  de: {
    name: "Segmentname",
    description: "Beschreibung",
    passTypeIdentifier: "Pass Type ID",
    templateId: "Kartenvorlage",
    gender: "Geschlecht des Kunden",
    lastVisit: "Tage seit dem letzten Besuch",
    source: "Kartenausgabestelle",
    status: "Kundenstatus",
    balance: "Kundenkonto",
    discount: "Rabattgröße",
    installed: "Gerät, auf dem die Karte installiert ist",
    referrer: "Hat der Kunde neue Kunden geworben",
    createdByUser: "Benutzer, der die Karte ausgegeben hat",
    department: "Abteilung/Bereich, der die Karte ausgegeben hat",
    indicator: "Indikator",
    operator: "Operator",
    value: "Wert",
    less: "weniger",
    more: "mehr",
    equal: "gleich",
    m: "männlich",
    f: "weiblich",
    active: "aktiv",
    inactive: "inaktiv",
    true: "ja",
    false: "nein",
  },
  es: {
    name: "Nombre del segmento",
    description: "Descripción",
    passTypeIdentifier: "Identificador del tipo de pase",
    templateId: "Plantilla de tarjeta",
    gender: "Género del cliente",
    lastVisit: "Días desde la última visita",
    source: "Fuente de emisión de la tarjeta",
    status: "Estado del cliente",
    balance: "Saldo del cliente",
    discount: "Tamaño del descuento",
    installed: "Dispositivo en el que está instalada la tarjeta",
    referrer: "¿El cliente ha referido a nuevos clientes?",
    createdByUser: "Usuario que emitió la tarjeta",
    department: "Departamento/División que emitió la tarjeta",
    indicator: "Indicador",
    operator: "Operador",
    value: "Valor",
    less: "menos",
    more: "más",
    equal: "igual",
    m: "masculino",
    f: "femenino",
    active: "activo",
    inactive: "inactivo",
    true: "sí",
    false: "no",
  },
};

module.exports.billing = {
  ru: {
    tariffs: "Тарифы",
    base: "БАЗОВЫЙ (до 1000 карт)",
    loyalty: "ПРОГРАМА ЛОЯЛЬНОСТИ",
    marketing: "МАРКЕТИНГ",
    integration: "ИНТЕГРАЦИЯ",
    white_label: "WHITE LABEL",
    additionalCards: "Дополнительные карты",
    cards: "карт",
    tariffDescription: "Стоимость и описание тарифов",
    nextPayment: "Следующий платеж",
    amountQuarter: "К оплате за 3 месяца",
    amountYear: "К оплате за год",
    askBill: "Запросить счет на оплату",
    askQuarterBill: "Счет на 3 месяца",
    askYearBill: "Счет на год (-20%)",
    payByCard: "Оплатить картой",
    payByCardQuarter: "Оплатить за 3 месяца",
    payByCardYear: "Оплатить за год (-20%)",
  },
  uk: {
    tariffs: "Тарифи",
    base: "БАЗОВИЙ (до 1000 карт)",
    loyalty: "ПРОГРАМА ЛОЯЛЬНОСТІ",
    marketing: "МАРКЕТИНГ",
    integration: "ІНТЕГРАЦІЯ",
    white_label: "WHITE LABEL",
    additionalCards: "Додаткові картки",
    cards: "карток",
    tariffDescription: "Вартість та опис тарифів",
    nextPayment: "Наступний платіж",
    amountQuarter: "До сплати за 3 місяці",
    amountYear: "До сплати за рік",
    askBill: "Запросити рахунок на оплату",
    askQuarterBill: "Рахунок на 3 місяці",
    askYearBill: "Рахунок на рік (-20%)",
    payByCard: "Сплатити карткою",
    payByCardQuarter: "Сплатити за 3 місяці",
    payByCardYear: "Сплатити за рік (-20%)",
  },
  en: {
    tariffs: "Tariffs",
    base: "BASE (up to 1000 cards)",
    loyalty: "LOYALTY PROGRAM",
    marketing: "MARKETING",
    integration: "INTEGRATION",
    white_label: "WHITE LABEL",
    additionalCards: "Additional Cards",
    cards: "cards",
    tariffDescription: "Tariff Cost and Description",
    nextPayment: "Next Payment",
    amountQuarter: "Amount for 3 months",
    amountYear: "Amount for a year",
    askBill: "Request an Invoice",
    askQuarterBill: "Invoice for 3 months",
    askYearBill: "Invoice for a year (-20%)",
    payByCard: "Pay by Card",
    payByCardQuarter: "Pay for 3 months",
    payByCardYear: "Pay for a year (-20%)",
  },
  pl: {
    tariffs: "Taryfy",
    base: "PODSTAWOWY (do 1000 kart)",
    loyalty: "PROGRAM LOJALNOŚCI",
    marketing: "MARKETING",
    integration: "INTEGRACJA",
    white_label: "WHITE LABEL",
    additionalCards: "Dodatkowe Karty",
    cards: "kart",
    tariffDescription: "Koszt i opis taryf",
    nextPayment: "Następna płatność",
    amountQuarter: "Kwota za 3 miesiące",
    amountYear: "Kwota za rok",
    askBill: "Poproś o fakturę",
    askQuarterBill: "Faktura na 3 miesiące",
    askYearBill: "Faktura na rok (-20%)",
    payByCard: "Zapłać kartą",
    payByCardQuarter: "Zapłać za 3 miesiące",
    payByCardYear: "Zapłać za rok (-20%)",
  },
  de: {
    tariffs: "Tarife",
    base: "BASIS (bis zu 1000 Karten)",
    loyalty: "LOYALITÄTSPROGRAMM",
    marketing: "MARKETING",
    integration: "INTEGRATION",
    white_label: "WHITE LABEL",
    additionalCards: "Zusätzliche Karten",
    cards: "Karten",
    tariffDescription: "Tarifkosten und Beschreibung",
    nextPayment: "Nächste Zahlung",
    amountQuarter: "Betrag für 3 Monate",
    amountYear: "Betrag für ein Jahr",
    askBill: "Rechnung anfordern",
    askQuarterBill: "Rechnung für 3 Monate",
    askYearBill: "Rechnung für ein Jahr (-20%)",
    payByCard: "Mit Karte bezahlen",
    payByCardQuarter: "Für 3 Monate bezahlen",
    payByCardYear: "Für ein Jahr bezahlen (-20%)",
  },
  es: {
    tariffs: "Tarifas",
    base: "BÁSICO (hasta 1000 tarjetas)",
    loyalty: "PROGRAMA DE LEALTAD",
    marketing: "MARKETING",
    integration: "INTEGRACIÓN",
    white_label: "WHITE LABEL",
    additionalCards: "Tarjetas adicionales",
    cards: "tarjetas",
    tariffDescription: "Costo y descripción de tarifas",
    nextPayment: "Próximo pago",
    amountQuarter: "Importe por 3 meses",
    amountYear: "Importe por año",
    askBill: "Solicitar factura",
    askQuarterBill: "Factura por 3 meses",
    askYearBill: "Factura por año (-20%)",
    payByCard: "Pagar con tarjeta",
    payByCardQuarter: "Pagar por 3 meses",
    payByCardYear: "Pagar por año (-20%)",
  },
};

module.exports.settingsHeader = {
  ru: {
    general: "Общие настройки",
    design: "Дизайн",
    marketing: "Маркетинг",
    loyalty: "Лояльность",
    billing: "Биллинг",
    selfCreation: "Клиент самостоятельно заполняет анкету для регистрации",
    language: "Установить язык анкеты",
    city: "Город",
    arrayData: "введите через запятую",
    clientData: "Данные клиента",
    formData: "Форма регистрации",
    additionalData: "Дополнительная информация",
    expirationDate: "Действует до",
    period: "Период действия",
    category: "Категория",
    photo: "Фото",
    defaultData: "Данные по умолчанию",
    balance: "Баланс при получении карты",
    discount: "Скидка при получении карты",
    currentNumber: "Текущий номер карты",
    loyaltyProgram: "Программа лояльности",
    bonusSystem: "Бонусная система",
    discountSystem: "Скидочная система",
    stampSystem: "Штамп-карты",
    bonusPercent: "Процент начисления",
    discountPercent: "Процент скидки",
    freeNumber: "Номер бесплатного продукта",
    scan: "Сканировать телефоном / сканером (url)",
    active: "Активировано",
    segment: "Сегментация клиентов",
    birthday: "Поздравления с днем рождения",
    bDayBeforeDays: "Дней до ДР",
    bDayBeforeText: "Текст предложения",
    birthDayText: "Текст поздравления",
    bDayAfterDays: "Дней после ДР",
    bDayAfterText: "Напоминание о предложении",
    regularMessage: "Регулярные сообщения",
    regularMessageName: "Наименование сообщения",
    regularMessageDescription: "Описание сообщения",
    regularMessageTime: "Час рассылки сообщения",
    regularMessageDate: "Дата рассылки сообщения",
    regularMessageWeekday: "День недели",
    regularMessageNthWeek: "Номер недели в месяце",
    regularMessageSegment: "Сегмент клиентов",
    regularMessageText: "Текст сообщений",
  },
  uk: {
    general: "Загальні налаштування",
    design: "Дизайн",
    marketing: "Маркетинг",
    loyalty: "Лояльність",
    billing: "Біллінг",
    selfCreation: "Клієнт самостійно заповнює анкету для реєстрації",
    language: "Встановити мову анкети",
    city: "Місто",
    arrayData: "введіть через кому",
    clientData: "Клієнтські данні",
    formData: "Форма реєстрації",
    additionalData: "Додаткова інформація",
    expirationDate: "Діє до",
    period: "Період дії",
    category: "Категорія",
    photo: "Фото",
    defaultData: "Данні за замовченням",
    balance: "Баланс при отриманні картки",
    discount: "Знижка при отриманні картки",
    currentNumber: "Поточний номер картки",
    loyaltyProgram: "Програма лояльності",
    bonusSystem: "Бонусна система",
    discountSystem: "Знижкова система",
    stampSystem: "Штамп-картки",
    bonusPercent: "Процент нарахування",
    discountPercent: "Процент знижки",
    freeNumber: "Номер безкоштовного продукту",
    scan: "Сканування телефоном / сканером (url)",
    active: "Активовано",
    segment: "Сегментування клієнтів",
    birthday: "Вітання з днем народження",
    bDayBeforeDays: "Днів до ДН",
    bDayBeforeText: "Текст пропозиції",
    birthDayText: "Текст вітання",
    bDayAfterDays: "Днів після ДН",
    bDayAfterText: "Нагадування про пропозицію",
    regularMessage: "Регулярні повідомлення",
    regularMessageName: "Назва повідомлення",
    regularMessageDescription: "Опис повідомлення",
    regularMessageTime: "Час розсилки повідомлення",
    regularMessageDate: "Дата розсилки повідомлення",
    regularMessageWeekday: "День тижня",
    regularMessageNthWeek: "Номер тижня в місяці",
    regularMessageSegment: "Сегмент клієнтів",
    regularMessageText: "Текст повідомлення",
  },
  en: {
    general: "General Settings",
    design: "Design",
    marketing: "Marketing",
    loyalty: "Loyalty",
    billing: "Billing",
    selfCreation: "Client fills out the registration form independently",
    language: "Set form language",
    city: "City",
    arrayData: "enter separated by commas",
    clientData: "Client Data",
    formData: "Registration Form",
    additionalData: "Additional Information",
    expirationDate: "Expires on",
    period: "Validity Period",
    category: "Category",
    photo: "Photo",
    defaultData: "Default Data",
    balance: "Balance upon card issuance",
    discount: "Discount upon card issuance",
    currentNumber: "Current card number",
    loyaltyProgram: "Loyalty Program",
    bonusSystem: "Bonus System",
    discountSystem: "Discount System",
    stampSystem: "Stamp Cards",
    bonusPercent: "Accrual Percentage",
    discountPercent: "Discount Percentage",
    freeNumber: "Free Product Number",
    scan: "Scan with Phone/Scanner (URL)",
    active: "Activated",
    segment: "Customer Segmentation",
    birthday: "Birthday Greetings",
    bDayBeforeDays: "Days Before Birthday",
    bDayBeforeText: "Offer Text",
    birthDayText: "Greeting Text",
    bDayAfterDays: "Days After Birthday",
    bDayAfterText: "Reminder of the Offer",
    regularMessage: "Regular Messages",
    regularMessageName: "Message Name",
    regularMessageDescription: "Message Description",
    regularMessageTime: "Message Delivery Time",
    regularMessageDate: "Message Delivery Date",
    regularMessageWeekday: "Weekday",
    regularMessageNthWeek: "Week Number in Month",
    regularMessageSegment: "Customer Segment",
    regularMessageText: "Message Text",
  },
  pl: {
    general: "Ustawienia ogólne",
    design: "Wygląd",
    marketing: "Marketing",
    loyalty: "Lojalność",
    billing: "Rozliczenia",
    selfCreation: "Klient samodzielnie wypełnia formularz rejestracyjny",
    language: "Ustaw język formularza",
    city: "Miasto",
    arrayData: "wprowadź oddzielone przecinkami",
    clientData: "Dane klienta",
    formData: "Formularz rejestracyjny",
    additionalData: "Dodatkowe informacje",
    expirationDate: "Ważne do",
    period: "Okres ważności",
    category: "Kategoria",
    photo: "Zdjęcie",
    defaultData: "Dane domyślne",
    balance: "Saldo przy wydawaniu karty",
    discount: "Rabat przy wydawaniu karty",
    currentNumber: "Bieżący numer karty",
    loyaltyProgram: "Program lojalnościowy",
    bonusSystem: "System bonusowy",
    discountSystem: "System rabatowy",
    stampSystem: "Karty z pieczątkami",
    bonusPercent: "Procent naliczenia",
    discountPercent: "Procent rabatu",
    freeNumber: "Numer darmowego produktu",
    scan: "Skanowanie telefonem / skanerem (URL)",
    active: "Aktywowane",
    segment: "Segmentacja klientów",
    birthday: "Urodzinowe życzenia",
    bDayBeforeDays: "Dni przed urodzinami",
    bDayBeforeText: "Tekst oferty",
    birthDayText: "Tekst życzeń",
    bDayAfterDays: "Dni po urodzinach",
    bDayAfterText: "Przypomnienie o ofercie",
    regularMessage: "Regularne wiadomości",
    regularMessageName: "Nazwa wiadomości",
    regularMessageDescription: "Opis wiadomości",
    regularMessageTime: "Czas dostarczania wiadomości",
    regularMessageDate: "Data dostarczania wiadomości",
    regularMessageWeekday: "Dzień tygodnia",
    regularMessageNthWeek: "Numer tygodnia w miesiącu",
    regularMessageSegment: "Segment klientów",
    regularMessageText: "Treść wiadomości",
  },
  de: {
    general: "Allgemeine Einstellungen",
    design: "Design",
    marketing: "Marketing",
    loyalty: "Treue",
    billing: "Abrechnung",
    selfCreation: "Kunde füllt das Anmeldeformular selbstständig aus",
    language: "Formularsprache einstellen",
    city: "Stadt",
    arrayData: "durch Kommas getrennt eingeben",
    clientData: "Kundendaten",
    formData: "Anmeldeformular",
    additionalData: "Zusätzliche Informationen",
    expirationDate: "Läuft ab am",
    period: "Gültigkeitsdauer",
    category: "Kategorie",
    photo: "Foto",
    defaultData: "Standarddaten",
    balance: "Guthaben bei Kartenausgabe",
    discount: "Rabatt bei Kartenausgabe",
    currentNumber: "Aktuelle Kartennummer",
    loyaltyProgram: "Treueprogramm",
    bonusSystem: "Bonus-System",
    discountSystem: "Rabatt-System",
    stampSystem: "Stempelkarten",
    bonusPercent: "Gutschrift-Prozentsatz",
    discountPercent: "Rabatt-Prozentsatz",
    freeNumber: "Nummer für kostenloses Produkt",
    scan: "Mit Telefon/Scanner scannen (URL)",
    active: "Aktiviert",
    segment: "Kundensegmentierung",
    birthday: "Geburtstagsgrüße",
    bDayBeforeDays: "Tage vor dem Geburtstag",
    bDayBeforeText: "Angebotstext",
    birthDayText: "Gratulationstext",
    bDayAfterDays: "Tage nach dem Geburtstag",
    bDayAfterText: "Erinnerung an das Angebot",
    regularMessage: "Reguläre Nachrichten",
    regularMessageName: "Nachrichtenname",
    regularMessageDescription: "Nachrichtenbeschreibung",
    regularMessageTime: "Nachrichtenversandzeit",
    regularMessageDate: "Nachrichtenversanddatum",
    regularMessageWeekday: "Wochentag",
    regularMessageNthWeek: "Wochennummer im Monat",
    regularMessageSegment: "Kundensegment",
    regularMessageText: "Nachrichtentext",
  },
  es: {
    general: "Configuración general",
    design: "Diseño",
    marketing: "Marketing",
    loyalty: "Fidelidad",
    billing: "Facturación",
    selfCreation:
      "El cliente completa el formulario de registro de forma independiente",
    language: "Establecer idioma del formulario",
    city: "Ciudad",
    arrayData: "ingresar separado por comas",
    clientData: "Datos del cliente",
    formData: "Formulario de registro",
    additionalData: "Información adicional",
    expirationDate: "Vence el",
    period: "Período de validez",
    category: "Categoría",
    photo: "Foto",
    defaultData: "Datos predeterminados",
    balance: "Saldo al recibir la tarjeta",
    discount: "Descuento al recibir la tarjeta",
    currentNumber: "Número de tarjeta actual",
    loyaltyProgram: "Programa de fidelidad",
    bonusSystem: "Sistema de bonificación",
    discountSystem: "Sistema de descuento",
    stampSystem: "Tarjetas de sellos",
    bonusPercent: "Porcentaje de acumulación",
    discountPercent: "Porcentaje de descuento",
    freeNumber: "Número de producto gratuito",
    scan: "Escanear con teléfono/escáner (URL)",
    active: "Activado",
    segment: "Segmentación de clientes",
    birthday: "Felicitaciones de cumpleaños",
    bDayBeforeDays: "Días antes del cumpleaños",
    bDayBeforeText: "Texto de la oferta",
    birthDayText: "Texto de felicitación",
    bDayAfterDays: "Días después del cumpleaños",
    bDayAfterText: "Recordatorio de la oferta",
    regularMessage: "Mensajes regulares",
    regularMessageName: "Nombre del mensaje",
    regularMessageDescription: "Descripción del mensaje",
    regularMessageTime: "Hora de envío del mensaje",
    regularMessageDate: "Fecha de envío del mensaje",
    regularMessageWeekday: "Día de la semana",
    regularMessageNthWeek: "Número de semana en el mes",
    regularMessageSegment: "Segmento de clientes",
    regularMessageText: "Texto del mensaje",
  },
};

module.exports.distribution = {
  ru: {
    copyUrl: "Скопировать URL",
    followUrl: "Перейти по ссылке",
    description:
      "Ссылка и QR-код для самостоятельной регистрации новых клиентов и сохранения полученной карты в Apple Wallet или Google Pay",
  },
  uk: {
    copyUrl: "Скопіювати URL",
    followUrl: "Перейти за посиланням",
    description:
      "Посилання та QR-код для самостійної реєстрації нових клієнтів та збереження отриманої картки в Apple Wallet або Google Pay",
  },
  en: {
    copyUrl: "Copy URL",
    followUrl: "Follow the link",
    description:
      "Link and QR code for self-registration of new clients and saving the received card in Apple Wallet or Google Pay",
  },
  pl: {
    copyUrl: "Skopiuj URL",
    followUrl: "Przejdź do linku",
    description:
      "Link i kod QR do samodzielnej rejestracji nowych klientów i zapisywania otrzymanej karty w Apple Wallet lub Google Pay",
  },
  de: {
    copyUrl: "URL kopieren",
    followUrl: "Link folgen",
    description:
      "Link und QR-Code zur Selbstregistrierung neuer Kunden und zum Speichern der erhaltenen Karte in Apple Wallet oder Google Pay",
  },
  es: {
    copyUrl: "Copiar URL",
    followUrl: "Seguir el enlace",
    description:
      "Enlace y código QR para el autoregistro de nuevos clientes y para guardar la tarjeta recibida en Apple Wallet o Google Pay",
  },
};

module.exports.userCreate = {
  ru: {
    header: "Регистрация нового пользователя",
    department: "Подразделение",
    email: "Email",
    emailHelpText:
      "Email используется в качестве идентификатора пользователя, а также для восстановления и изменения пароля.",
    acceptRules: "Принимаю правила и условия использования сервиса",
    registration: "Зарегистрировать",
  },
  uk: {
    header: "Реєстрація нового користувача",
    department: "Підрозділ",
    email: "Email",
    emailHelpText:
      "Email використовується в якості ідентифікатора користувача, а також для відновлення і зміни пароля.",
    acceptRules: "Приймаю правила і умови використання сервісу",
    registration: "Зареєструвати",
  },
  en: {
    header: "Register a new user",
    department: "Department",
    email: "Email",
    emailHelpText:
      "Email is used as a user identifier, as well as for password recovery and change.",
    acceptRules: "I accept the rules and terms of service",
    registration: "Register",
  },
  pl: {
    header: "Rejestracja nowego użytkownika",
    department: "Dział",
    email: "Email",
    emailHelpText:
      "Email jest używany jako identyfikator użytkownika oraz do odzyskiwania i zmiany hasła.",
    acceptRules: "Akceptuję zasady i warunki korzystania z usługi",
    registration: "Zarejestruj",
  },
  de: {
    header: "Registrierung eines neuen Benutzers",
    department: "Abteilung",
    email: "Email",
    emailHelpText:
      "Email wird als Benutzerkennung sowie zur Wiederherstellung und Änderung des Passworts verwendet.",
    acceptRules:
      "Ich akzeptiere die Regeln und Nutzungsbedingungen des Dienstes",
    registration: "Registrieren",
  },
  es: {
    header: "Registro de un nuevo usuario",
    department: "Departamento",
    email: "Email",
    emailHelpText:
      "El correo electrónico se utiliza como identificador de usuario, así como para la recuperación y el cambio de contraseña.",
    acceptRules: "Acepto las reglas y condiciones de uso del servicio",
    registration: "Registrar",
  },
};

module.exports.userEdit = {
  ru: {
    header: "Изменить данные пользователя",
    department: "Подразделение",
    role: "Права доступа",
    status: "Статус",
    save: "Сохранить",
    exit: "Отмена",
  },
  uk: {
    header: "Зміниті данні користувача",
    department: "Підрозділ",
    role: "Права доступу",
    status: "Статус",
    save: "Зберегти",
    exit: "Скасувати",
  },
  en: {
    header: "Edit User Data",
    department: "Department",
    role: "Access Rights",
    status: "Status",
    save: "Save",
    exit: "Cancel",
  },
  pl: {
    header: "Edytuj dane użytkownika",
    department: "Dział",
    role: "Prawa dostępu",
    status: "Status",
    save: "Zapisz",
    exit: "Anuluj",
  },
  de: {
    header: "Benutzerdaten bearbeiten",
    department: "Abteilung",
    role: "Zugriffsrechte",
    status: "Status",
    save: "Speichern",
    exit: "Abbrechen",
  },
  es: {
    header: "Editar datos de usuario",
    department: "Departamento",
    role: "Permisos de acceso",
    status: "Estado",
    save: "Guardar",
    exit: "Cancelar",
  },
};

module.exports.userLogin = {
  ru: {
    header: "Авторизация",
    email: "Email",
    password: "Пароль",
    confirmPassword: "Подтверждение пароля",
    changePassword: "Изменение пароля",
    submit: "Вход",
    saveBtn: "Сохранить",
  },
  uk: {
    header: "Авторизація",
    email: "Email",
    password: "Пароль",
    confirmPassword: "Підтвердження аролю",
    changePassword: "Зміна паролю",
    submit: "Вхід",
    saveBtn: "Зберегти",
  },
  en: {
    header: "Authorization",
    email: "Email",
    password: "Password",
    confirmPassword: "Confirm Password",
    changePassword: "Change Password",
    submit: "Submit",
    saveBtn: "Save",
  },
  pl: {
    header: "Autoryzacja",
    email: "Email",
    password: "Hasło",
    confirmPassword: "Potwierdź hasło",
    changePassword: "Zmień hasło",
    submit: "Zatwierdź",
    saveBtn: "Zapisz",
  },
  de: {
    header: "Autorisierung",
    email: "Email",
    password: "Passwort",
    confirmPassword: "Passwort bestätigen",
    changePassword: "Passwort ändern",
    submit: "Senden",
    saveBtn: "Speichern",
  },
  es: {
    header: "Autorización",
    email: "Email",
    password: "Contraseña",
    confirmPassword: "Confirmar contraseña",
    changePassword: "Cambiar contraseña",
    submit: "Enviar",
    saveBtn: "Guardar",
  },
};

module.exports.userDisplay = {
  ru: {
    header: "Пользователи",
    department: "Подразделение",
    email: "Пользователь",
    role: "Роль",
    status: "Статус",
    resetPassword: "Сбросить пароль",
    edit: "Изменить",
    delete: "Удалить",
    newUser: "Добавить",
  },
  uk: {
    header: "Користувачі",
    department: "Підрозділ",
    email: "Користувач",
    role: "Роль",
    status: "Статус",
    resetPassword: "Скинути пароль",
    edit: "Змінити",
    delete: "Видалити",
    newUser: "Додати",
  },
  en: {
    header: "Users",
    department: "Department",
    email: "User",
    role: "Role",
    status: "Status",
    resetPassword: "Reset Password",
    edit: "Edit",
    delete: "Delete",
    newUser: "Add New",
  },
  pl: {
    header: "Użytkownicy",
    department: "Dział",
    email: "Użytkownik",
    role: "Rola",
    status: "Status",
    resetPassword: "Zresetuj hasło",
    edit: "Edytuj",
    delete: "Usuń",
    newUser: "Dodaj nowego",
  },
  de: {
    header: "Benutzer",
    department: "Abteilung",
    email: "Benutzer",
    role: "Rolle",
    status: "Status",
    resetPassword: "Passwort zurücksetzen",
    edit: "Bearbeiten",
    delete: "Löschen",
    newUser: "Hinzufügen",
  },
  es: {
    header: "Usuarios",
    department: "Departamento",
    email: "Usuario",
    role: "Rol",
    status: "Estado",
    resetPassword: "Restablecer contraseña",
    edit: "Editar",
    delete: "Eliminar",
    newUser: "Agregar nuevo",
  },
};

module.exports.registerDisplay = {
  ru: {
    header: "Реестры",
    currentMonth: "Текущий месяц",
    lastMonth: "Прошлый месяц",
    period: "Период",
    date: "Дата",
    department: "Подразделение",
    user: "Пользователь",
    issued: "Выпущено",
    updated: "Обновлено",
    deleted: "Удалено",
    total: "Итого",
    download: "Скачать",
  },
  uk: {
    header: "Реєстри",
    currentMonth: "Поточний місяць",
    lastMonth: "Попередній місяць",
    period: "Період",
    date: "Дата",
    department: "Підрозділ",
    user: "Користувач",
    issued: "Емітовано",
    updated: "Оновлено",
    deleted: "Видалено",
    total: "Загалом",
    download: "Завантажити",
  },
  en: {
    header: "Registers",
    currentMonth: "Current Month",
    lastMonth: "Last Month",
    period: "Period",
    date: "Date",
    department: "Department",
    user: "User",
    issued: "Issued",
    updated: "Updated",
    deleted: "Deleted",
    total: "Total",
    download: "Download",
  },
  pl: {
    header: "Rejestry",
    currentMonth: "Bieżący miesiąc",
    lastMonth: "Poprzedni miesiąc",
    period: "Okres",
    date: "Data",
    department: "Dział",
    user: "Użytkownik",
    issued: "Wydane",
    updated: "Zaktualizowane",
    deleted: "Usunięte",
    total: "Suma",
    download: "Pobierz",
  },
  de: {
    header: "Register",
    currentMonth: "Aktueller Monat",
    lastMonth: "Vorheriger Monat",
    period: "Zeitraum",
    date: "Datum",
    department: "Abteilung",
    user: "Benutzer",
    issued: "Ausgestellt",
    updated: "Aktualisiert",
    deleted: "Gelöscht",
    total: "Gesamt",
    download: "Herunterladen",
  },
  es: {
    header: "Registros",
    currentMonth: "Mes Actual",
    lastMonth: "Mes Anterior",
    period: "Período",
    date: "Fecha",
    department: "Departamento",
    user: "Usuario",
    issued: "Emitido",
    updated: "Actualizado",
    deleted: "Eliminado",
    total: "Total",
    download: "Descargar",
  },
};

module.exports.uploadRegister = {
  ru: {
    uploadButton: "Выгрузить",
    selectFile: "Выбрать файл",
    fileNotSelected: "Файл не выбран",
    downloadButton: "Получить шаблон файла (template.xlsx)",
  },
  uk: {
    uploadButton: "Вивантажити",
    selectFile: "Вибрати файл",
    fileNotSelected: "Файл не вибрано",
    downloadButton: "Отримати шаблон файлу (template.xlsx)",
  },
  en: {
    uploadButton: "Upload",
    selectFile: "Select File",
    fileNotSelected: "File Not Selected",
    downloadButton: "Download File Template (template.xlsx)",
  },
  pl: {
    uploadButton: "Załaduj",
    selectFile: "Wybierz plik",
    fileNotSelected: "Plik nie wybrany",
    downloadButton: "Pobierz szablon pliku (template.xlsx)",
  },
  de: {
    uploadButton: "Hochladen",
    selectFile: "Datei auswählen",
    fileNotSelected: "Datei nicht ausgewählt",
    downloadButton: "Dateivorlage herunterladen (template.xlsx)",
  },
  es: {
    uploadButton: "Cargar",
    selectFile: "Seleccionar archivo",
    fileNotSelected: "Archivo no seleccionado",
    downloadButton: "Descargar plantilla de archivo (template.xlsx)",
  },
};

module.exports.cardsDisplay = {
  ru: {
    createdAt: "Создано",
    updatedAt: "Изменено",
    fullName: "Имя",
    memberId: "Номер карты",
    balance: "Баланс",
    bonus: "Начисление",
    discount: "Скидка",
    cardUrl: "Ссылка на карту",
    download: "Скачать",
    tel: "Телефон",
    birthday: "Дата рождения",
    edit: "Изменить",
    delete: "Удалить",
    cardNumber: "Карта",
    name: "Имя",
    search: "Поиск",
    allCards: "Все карты",
    newCard: "Создать",
    availableWithMarketing: 'Доступно с пакетом "Marketing"',
    photo: "Фото",
  },
  uk: {
    createdAt: "Створено",
    updatedAt: "Змінено",
    fullName: "Ім'я",
    memberId: "Номер картки",
    balance: "Баланс",
    bonus: "Нарахування",
    discount: "Знижка",
    cardUrl: "Посилання на картку",
    download: "Завантажити",
    tel: "Телефон",
    birthday: "Дата народження",
    edit: "Змінити",
    delete: "Видалити",
    cardNumber: "Картка",
    name: "Ім'я",
    search: "Пошук",
    allCards: "Усі картки",
    newCard: "Створити",
    availableWithMarketing: 'Доступно з пакетом "Marketing"',
    photo: "Фото",
  },
  en: {
    createdAt: "Created At",
    updatedAt: "Updated At",
    fullName: "Full Name",
    memberId: "Card Number",
    balance: "Balance",
    bonus: "Bonus",
    discount: "Discount",
    cardUrl: "Card URL",
    download: "Download",
    tel: "Phone",
    birthday: "Birthday",
    edit: "Edit",
    delete: "Delete",
    cardNumber: "Card",
    name: "Name",
    search: "Search",
    allCards: "All Cards",
    newCard: "Create",
    availableWithMarketing: 'Available with "Marketing" package',
    photo: "Photo",
  },
  pl: {
    createdAt: "Utworzono",
    updatedAt: "Zaktualizowano",
    fullName: "Imię i Nazwisko",
    memberId: "Numer karty",
    balance: "Saldo",
    bonus: "Bonus",
    discount: "Zniżka",
    cardUrl: "URL karty",
    download: "Pobierz",
    tel: "Telefon",
    birthday: "Data urodzenia",
    edit: "Edytuj",
    delete: "Usuń",
    cardNumber: "Karta",
    name: "Imię",
    search: "Szukaj",
    allCards: "Wszystkie karty",
    newCard: "Utwórz",
    availableWithMarketing: 'Dostępne w pakiecie "Marketing"',
    photo: "Zdjęcie",
  },
  de: {
    createdAt: "Erstellt am",
    updatedAt: "Aktualisiert am",
    fullName: "Vollständiger Name",
    memberId: "Kartennummer",
    balance: "Kontostand",
    bonus: "Bonus",
    discount: "Rabatt",
    cardUrl: "Karten-URL",
    download: "Herunterladen",
    tel: "Telefon",
    birthday: "Geburtstag",
    edit: "Bearbeiten",
    delete: "Löschen",
    cardNumber: "Karte",
    name: "Name",
    search: "Suche",
    allCards: "Alle Karten",
    newCard: "Erstellen",
    availableWithMarketing: 'Verfügbar mit "Marketing"-Paket',
    photo: "Foto",
  },
  es: {
    createdAt: "Creado el",
    updatedAt: "Actualizado el",
    fullName: "Nombre completo",
    memberId: "Número de tarjeta",
    balance: "Saldo",
    bonus: "Bono",
    discount: "Descuento",
    cardUrl: "URL de la tarjeta",
    download: "Descargar",
    tel: "Teléfono",
    birthday: "Fecha de nacimiento",
    edit: "Editar",
    delete: "Eliminar",
    cardNumber: "Tarjeta",
    name: "Nombre",
    search: "Buscar",
    allCards: "Todas las tarjetas",
    newCard: "Crear",
    availableWithMarketing: 'Disponible con el paquete "Marketing"',
    photo: "Foto",
  },
};

module.exports.sendPass = {
  ru: {
    title: "На ваше имя выпущена электронная карта",
    open: "Открыть",
    scanToReceive: "Для получения карты наведите камеру телефона на QR код",
    downloadFromGooglePlay:
      "Скачайте бесплатное приложение для использования карт Apple Wallet на Android устройствах",
    addToWalletPasses:
      'Если у вас уже установлено приложение WalletPasses, добавьте карту в приложение "Карты"',
  },
  uk: {
    title: "На ваше ім'я випущена електронна картка",
    open: "Відкрити",
    scanToReceive: "Для отримання карти наведіть камеру телефону на QR код",
    downloadFromGooglePlay:
      "Завантажте безкоштовний додаток для використання карт Apple Wallet на Android пристроях",
    addToWalletPasses:
      "Якщо у вас вже встановлено додаток WalletPasses, додайте карту в програму «Карти»",
  },
  en: {
    title: "An electronic card has been issued in your name",
    open: "Open",
    scanToReceive:
      "To receive the card, point your phone camera at the QR code",
    downloadFromGooglePlay:
      "Download the free app to use Apple Wallet cards on Android devices",
    addToWalletPasses:
      'If you already have the WalletPasses app installed, add the card to the "Cards" app',
  },
  pl: {
    title: "Dla Ciebie została wydana karta elektroniczna",
    open: "Otwórz",
    scanToReceive: "Aby odebrać kartę, zeskanuj kod QR aparatem telefonu",
    downloadFromGooglePlay:
      "Pobierz bezpłatną aplikację, aby korzystać z kart Apple Wallet na urządzeniach z systemem Android",
    addToWalletPasses:
      'Jeśli masz już zainstalowaną aplikację WalletPasses, dodaj kartę do aplikacji "Karty"',
  },
  de: {
    title: "Eine elektronische Karte wurde auf Ihren Namen ausgestellt",
    open: "Öffnen",
    scanToReceive:
      "Um die Karte zu erhalten, richten Sie die Kamera Ihres Telefons auf den QR-Code",
    downloadFromGooglePlay:
      "Laden Sie die kostenlose App herunter, um Apple Wallet-Karten auf Android-Geräten zu verwenden",
    addToWalletPasses:
      'Fügen Sie die Karte zur "Karten"-App hinzu, wenn Sie bereits die WalletPasses-App installiert haben',
  },
  es: {
    title: "Se ha emitido una tarjeta electrónica a su nombre",
    open: "Abrir",
    scanToReceive:
      "Para recibir la tarjeta, apunte la cámara de su teléfono al código QR",
    downloadFromGooglePlay:
      "Descargue la aplicación gratuita para utilizar tarjetas de Apple Wallet en dispositivos Android",
    addToWalletPasses:
      'Si ya tiene la aplicación WalletPasses instalada, agregue la tarjeta a la aplicación "Tarjetas"',
  },
};
module.exports.sendMessages = {
  ru: {
    title: "Пуш-уведомления",
    subTitle: "Уведомление будет отправлено на все установленные карты",
    message: "Текст уведомления",
    filter: "Параметр фильтрации",
    pushAll: "Отправить всем",
    category: "Категория",
    segment: "Сегмент",
    city: "Город",
    passTypeIdentifier: "Pass Type ID",
    templateId: "Шаблон",
    submitButton: "Отправить",
  },
  uk: {
    title: "Пуш-повідомлення",
    subTitle: "Повідомлення буде надіслано на всі встановленні картки",
    message: "Текст повідомлення",
    filter: "Параметр фільтрації",
    pushAll: "Надіслати всім",
    category: "Категорія",
    segment: "Сегмент",
    city: "Місто",
    passTypeIdentifier: "Pass Type ID",
    templateId: "Шаблон",
    submitButton: "Надіслати",
  },
  en: {
    title: "Push Notifications",
    subTitle: "Notification will be sent to all installed cards",
    message: "Notification Text",
    filter: "Filter Parameter",
    pushAll: "Send to All",
    category: "Category",
    segment: "Segment",
    city: "City",
    passTypeIdentifier: "Pass Type ID",
    templateId: "Template",
    submitButton: "Send",
  },
  pl: {
    title: "Powiadomienia Push",
    subTitle:
      "Powiadomienie zostanie wysłane do wszystkich zainstalowanych kart",
    message: "Treść powiadomienia",
    filter: "Parametr filtra",
    pushAll: "Wyślij do wszystkich",
    category: "Kategoria",
    segment: "Segment",
    city: "Miasto",
    passTypeIdentifier: "ID typu karty",
    templateId: "Szablon",
    submitButton: "Wyślij",
  },
  de: {
    title: "Push-Benachrichtigungen",
    subTitle: "Die Benachrichtigung wird an alle installierten Karten gesendet",
    message: "Benachrichtigungstext",
    filter: "Filterparameter",
    pushAll: "An alle senden",
    category: "Kategorie",
    segment: "Segment",
    city: "Stadt",
    passTypeIdentifier: "Pass-Typ-ID",
    templateId: "Vorlage",
    submitButton: "Senden",
  },
  es: {
    title: "Notificaciones Push",
    subTitle: "La notificación se enviará a todas las tarjetas instaladas",
    message: "Texto de la notificación",
    filter: "Parámetro de filtro",
    pushAll: "Enviar a todos",
    category: "Categoría",
    segment: "Segmento",
    city: "Ciudad",
    passTypeIdentifier: "ID de Tipo de Pase",
    templateId: "Plantilla",
    submitButton: "Enviar",
  },
};

module.exports.multiselect = {
  ru: {
    allItemsAreSelected: "Выбраны все элементы",
    clearSearch: "Очистить строку Поиска",
    clearSelected: "Очистить Выбранные",
    noOptions: "Нет опций",
    search: "Поиск",
    selectAll: "Выбрать все",
    selectAllFiltered: "Выбрать все (Отфилитрованные)",
    selectSomeItems: "Выбор...",
    create: "Создать",
  },
  uk: {
    allItemsAreSelected: "Вибрані всі елементи",
    clearSearch: "Очистити Пошук",
    clearSelected: "Очистити Обрані",
    noOptions: "Немає опцій",
    search: "Пошук",
    selectAll: "Обрати усі",
    selectAllFiltered: "Обрати усі (Відфільтровані)",
    selectSomeItems: "Обрати...",
    create: "Створити",
  },
  en: {
    allItemsAreSelected: "All items are selected",
    clearSearch: "Clear Search",
    clearSelected: "Clear Selected",
    noOptions: "No options",
    search: "Search",
    selectAll: "Select All",
    selectAllFiltered: "Select All (Filtered)",
    selectSomeItems: "Select...",
    create: "Create",
  },
  pl: {
    allItemsAreSelected: "Wybrane wszystkie elementy",
    clearSearch: "Wyczyść wyszukiwanie",
    clearSelected: "Wyczyść wybrane",
    noOptions: "Brak opcji",
    search: "Szukaj",
    selectAll: "Zaznacz wszystkie",
    selectAllFiltered: "Zaznacz wszystkie (filtrowane)",
    selectSomeItems: "Wybierz...",
    create: "Utwórz",
  },
  de: {
    allItemsAreSelected: "Alle Elemente ausgewählt",
    clearSearch: "Suche löschen",
    clearSelected: "Auswahl löschen",
    noOptions: "Keine Optionen",
    search: "Suche",
    selectAll: "Alle auswählen",
    selectAllFiltered: "Alle auswählen (gefiltert)",
    selectSomeItems: "Auswahl...",
    create: "Erstellen",
  },
  es: {
    allItemsAreSelected: "Se han seleccionado todos los elementos",
    clearSearch: "Borrar búsqueda",
    clearSelected: "Borrar seleccionados",
    noOptions: "No hay opciones",
    search: "Buscar",
    selectAll: "Seleccionar todos",
    selectAllFiltered: "Seleccionar todos (filtrado)",
    selectSomeItems: "Seleccionar...",
    create: "Crear",
  },
};

module.exports.createPass = {
  ru: {
    title: "Выпуск карты",
    titleClient: "Для получения карты заполните форму",
    templateId: "Шаблон карты",
    fullName: "Имя и фамилия",
    firstName: "Имя",
    lastName: "Фамилия",
    tel: "Телефон",
    birthday: "Дата рождения",
    balance: "Баланс карты",
    discount: "Размер скидки",
    certAmount: "Сумма сертификата",
    memberId: "Номер карты",
    expirationDate: "Срок действия",
    relevantDate: "Релевантные дата и время",
    from: "Действует с (дд.мм.гггг)",
    till: "Действует до (дд.мм.гггг)",
    submitButton: "Получить карту",
    createCardButton: "Выпустить карту",
    goBack: "Отмена",
  },
  uk: {
    title: "Випуск картки",
    titleClient: "Для отримання картки заповніть форму",
    templateId: "Шаблон картки",
    fullName: "Ім'я та прізвище",
    firstName: "Ім'я",
    lastName: "Прізвище",
    tel: "Телефон",
    birthday: "Дата народження",
    balance: "Баланс картки",
    discount: "Розмір знижки",
    certAmount: "Сума сертифіката",
    memberId: "Номер картки",
    expirationDate: "Термін дії",
    relevantDate: "Релевантні дата та час",
    from: "Діє з (дд.мм.рррр)",
    till: "Діє до (дд.мм.рррр)",
    submitButton: "Отримати картку",
    createCardButton: "Створити картку",
    goBack: "Скасувати",
  },
  en: {
    title: "Card Issuance",
    titleClient: "Fill in the form to get a card",
    templateId: "Card Template",
    fullName: "Full Name",
    firstName: "First Name",
    lastName: "Last Name",
    tel: "Phone",
    birthday: "Date of Birth",
    balance: "Card Balance",
    discount: "Discount Size",
    certAmount: "Certificate Amount",
    memberId: "Card Number",
    expirationDate: "Expiration Date",
    relevantDate: "Relevant Date and Time",
    from: "Valid from (dd.mm.yyyy)",
    till: "Valid until (dd.mm.yyyy)",
    submitButton: "Get Card",
    createCardButton: "Issue Card",
    goBack: "Cancel",
  },
  pl: {
    title: "Wydanie karty",
    titleClient: "Aby otrzymać kartę, wypełnij formularz",
    templateId: "Szablon karty",
    fullName: "Imię i nazwisko",
    firstName: "Imię",
    lastName: "Nazwisko",
    tel: "Telefon",
    birthday: "Data urodzenia",
    balance: "Saldo karty",
    discount: "Rozmiar rabatu",
    certAmount: "Kwota certyfikatu",
    memberId: "Numer karty",
    expirationDate: "Data ważności",
    relevantDate: "Relewantna data i godzina",
    from: "Ważne od (dd.mm.rrrr)",
    till: "Ważne do (dd.mm.rrrr)",
    submitButton: "Otrzymaj kartę",
    createCardButton: "Wydaj kartę",
    goBack: "Anuluj",
  },
  de: {
    title: "Kartenausgabe",
    titleClient: "Füllen Sie das Formular aus, um eine Karte zu erhalten",
    templateId: "Kartenvorlage",
    fullName: "Vor- und Nachname",
    firstName: "Vorname",
    lastName: "Nachname",
    tel: "Telefon",
    birthday: "Geburtsdatum",
    balance: "Kartensaldo",
    discount: "Rabatthöhe",
    certAmount: "Zertifikatsbetrag",
    memberId: "Kartennummer",
    expirationDate: "Ablaufdatum",
    relevantDate: "Relevante Datum und Uhrzeit",
    from: "Gültig ab (TT.MM.JJJJ)",
    till: "Gültig bis (TT.MM.JJJJ)",
    submitButton: "Karte erhalten",
    createCardButton: "Karte ausstellen",
    goBack: "Abbrechen",
  },
  es: {
    title: "Emisión de Tarjeta",
    titleClient: "Complete el formulario para obtener una tarjeta",
    templateId: "Plantilla de Tarjeta",
    fullName: "Nombre completo",
    firstName: "Nombre",
    lastName: "Apellido",
    tel: "Teléfono",
    birthday: "Fecha de nacimiento",
    balance: "Saldo de la tarjeta",
    discount: "Tamaño del descuento",
    certAmount: "Monto del certificado",
    memberId: "Número de tarjeta",
    expirationDate: "Fecha de caducidad",
    relevantDate: "Fecha y hora relevante",
    from: "Válido desde (dd.mm.aaaa)",
    till: "Válido hasta (dd.mm.aaaa)",
    submitButton: "Obtener Tarjeta",
    createCardButton: "Emitir Tarjeta",
    goBack: "Cancelar",
  },
};
module.exports.editPass = {
  ru: {
    title: "Редактирование карты",
    fullName: "Имя и фамилия",
    firstName: "Имя",
    lastName: "Фамилия",
    tel: "Телефон",
    birthday: "Дата рождения",
    balance: "Баланс карты",
    bonus: "Размер начисления",
    discount: "Размер скидки",
    certAmount: "Сумма сертификата",
    message: "Персональное сообщение",
    memberId: "Номер карты",
    city: "Город",
    category: "Категория клиента",
    templateId: "Шаблон",
    relevantDate: "Релевантные дата и время",
    expirationDate: "Срок действия",
    photo: "Фото",
    submitButton: "Сохранить изменения",
    goBack: "Отмена",
    uploadImg: "Загрузить изображение",
  },
  uk: {
    title: "Редагування картки",
    fullName: "Ім'я та прізвище",
    firstName: "Ім'я",
    lastName: "Прізвище",
    tel: "Телефон",
    birthday: "Дата народження",
    balance: "Баланс картки",
    bonus: "Розмір нарахувань",
    discount: "Розмір знижки",
    certAmount: "Сума сертифіката",
    message: "Персональне повідомлення",
    memberId: "Номер картки",
    city: "Місто",
    category: "Категорія клієнта",
    templateId: "Шаблон",
    relevantDate: "Релевантні дата та час",
    expirationDate: "Строк дії картки",
    photo: "Фото",
    submitButton: "Зберегти зміни",
    goBack: "Скасувати",
    uploadImg: "Завантажити зображення",
  },
  en: {
    title: "Edit Card",
    fullName: "Full Name",
    firstName: "First Name",
    lastName: "Last Name",
    tel: "Phone",
    birthday: "Date of Birth",
    balance: "Card Balance",
    bonus: "Accrual Amount",
    discount: "Discount Amount",
    certAmount: "Certificate Amount",
    message: "Personal Message",
    memberId: "Card Number",
    city: "City",
    category: "Client Category",
    templateId: "Template",
    relevantDate: "Relevant Date and Time",
    expirationDate: "Expiration Date",
    photo: "Photo",
    submitButton: "Save Changes",
    goBack: "Cancel",
    uploadImg: "Upload Image",
  },
  pl: {
    title: "Edycja Karty",
    fullName: "Imię i Nazwisko",
    firstName: "Imię",
    lastName: "Nazwisko",
    tel: "Telefon",
    birthday: "Data Urodzenia",
    balance: "Saldo Karty",
    bonus: "Kwota Premii",
    discount: "Wysokość Rabatu",
    certAmount: "Kwota certyfikatu",
    message: "Wiadomość Osobista",
    memberId: "Numer Karty",
    city: "Miasto",
    category: "Kategoria Klienta",
    templateId: "Szablon",
    relevantDate: "Data i Czas Dotyczące",
    expirationDate: "Data Wygaśnięcia",
    photo: "Zdjęcie",
    submitButton: "Zapisz Zmiany",
    goBack: "Anuluj",
    uploadImg: "Prześlij Zdjęcie",
  },
  de: {
    title: "Karte bearbeiten",
    fullName: "Vor- und Nachname",
    firstName: "Vorname",
    lastName: "Nachname",
    tel: "Telefon",
    birthday: "Geburtsdatum",
    balance: "Kartenstand",
    bonus: "Gutschriftsbetrag",
    discount: "Rabattbetrag",
    certAmount: "Zertifikatsbetrag",
    message: "Persönliche Nachricht",
    memberId: "Kartennummer",
    city: "Stadt",
    category: "Kundekategorie",
    templateId: "Vorlage",
    relevantDate: "Relevanter Tag und Uhrzeit",
    expirationDate: "Ablaufdatum",
    photo: "Foto",
    submitButton: "Änderungen speichern",
    goBack: "Abbrechen",
    uploadImg: "Bild hochladen",
  },
  es: {
    title: "Editar Tarjeta",
    fullName: "Nombre y Apellido",
    firstName: "Nombre",
    lastName: "Apellido",
    tel: "Teléfono",
    birthday: "Fecha de Nacimiento",
    balance: "Saldo de la Tarjeta",
    bonus: "Monto de Bonificación",
    discount: "Monto de Descuento",
    certAmount: "Monto del certificado",
    message: "Mensaje Personal",
    memberId: "Número de Tarjeta",
    city: "Ciudad",
    category: "Categoría de Cliente",
    templateId: "Plantilla",
    relevantDate: "Fecha y Hora Relevante",
    expirationDate: "Fecha de Expiración",
    photo: "Foto",
    submitButton: "Guardar Cambios",
    goBack: "Cancelar",
    uploadImg: "Subir Imagen",
  },
};
module.exports.statistic = {
  ru: {
    title: "Статистика",
    issuedTotal: "Выпущено карт",
    period: "Период",
    currentMonth: "Текущий месяц",
    previousMonth: "Прошлый месяц",
    sixMonths: "6 месяцев",
    twelveMonths: "12 месяцев",
    fullPeriod: "Весь период",
    selfService: "Самостоятельно клиентом",
    installedAt: "Установлены на устройства",
    source: "Источник выпуска",
    mainLink: "Основная ссылка / QR код",
    cabinet: "Личный кабинет",
    referral: "Реферальная ссылка",
    register: "Реестровый выпуск",
    clients: "Клиенты",
    active: "Активные",
    passive: "Пассивные",
    criterion: "* критерий по умолчанию - 5 недель",
    clientsGender: "Пол клиентов",
    male: "Мужчины",
    female: "Женщины",
    notDetermined: "Не определен",
  },
  uk: {
    title: "Статистика",
    issuedTotal: "Емітовано карток",
    period: "Період",
    currentMonth: "Поточний місяць",
    previousMonth: "Попередній місяць",
    sixMonths: "6 місяців",
    twelveMonths: "12 місяців",
    fullPeriod: "Весь період",
    selfService: "Самостійно клієнтом",
    installedAt: "Встановлено на пристрій",
    source: "Джерело випуску",
    mainLink: "Основне посилання / QR код",
    cabinet: "Особистий кабінет",
    referral: "Реферальне посилання",
    register: "Реєстровий випуск",
    clients: "Клієнти",
    active: "Активні",
    passive: "Пасивні",
    criterion: "* критерій за замовченням - 5 тижнів",
    clientsGender: "Стать клієнтів",
    male: "Чоловіки",
    female: "Жінки",
    notDetermined: "Не визначена",
  },
  en: {
    title: "Statistics",
    issuedTotal: "Cards Issued",
    period: "Period",
    currentMonth: "Current Month",
    previousMonth: "Previous Month",
    sixMonths: "6 Months",
    twelveMonths: "12 Months",
    fullPeriod: "Full Period",
    selfService: "Self-Service",
    installedAt: "Installed on Devices",
    source: "Source of Issuance",
    mainLink: "Main Link / QR Code",
    cabinet: "Personal Cabinet",
    referral: "Referral Link",
    register: "Registry Issuance",
    clients: "Clients",
    active: "Active",
    passive: "Passive",
    criterion: "* default criterion - 5 weeks",
    clientsGender: "Clients Gender",
    male: "Male",
    female: "Female",
    notDetermined: "Not Determined",
  },
  pl: {
    title: "Statystyki",
    issuedTotal: "Wydane karty",
    period: "Okres",
    currentMonth: "Bieżący miesiąc",
    previousMonth: "Poprzedni miesiąc",
    sixMonths: "6 miesięcy",
    twelveMonths: "12 miesięcy",
    fullPeriod: "Cały okres",
    selfService: "Obsługa przez klienta",
    installedAt: "Zainstalowane na urządzeniach",
    source: "Źródło wydania",
    mainLink: "Główny link / Kod QR",
    cabinet: "Konto użytkownika",
    referral: "Link polecający",
    register: "Rejestrowe wydanie",
    clients: "Klienci",
    active: "Aktywni",
    passive: "Bierni",
    criterion: "* domyślny kryterium - 5 tygodni",
    clientsGender: "Płeć klientów",
    male: "Mężczyźni",
    female: "Kobiety",
    notDetermined: "Nieokreślony",
  },
  de: {
    title: "Statistik",
    issuedTotal: "Ausgegebene Karten",
    period: "Zeitraum",
    currentMonth: "Aktueller Monat",
    previousMonth: "Vorheriger Monat",
    sixMonths: "6 Monate",
    twelveMonths: "12 Monate",
    fullPeriod: "Gesamtzeitraum",
    selfService: "Selbstbedienung",
    installedAt: "Auf Geräten installiert",
    source: "Ausgabequelle",
    mainLink: "Hauptlink / QR-Code",
    cabinet: "Persönliches Konto",
    referral: "Empfehlungslink",
    register: "Registrierungs-Ausgabe",
    clients: "Kunden",
    active: "Aktiv",
    passive: "Passiv",
    criterion: "* Standardkriterium - 5 Wochen",
    clientsGender: "Geschlecht der Kunden",
    male: "Männlich",
    female: "Weiblich",
    notDetermined: "Nicht festgelegt",
  },
  es: {
    title: "Estadísticas",
    issuedTotal: "Tarjetas Emitidas",
    period: "Período",
    currentMonth: "Mes Actual",
    previousMonth: "Mes Anterior",
    sixMonths: "6 Meses",
    twelveMonths: "12 Meses",
    fullPeriod: "Período Completo",
    selfService: "Auto-servicio",
    installedAt: "Instaladas en Dispositivos",
    source: "Fuente de Emisión",
    mainLink: "Enlace Principal / Código QR",
    cabinet: "Cuenta Personal",
    referral: "Enlace de Referido",
    register: "Emisión de Registro",
    clients: "Clientes",
    active: "Activos",
    passive: "Pasivos",
    criterion: "* criterio predeterminado - 5 semanas",
    clientsGender: "Género de Clientes",
    male: "Masculino",
    female: "Femenino",
    notDetermined: "No Determinado",
  },
};

module.exports.scan = {
  ru: {
    points: "Бонусы",
    totalValue: "Общая сумма",
    balance: "Баланс",
    newBalance: "Новый баланс",
    change: "Изменение",
    add: "Начислено",
    sub: "Списано",
    accumulate: "Начислить",
    redeem: "Списать",
    goBack: "Назад",
    toMain: "На главную",
    transactions: "Реестр",
  },
  uk: {
    points: "Бонуси",
    totalValue: "Загальна вартість",
    balance: "Баланс",
    newBalance: "Новий баланс",
    change: "Змінення",
    add: "Нараховано",
    sub: "Списано",
    accumulate: "Нарахувати",
    redeem: "Списати",
    goBack: "Повернутись",
    toMain: "На головну",
    transactions: "Реєстр",
  },
  en: {
    points: "Points",
    totalValue: "Total Value",
    balance: "Balance",
    newBalance: "New Balance",
    change: "Change",
    add: "Add",
    sub: "Subtract",
    accumulate: "Accumulate",
    redeem: "Redeem",
    goBack: "Go Back",
    toMain: "Back to Main",
    transactions: "Transactions",
  },
  pl: {
    points: "Punkty",
    totalValue: "Całkowita wartość",
    balance: "Saldo",
    newBalance: "Nowe saldo",
    change: "Zmiana",
    add: "Dodaj",
    sub: "Odejmij",
    accumulate: "Nalicz",
    redeem: "Zrealizuj",
    goBack: "Powrót",
    toMain: "Powrót do głównej",
    transactions: "Transakcje",
  },
  de: {
    points: "Punkte",
    totalValue: "Gesamtwert",
    balance: "Kontostand",
    newBalance: "Neuer Kontostand",
    change: "Änderung",
    add: "Hinzufügen",
    sub: "Abziehen",
    accumulate: "Anhäufen",
    redeem: "Einlösen",
    goBack: "Zurück",
    toMain: "Zurück zur Hauptseite",
    transactions: "Transaktionen",
  },
  es: {
    points: "Puntos",
    totalValue: "Valor Total",
    balance: "Saldo",
    newBalance: "Nuevo Saldo",
    change: "Cambiar",
    add: "Agregar",
    sub: "Restar",
    accumulate: "Acumular",
    redeem: "Canjear",
    goBack: "Volver",
    toMain: "Volver a Principal",
    transactions: "Transacciones",
  },
};

module.exports.transaction = {
  ru: {
    date: "Дата",
    transaction: "Транзакции",
    time: "Время",
    name: "Имя",
    action: "Транзакция",
    points: "Бонус",
    sum: "Общая сумма",
    balance: "Баланс",
    accumulation: "Начисление",
    redemption: "Списание",
    discount: "Скидка",
    referrer: "Пригласивший",
    referral: "Приглашенный",
    user: "Пользователь",
    showAll: "Отобразить все",
    download: "Скачать",
    clearFilter: "Все клиенти",
  },

  uk: {
    date: "Дата",
    transaction: "Транзакції",
    time: "Час",
    name: "Ім'я",
    action: "Транзакція",
    points: "Бонус",
    sum: "Загальна сума",
    balance: "Баланс",
    accumulation: "Нарахування",
    redemption: "Списання",
    discount: "Знижка",
    referrer: "Запросивший",
    referral: "Запрошений",
    user: "Користувач",
    showAll: "Відобразити усі",
    download: "Завантажити",
    clearFilter: "Усі клієнти",
  },
  en: {
    date: "Date",
    transaction: "Transactions",
    time: "Time",
    name: "Name",
    action: "Action",
    points: "Points",
    sum: "Total Amount",
    balance: "Balance",
    accumulation: "Accumulation",
    redemption: "Redemption",
    discount: "Discount",
    referrer: "Referrer",
    referral: "Referral",
    user: "User",
    showAll: "Show All",
    download: "Download",
    clearFilter: "Clear Filter",
  },
  pl: {
    date: "Data",
    transaction: "Transakcje",
    time: "Czas",
    name: "Nazwa",
    action: "Akcja",
    points: "Punkty",
    sum: "Suma",
    balance: "Saldo",
    accumulation: "Naliczenia",
    redemption: "Spisy",
    discount: "Zniżka",
    referrer: "Zapraszający",
    referral: "Zaproszony",
    user: "Użytkownik",
    showAll: "Pokaż wszystkie",
    download: "Pobierz",
    clearFilter: "Wyczyść filtr",
  },
  de: {
    date: "Datum",
    transaction: "Transaktionen",
    time: "Zeit",
    name: "Name",
    action: "Aktion",
    points: "Punkte",
    sum: "Gesamtsumme",
    balance: "Kontostand",
    accumulation: "Sammeln",
    redemption: "Einlösen",
    discount: "Rabatt",
    referrer: "Werbender",
    referral: "Empfohlener",
    user: "Benutzer",
    showAll: "Alle anzeigen",
    download: "Herunterladen",
    clearFilter: "Filter löschen",
  },
  es: {
    date: "Fecha",
    transaction: "Transacciones",
    time: "Hora",
    name: "Nombre",
    action: "Acción",
    points: "Puntos",
    sum: "Suma total",
    balance: "Balance",
    accumulation: "Acumulación",
    redemption: "Redención",
    discount: "Descuento",
    referrer: "Referente",
    referral: "Referido",
    user: "Usuario",
    showAll: "Mostrar todo",
    download: "Descargar",
    clearFilter: "Limpiar filtro",
  },
};

module.exports.error = {
  ru: {
    notFound: "Страница не найдена",
    notPaid: "Подписка не оплачена",
    forbidden: "У вас нет доступа",
    goToMain: "Перейти на Главную",
    goToPayment: "Перейти к оплате",
    goToContact: "Запросить счет в Telegram",
  },
  uk: {
    notFound: "Сторінку не знайдено",
    notPaid: "Підписка не сплачена",
    forbidden: "Ви не маєте доступу",
    goToMain: "Перейти на Головну",
    goToPayment: "Перейти до оплати",
    goToContact: "Запросити рахунок в Telegram",
  },
  en: {
    notFound: "Page not found",
    notPaid: "Subscription not paid",
    forbidden: "Access forbidden",
    goToMain: "Go to Main",
    goToPayment: "Go to Payment",
    goToContact: "Request invoice on Telegram",
  },
  pl: {
    notFound: "Nie znaleziono strony",
    notPaid: "Brak opłaconego abonamentu",
    forbidden: "Brak dostępu",
    goToMain: "Przejdź do strony głównej",
    goToPayment: "Przejdź do płatności",
    goToContact: "Poproś o fakturę na Telegramie",
  },
  de: {
    notFound: "Seite nicht gefunden",
    notPaid: "Abonnement nicht bezahlt",
    forbidden: "Zugriff verweigert",
    goToMain: "Zur Startseite gehen",
    goToPayment: "Zur Zahlung gehen",
    goToContact: "Rechnung per Telegram anfordern",
  },
  es: {
    notFound: "Página no encontrada",
    notPaid: "Suscripción no pagada",
    forbidden: "Acceso denegado",
    goToMain: "Ir a la página principal",
    goToPayment: "Ir al pago",
    goToContact: "Solicitar factura en Telegram",
  },
};

module.exports.posts = {
  ru: {
    date: "Дата",
    language: "Язык",
    title: "Заголовок",
    description: "Описание",
    text: "Статья",
    keywords: "Ключевые слова",
    articleUrl: "Адрес статьи",
    fbImgUrl: "Изображение для Facebook",
    twImgUrl: "Изображение для Twitter",
    fileName: "Название файла (без расширения)",
  },
  uk: {
    date: "Дата",
    language: "Мова",
    title: "Назва",
    description: "Опис",
    text: "Стаття",
    keywords: "Ключові слова",
    articleUrl: "Адреса статті",
    fbImgUrl: "Зображення для Facebook",
    twImgUrl: "Зображення для Twitter",
    fileName: "Назва файлу (без расширення)",
  },
  en: {
    date: "Date",
    language: "Language",
    title: "Title",
    description: "Description",
    text: "Article",
    keywords: "Keywords",
    articleUrl: "Article URL",
    fbImgUrl: "Facebook Image URL",
    twImgUrl: "Twitter Image URL",
    fileName: "File Name (without extension)",
  },
  pl: {
    date: "Data",
    language: "Język",
    title: "Tytuł",
    description: "Opis",
    text: "Artykuł",
    keywords: "Słowa kluczowe",
    articleUrl: "URL artykułu",
    fbImgUrl: "URL obrazka dla Facebooka",
    twImgUrl: "URL obrazka dla Twittera",
    fileName: "Nazwa pliku (bez rozszerzenia)",
  },
  de: {
    date: "Datum",
    language: "Sprache",
    title: "Titel",
    description: "Beschreibung",
    text: "Artikel",
    keywords: "Schlüsselwörter",
    articleUrl: "Artikel URL",
    fbImgUrl: "Facebook Bild URL",
    twImgUrl: "Twitter Bild URL",
    fileName: "Dateiname (ohne Erweiterung)",
  },
  es: {
    date: "Fecha",
    language: "Idioma",
    title: "Título",
    description: "Descripción",
    text: "Artículo",
    keywords: "Palabras clave",
    articleUrl: "URL del artículo",
    fbImgUrl: "URL de imagen para Facebook",
    twImgUrl: "URL de imagen para Twitter",
    fileName: "Nombre del archivo (sin extensión)",
  },
};

module.exports.loyalty = {
  uk: {
    bonusSystem: "Простий бонус",
    bonusSystemDescription: "Постійний процент нарахування бонусів",
    bonusSystemPlaceholder: "% нарахування",
    bonusBill: 'Бонусна "Рахунок"',
    bonusBillDescription:
      "Відсоток нарахувань залежить від розміру поточного рахунку",
    bonusSum: 'Бонусна "Прогресуюча',
    bonusSumDescription:
      "Відсоток нарахувань залежить від загальної суми покупок",
    bonusVisit: 'Бонусна "Кількість"',
    bonusVisitDescription: "Відсоток нарахувань залежить від кількості покупок",
    bonusLastVisit: 'Бонусна "Період"',
    bonusLastVisitDescription:
      "Відсоток нарахувань залежить від часу з останньої покупки",
    bonusLastVisitPlaceholder:
      "Введіть кількість днів з останнього візиту та процент нарахування",
    discountSystem: "Проста знижка",
    discountSystemDescription: "Постійний процент нарахування знижки",
    discountSystemPlaceholder: "% знижки",
    discountBill: 'Знижка "Рахунок"',
    discountBillDescription:
      "Відсоток знижки залежить від розміру поточного рахунку",
    discountSum: 'Знижка "Прогресуюча',
    discountSumDescription:
      "Відсоток знижки залежить від загальної суми покупок",
    discountVisit: 'Знижка "Кількість"',
    discountVisitDescription: "Відсоток знижки залежить від кількості покупок",
    discountLastVisit: 'Знижка "Період"',
    discountLastVisitDescription:
      "Відсоток знижки залежить від часу з останньої покупки",
    discountLastVisitPlaceholder:
      "Введіть кількість днів з останнього візиту та процент знижки",

    stampSystem: "Штамп-картка (кожен n-ний безкоштовний)",
    stampSystemDescription:
      "При досягненні зазначеної кількості покупок і отриманні подарунка картка обнуляється",
    stampSystemPlaceholder: "Кількість балів для отримання безкоштовного",
    stampStepBonusSystem: "Покрокова бонусна штамп-картка",
    stampStepBonusSystemDescription:
      "Нарахування бонусів залежить від номеру візиту. По досягненні максимальної кількості візитів картка обнуляється",
    stampStepBonusSystemPlaceholder:
      "Введіть бонуси через кому, де кожне значення - це номер візиту",
    stampStepDiscountSystem: "Покрокова дисконтна штамп-картка",
    stampStepDiscountSystemDescription:
      "Розмір знижки залежить від номеру візиту. По досягненні максимальної кількості візитів картка обнуляється",
    stampStepDiscountSystemPlaceholder:
      "Введіть знижки через кому, де кожне значення - це номер візиту",
  },
  ru: {
    bonusSystem: "Простая бонусная система",
    bonusSystemDescription: "Постоянный процент начисления бонусов",
    bonusSystemPlaceholder: "% начисления",
    bonusBill: 'Бонусный "Счет"',
    bonusBillDescription:
      "Процент начислений зависит от размера текущего счета",
    bonusSum: 'Бонусная "Прогрессивная"',
    bonusSumDescription: "Процент начислений зависит от общей суммы покупок",
    bonusVisit: 'Бонусная "Количество"',
    bonusVisitDescription: "Процент начислений зависит от количества покупок",
    bonusLastVisit: 'Бонусная "Период"',
    bonusLastVisitDescription:
      "Процент начислений зависит от времени с последней покупки",
    bonusLastVisitPlaceholder:
      "Введите количество дней с последнего визита и процент начисления",
    discountSystem: "Простая скидка",
    discountSystemDescription: "Постоянный процент начисления скидки",
    discountSystemPlaceholder: "% скидки",
    discountBill: 'Дисконт "Счет"',
    discountBillDescription: "Процент скидки зависит от размера текущего счета",
    discountSum: 'Дисконт "Прогрессивный"',
    discountSumDescription: "Процент скидки зависит от общей суммы покупок",
    discountVisit: 'Дисконт "Количество"',
    discountVisitDescription: "Процент скидки зависит от количества покупок",
    discountLastVisit: 'Дисконт "Период"',
    discountLastVisitDescription:
      "Процент скидки зависит от времени с последней покупки",
    discountLastVisitPlaceholder:
      "Введите количество дней с последнего визита и процент скидки",
    stampSystem: "Штампованая карта (каждая n-я бесплатная)",
    stampSystemDescription:
      "При достижении указанного количества покупок и получении подарка карта обнуляется",
    stampSystemPlaceholder: "Количество баллов для получения бесплатного",
    stampStepBonusSystem: "Шаговая бонусная штампованая карта",
    stampStepBonusSystemDescription:
      "Начисление бонусов зависит от номера визита. При достижении максимального количества визитов карта обнуляется",
    stampStepBonusSystemPlaceholder:
      "Введите бонусы через запятую, где каждое значение - номер визита",
    stampStepDiscountSystem: "Шаговая дисконтная штампованая карта",
    stampStepDiscountSystemDescription:
      "Размер скидки зависит от номера визита. При достижении максимального количества визитов карта обнуляется",
    stampStepDiscountSystemPlaceholder:
      "Введите скидки через запятую, где каждое значение - номер визита",
  },
  en: {
    bonusSystem: "Simple Bonus System",
    bonusSystemDescription: "Constant percentage of bonus accrual",
    bonusSystemPlaceholder: "% accrual",
    bonusBill: 'Bonus "Bill"',
    bonusBillDescription:
      "Percentage of accruals depends on the current bill size",
    bonusSum: 'Bonus "Progressive"',
    bonusSumDescription:
      "Percentage of accruals depends on the total purchase amount",
    bonusVisit: 'Bonus "Quantity"',
    bonusVisitDescription:
      "Percentage of accruals depends on the quantity of purchases",
    bonusLastVisit: 'Bonus "Period"',
    bonusLastVisitDescription:
      "Percentage of accruals depends on the time since the last purchase",
    bonusLastVisitPlaceholder:
      "Enter the number of days since the last visit and the accrual percentage",
    discountSystem: "Simple Discount",
    discountSystemDescription: "Constant percentage of discount accrual",
    discountSystemPlaceholder: "% discount",
    discountBill: 'Discount "Bill"',
    discountBillDescription:
      "Percentage of discount depends on the current bill size",
    discountSum: 'Discount "Progressive"',
    discountSumDescription:
      "Percentage of discount depends on the total purchase amount",
    discountVisit: 'Discount "Quantity"',
    discountVisitDescription:
      "Percentage of discount depends on the quantity of purchases",
    discountLastVisit: 'Discount "Period"',
    discountLastVisitDescription:
      "Percentage of discount depends on the time since the last purchase",
    discountLastVisitPlaceholder:
      "Enter the number of days since the last visit and the discount percentage",
    stampSystem: "Stamp Card (every n-th free)",
    stampSystemDescription:
      "When reaching the specified number of purchases and receiving a gift, the card is reset",
    stampSystemPlaceholder: "Number of points to get a free",
    stampStepBonusSystem: "Step Bonus Stamp Card",
    stampStepBonusSystemDescription:
      "Bonus accrual depends on the visit number. When reaching the maximum number of visits, the card is reset",
    stampStepBonusSystemPlaceholder:
      "Enter bonuses separated by comma, where each value is the visit number",
    stampStepDiscountSystem: "Step Discount Stamp Card",
    stampStepDiscountSystemDescription:
      "Discount size depends on the visit number. When reaching the maximum number of visits, the card is reset",
    stampStepDiscountSystemPlaceholder:
      "Enter discounts separated by comma, where each value is the visit number",
  },
  pl: {
    bonusSystem: "Prosty system bonusowy",
    bonusSystemDescription: "Stały procent naliczania bonusów",
    bonusSystemPlaceholder: "% naliczenia",
    bonusBill: 'Bonusowy "Rachunek"',
    bonusBillDescription:
      "Procent naliczeń zależy od wielkości bieżącego rachunku",
    bonusSum: 'Bonusowy "Postępujący"',
    bonusSumDescription: "Procent naliczeń zależy od całkowitej sumy zakupów",
    bonusVisit: 'Bonusowy "Ilość"',
    bonusVisitDescription: "Procent naliczeń zależy od ilości zakupów",
    bonusLastVisit: 'Bonusowy "Okres"',
    bonusLastVisitDescription:
      "Procent naliczeń zależy od czasu od ostatniego zakupu",
    bonusLastVisitPlaceholder:
      "Wprowadź liczbę dni od ostatniej wizyty i procent naliczenia",
    discountSystem: "Prosta zniżka",
    discountSystemDescription: "Stały procent naliczania zniżki",
    discountSystemPlaceholder: "% zniżki",
    discountBill: 'Zniżka "Rachunek"',
    discountBillDescription:
      "Procent zniżki zależy od wielkości bieżącego rachunku",
    discountSum: 'Zniżka "Postępująca"',
    discountSumDescription: "Procent zniżki zależy od całkowitej sumy zakupów",
    discountVisit: 'Zniżka "Ilość"',
    discountVisitDescription: "Procent zniżki zależy od ilości zakupów",
    discountLastVisit: 'Zniżka "Okres"',
    discountLastVisitDescription:
      "Procent zniżki zależy od czasu od ostatniego zakupu",
    discountLastVisitPlaceholder:
      "Wprowadź liczbę dni od ostatniej wizyty i procent zniżki",
    stampSystem: "Karta z pieczątkami (co n-ty darmowy)",
    stampSystemDescription:
      "Przy osiągnięciu określonej liczby zakupów i otrzymaniu prezentu karta jest resetowana",
    stampSystemPlaceholder: "Liczba punktów do uzyskania darmowego",
    stampStepBonusSystem: "Karta z krokowym systemem bonusowym",
    stampStepBonusSystemDescription:
      "Naliczanie bonusów zależy od numeru wizyty. Przy osiągnięciu maksymalnej liczby wizyt karta jest resetowana",
    stampStepBonusSystemPlaceholder:
      "Wprowadź bonusy oddzielone przecinkiem, gdzie każda wartość to numer wizyty",
    stampStepDiscountSystem: "Karta z krokowym systemem zniżek",
    stampStepDiscountSystemDescription:
      "Wielkość zniżki zależy od numeru wizyty. Przy osiągnięciu maksymalnej liczby wizyt karta jest resetowana",
    stampStepDiscountSystemPlaceholder:
      "Wprowadź zniżki oddzielone przecinkiem, gdzie każda wartość to numer wizyty",
  },
  de: {
    bonusSystem: "Einfaches Bonussystem",
    bonusSystemDescription: "Ständiger Prozentsatz der Bonusgutschrift",
    bonusSystemPlaceholder: "% Gutschrift",
    bonusBill: 'Bonus "Rechnung"',
    bonusBillDescription:
      "Der Prozentsatz der Gutschriften hängt von der aktuellen Rechnungsgröße ab",
    bonusSum: 'Bonus "Progressiv"',
    bonusSumDescription:
      "Der Prozentsatz der Gutschriften hängt vom Gesamteinkaufsbetrag ab",
    bonusVisit: 'Bonus "Anzahl"',
    bonusVisitDescription:
      "Der Prozentsatz der Gutschriften hängt von der Anzahl der Einkäufe ab",
    bonusLastVisit: 'Bonus "Zeitraum"',
    bonusLastVisitDescription:
      "Der Prozentsatz der Gutschriften hängt von der Zeit seit dem letzten Kauf ab",
    bonusLastVisitPlaceholder:
      "Geben Sie die Anzahl der Tage seit dem letzten Besuch und den Gutschrift-Prozentsatz ein",
    discountSystem: "Einfacher Rabatt",
    discountSystemDescription: "Ständiger Prozentsatz der Rabattgutschrift",
    discountSystemPlaceholder: "% Rabatt",
    discountBill: 'Rabatt "Rechnung"',
    discountBillDescription:
      "Der Prozentsatz des Rabatts hängt von der aktuellen Rechnungsgröße ab",
    discountSum: 'Rabatt "Progressiv"',
    discountSumDescription:
      "Der Prozentsatz des Rabatts hängt vom Gesamteinkaufsbetrag ab",
    discountVisit: 'Rabatt "Anzahl"',
    discountVisitDescription:
      "Der Prozentsatz des Rabatts hängt von der Anzahl der Einkäufe ab",
    discountLastVisit: 'Rabatt "Zeitraum"',
    discountLastVisitDescription:
      "Der Prozentsatz des Rabatts hängt von der Zeit seit dem letzten Kauf ab",
    discountLastVisitPlaceholder:
      "Geben Sie die Anzahl der Tage seit dem letzten Besuch und den Rabatt-Prozentsatz ein",
    stampSystem: "Stempelkarte (jede n-te kostenlos)",
    stampSystemDescription:
      "Wenn die angegebene Anzahl von Einkäufen erreicht ist und ein Geschenk erhalten wird, wird die Karte zurückgesetzt",
    stampSystemPlaceholder: "Anzahl der Punkte, um kostenlos zu erhalten",
    stampStepBonusSystem: "Schrittweise Bonus-Stempelkarte",
    stampStepBonusSystemDescription:
      "Die Bonusgutschrift hängt von der Besuchsnummer ab. Bei Erreichen der maximalen Anzahl von Besuchen wird die Karte zurückgesetzt",
    stampStepBonusSystemPlaceholder:
      "Geben Sie Boni durch Komma getrennt ein, wobei jeder Wert die Besuchsnummer ist",
    stampStepDiscountSystem: "Schrittweise Rabatt-Stempelkarte",
    stampStepDiscountSystemDescription:
      "Die Größe des Rabatts hängt von der Besuchsnummer ab. Bei Erreichen der maximalen Anzahl von Besuchen wird die Karte zurückgesetzt",
    stampStepDiscountSystemPlaceholder:
      "Geben Sie Rabatte durch Komma getrennt ein, wobei jeder Wert die Besuchsnummer ist",
  },
  es: {
    bonusSystem: "Sistema de bonificación simple",
    bonusSystemDescription:
      "Porcentaje constante de acumulación de bonificaciones",
    bonusSystemPlaceholder: "% acumulación",
    bonusBill: 'Bonificación "Cuenta"',
    bonusBillDescription:
      "El porcentaje de acumulación depende del tamaño de la cuenta actual",
    bonusSum: 'Bonificación "Progresiva"',
    bonusSumDescription:
      "El porcentaje de acumulación depende del monto total de compras",
    bonusVisit: 'Bonificación "Cantidad"',
    bonusVisitDescription:
      "El porcentaje de acumulación depende de la cantidad de compras",
    bonusLastVisit: 'Bonificación "Periodo"',
    bonusLastVisitDescription:
      "El porcentaje de acumulación depende del tiempo transcurrido desde la última compra",
    bonusLastVisitPlaceholder:
      "Ingrese la cantidad de días desde la última visita y el porcentaje de acumulación",
    discountSystem: "Descuento simple",
    discountSystemDescription:
      "Porcentaje constante de acumulación de descuentos",
    discountSystemPlaceholder: "% de descuento",
    discountBill: 'Descuento "Cuenta"',
    discountBillDescription:
      "El porcentaje de descuento depende del tamaño de la cuenta actual",
    discountSum: 'Descuento "Progresivo"',
    discountSumDescription:
      "El porcentaje de descuento depende del monto total de compras",
    discountVisit: 'Descuento "Cantidad"',
    discountVisitDescription:
      "El porcentaje de descuento depende de la cantidad de compras",
    discountLastVisit: 'Descuento "Periodo"',
    discountLastVisitDescription:
      "El porcentaje de descuento depende del tiempo transcurrido desde la última compra",
    discountLastVisitPlaceholder:
      "Ingrese la cantidad de días desde la última visita y el porcentaje de descuento",
    stampSystem: "Tarjeta de sellos (cada n-ésima gratis)",
    stampSystemDescription:
      "Cuando se alcanza el número especificado de compras y se recibe un regalo, la tarjeta se reinicia",
    stampSystemPlaceholder: "Número de puntos para obtener uno gratis",
    stampStepBonusSystem: "Tarjeta de sellos con bonificación por pasos",
    stampStepBonusSystemDescription:
      "La acumulación de bonificaciones depende del número de visita. Cuando se alcanza el número máximo de visitas, la tarjeta se reinicia",
    stampStepBonusSystemPlaceholder:
      "Ingrese bonificaciones separadas por comas, donde cada valor es el número de visita",
    stampStepDiscountSystem: "Tarjeta de sellos con descuento por pasos",
    stampStepDiscountSystemDescription:
      "El tamaño del descuento depende del número de visita. Cuando se alcanza el número máximo de visitas, la tarjeta se reinicia",
    stampStepDiscountSystemPlaceholder:
      "Ingrese descuentos separados por comas, donde cada valor es el número de visita",
  },
};

module.exports.swal = {
  uk: {
    demoAccSettingsText:
      "Ви використовуєте тестовий аккаунт.\nВи можете ознайомитись зі вмістом розділу, але не можете його редагувати. Після оформлення підписки ви зможете редагувати та зберігати зміни у налаштуваннях.",
    demoDesignSettingsText:
      "Після оформлення підписки ви зможете змінювати дизайн картки.",
    demoLoyaltySettingsText:
      'Налаштування програми лояльності доступне після оформлення пакету "Лояльність".',
    demoMarketingSettingsText:
      'Налаштування даного розділу доступне після оформлення пакету "Маркетинг".',
    demoTelegramSettingsText:
      'Налаштування даного розділу доступне після оформлення пакету "Телеграм".',
  },
  ru: {
    demoAccSettingsText:
      "Вы используете тестовый аккаунт.\nВы можете ознакомиться с содержимым раздела, но не можете его редактировать. После оформления подписки вы сможете редактировать и сохранять изменения в настройках.",
    demoDesignSettingsText:
      "После оформления подписки вы сможете изменять дизайн карты.",
    demoLoyaltySettingsText:
      'Настройки программы лояльности доступны после оформления пакета "Лояльность".',
    demoMarketingSettingsText:
      'Настройки данного раздела доступны после оформления пакета "Маркетинг".',
    demoTelegramSettingsText:
      'Настройки данного раздела доступны после оформления пакета "Телеграм".',
  },
  pl: {
    demoAccSettingsText:
      "Korzystasz z konta testowego.\nMożesz zapoznać się z zawartością sekcji, ale nie możesz go edytować. Po zakupie subskrypcji będziesz mógł edytować i zapisywać zmiany w ustawieniach.",
    demoDesignSettingsText:
      "Po zakupie subskrypcji będziesz mógł zmieniać projekt karty.",
    demoLoyaltySettingsText:
      'Ustawienia programu lojalnościowego są dostępne po zakupie pakietu "Lojalność".',
    demoMarketingSettingsText:
      'Ustawienia tej sekcji są dostępne po zakupie pakietu "Marketing".',
    demoTelegramSettingsText:
      'Ustawienia tej sekcji są dostępne po zakupie pakietu "Telegram".',
  },
  de: {
    demoAccSettingsText:
      "Sie verwenden ein Testkonto.\nSie können den Inhalt des Abschnitts anzeigen, aber nicht bearbeiten. Nach dem Kauf eines Abonnements können Sie Einstellungen bearbeiten und Änderungen speichern.",
    demoDesignSettingsText:
      "Nach dem Kauf eines Abonnements können Sie das Design der Karte ändern.",
    demoLoyaltySettingsText:
      'Einstellungen des Treueprogramms stehen nach dem Kauf des "Loyalität"-Pakets zur Verfügung.',
    demoMarketingSettingsText:
      'Einstellungen dieses Abschnitts stehen nach dem Kauf des "Marketing"-Pakets zur Verfügung.',
    demoTelegramSettingsText:
      'Einstellungen dieses Abschnitts stehen nach dem Kauf des "Telegram"-Pakets zur Verfügung.',
  },
  es: {
    demoAccSettingsText:
      "Estás utilizando una cuenta de prueba.\nPuedes ver el contenido de la sección, pero no puedes editarlo. Después de suscribirte, podrás editar y guardar cambios en la configuración.",
    demoDesignSettingsText:
      "Después de suscribirte, podrás cambiar el diseño de la tarjeta.",
    demoLoyaltySettingsText:
      'Las configuraciones del programa de lealtad están disponibles después de suscribirte al paquete "Lealtad".',
    demoMarketingSettingsText:
      'Las configuraciones de esta sección están disponibles después de suscribirte al paquete "Marketing".',
    demoTelegramSettingsText:
      'Las configuraciones de esta sección están disponibles después de suscribirte al paquete "Telegram".',
  },
  en: {
    demoAccSettingsText:
      "You are using a demo account.\nYou can view the content of the section but cannot edit it. After subscribing, you will be able to edit and save changes in the settings.",
    demoDesignSettingsText:
      "After subscribing, you can change the card design.",
    demoLoyaltySettingsText:
      'Settings for the loyalty program are available after subscribing to the "Loyalty" package.',
    demoMarketingSettingsText:
      'Settings for this section are available after subscribing to the "Marketing" package.',
    demoTelegramSettingsText:
      'Settings for this section are available after subscribing to the "Telegram" package.',
  },
};

module.exports.imageSizes = {
  uk: {
    icon: "Іконка, що відображається в повідомленнях на заблокованному екрані.\nРозмір 87 x 87 px, має містити фон.",
    logo: "Логотип емітента картки, розміщений у лівому верхньому куті картки.\nКвадратне зображення розміром 150 х 150 px, або прямокутне розміром 480 x 150 px з прозорим фоном. Формат PNG.",
    strip:
      "Основне зображення картки.\nПрямокутне зображення розміром 1125 x 432 px",
    logo_g:
      "Логотип емітента картки, розміщений у лівому верхньому куті картки.\nКругле зображення розміром 1200 x 1200 px, має містити фон.",
    hero: "Основне зображення картки.\nПрямокутне зображення розміром 1032 x 336 px.",
  },
  ru: {
    icon: "Иконка, отображаемая в уведомлениях на заблокированном экране.\nРазмер 87 x 87 px, должна содержать фон.",
    logo: "Логотип эмитента карты, расположенный в левом верхнем углу карты.\nКвадратное изображение размером 150 x 150 px или прямоугольное размером 480 x 150 px с прозрачным фоном. Формат PNG.",
    strip:
      "Основное изображение карты.\nПрямоугольное изображение размером 1125 x 432 px",
    logo_g:
      "Логотип эмитента карты, расположенный в левом верхнем углу карты.\nКруглое изображение размером 1200 x 1200 px, должно содержать фон.",
    hero: "Основное изображение карты.\nПрямоугольное изображение размером 1032 x 336 px.",
  },
  pl: {
    icon: "Ikona widoczna w powiadomieniach na zablokowanym ekranie.\nRozmiar 87 x 87 px, powinna zawierać tło.",
    logo: "Logo emitenta karty, umieszczone w lewym górnym rogu karty.\nKwadratowe zdjęcie o rozmiarze 150 x 150 px lub prostokątne o rozmiarze 480 x 150 px z przezroczystym tłem. Format PNG.",
    strip:
      "Główne zdjęcie karty.\nProstokątne zdjęcie o rozmiarze 1125 x 432 px",
    logo_g:
      "Logo emitenta karty, umieszczone w lewym górnym rogu karty.\nOkrągłe zdjęcie o rozmiarze 1200 x 1200 px, powinno zawierać tło.",
    hero: "Główne zdjęcie karty.\nProstokątne zdjęcie o rozmiarze 1032 x 336 px.",
  },
  es: {
    icon: "Icono visible en las notificaciones en la pantalla bloqueada.\nTamaño 87 x 87 px, debe contener fondo.",
    logo: "Logotipo del emisor de la tarjeta, ubicado en la esquina superior izquierda de la tarjeta.\nImagen cuadrada de 150 x 150 px o rectangular de 480 x 150 px con fondo transparente. Formato PNG.",
    strip:
      "Imagen principal de la tarjeta.\nImagen rectangular de 1125 x 432 px",
    logo_g:
      "Logotipo del emisor de la tarjeta, ubicado en la esquina superior izquierda de la tarjeta.\nImagen redonda de 1200 x 1200 px, debe contener fondo.",
    hero: "Imagen principal de la tarjeta.\nImagen rectangular de 1032 x 336 px.",
  },
  de: {
    icon: "Symbol, das in Benachrichtigungen auf dem Sperrbildschirm angezeigt wird.\nGröße 87 x 87 px, sollte einen Hintergrund enthalten.",
    logo: "Logo des Kartenherausgebers, oben links auf der Karte platziert.\nQuadratisches Bild mit einer Größe von 150 x 150 px oder rechteckiges Bild mit einer Größe von 480 x 150 px mit transparentem Hintergrund. PNG-Format.",
    strip:
      "Hauptbild der Karte.\nRechteckiges Bild mit einer Größe von 1125 x 432 px",
    logo_g:
      "Logo des Kartenherausgebers, oben links auf der Karte platziert.\nRundes Bild mit einer Größe von 1200 x 1200 px, sollte einen Hintergrund enthalten.",
    hero: "Hauptbild der Karte.\nRechteckiges Bild mit einer Größe von 1032 x 336 px.",
  },
  bg: {
    icon: "Икона, която се вижда в известията на заключения екран.\nРазмер 87 х 87 px, трябва да съдържа фон.",
    logo: "Лого на издателя на картата, разположено в горния ляв ъгъл на картата.\nКвадратно изображение с размер 150 х 150 px или правоъгълно с размер 480 х 150 px с прозрачен фон. Формат PNG.",
    strip:
      "Основно изображение на картата.\nПравоъгълно изображение с размер 1125 х 432 px",
    logo_g:
      "Лого на издателя на картата, разположено в горния ляв ъгъл на картата.\nКръгло изображение с размер 1200 х 1200 px, трябва да съдържа фон.",
    hero: "Основно изображение на картата.\nПравоъгълно изображение с размер 1032 х 336 px.",
  },
  en: {
    icon: "Icon displayed in notifications on the locked screen.\nSize 87 x 87 px, should include a background.",
    logo: "Issuer logo on the card, placed in the top left corner.\nSquare image of 150 x 150 px or rectangular image of 480 x 150 px with a transparent background. PNG format.",
    strip: "Main image of the card.\nRectangular image of 1125 x 432 px",
    logo_g:
      "Issuer logo on the card, placed in the top left corner.\nRound image of 1200 x 1200 px, should include a background.",
    hero: "Main image of the card.\nRectangular image of 1032 x 336 px.",
  },
};
