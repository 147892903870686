export const translations = {
  uk: {
    startHeader: "Дайте відповідь на декілька питань",
    startText: "і ми підберемо найкраще рішення для вашого бізнесу",
    question1: "Для якого бізнесу потрібна програма лояльності / картки?",
    response1_1: "Кафе, ресторан, кав'ярня",
    response1_2: "Автомийка, автосервіс, шиномонтаж",
    response1_3: "Барбершоп, салон",
    response1_4: "Спортивний клуб",
    response1_5: "Роздрібна торгівля",
    response1_6: "Інші послуги",
    response1_7: "Онлайн бізнес",
    response1_8: "Інше",

    question2: "Яку програму ви використовуєте?",
    response2_1: "Не використовую жодної",
    response2_2: "HugeProfit",
    response2_3: "Skynum",
    response2_4: "Poster",
    response2_5: "SkyService",
    response2_6: "Altegio",
    response2_7: "Syrve",
    response2_8: "TurboPOS",
    response2_9: "BAS / 1C",
    response2_10: "Інше",

    question3:
      "Яку програму лояльності ви плануєте використати або вже використовуєте?",
    response3_1: "Знижки",
    response3_2: "Бонуси (кешбек)",
    response3_3: "Штамповані картки",
    response3_4: "Клубні картки",
    response3_5: "Інше",

    question4:
      "Чи ведете ви базу клієнтів? Якщо так, то як ви ідентифікуєте клієнта?",
    response4_1: "Ні",
    response4_2: "Скануємо картку",
    response4_3: "Клієнт показує картку",
    response4_4: "Клієнт називає номер телефону",
    response4_5: "Інше",

    question5: "Ваші контактні дані",
    name: "Ваше ім'я",
    confirm: "Ви підтверджуєте, що ознайомилися з нашою ",
    confirmLink: "Політикою конфіденційності",
    submit: "Надіслати",

    thank: "Дякуємо за інтерес до нашого сервісу",
    staffWillContact:
      "Наш співробітник зв'яжеться з вами найближчим часом із пропозицією та подальшими інструкціями для запуску вашої програми лояльності.",
    visitSite:
      "Відвідайте наш веб-сайт, щоб отримати додаткову інформацію про наш сервіс.",

    question: "Питання",
    from: "з",

    start: "Почати",
    back: "Назад",
    next: "Далі",
  },
  en: {
    startHeader: "Answer a few questions",
    startText: "and we'll find the best solution for your business",
    question1: "What type of business needs a loyalty program / cards?",
    response1_1: "Café, restaurant, coffee shop",
    response1_2: "Car wash, auto repair shop, tire service",
    response1_3: "Barbershop, salon",
    response1_4: "Gym, fitness club",
    response1_5: "Retail store",
    response1_6: "Other services",
    response1_7: "Online business",
    response1_8: "Other",

    question2: "Which program do you use?",
    response2_1: "Not using any",
    response2_2: "HugeProfit",
    response2_3: "Skynum",
    response2_4: "Poster",
    response2_5: "SkyService",
    response2_6: "Altegio",
    response2_7: "Syrve",
    response2_8: "TurboPOS",
    response2_9: "BAS / 1C",
    response2_10: "Other",

    question3: "Which loyalty program do you plan to use or already use?",
    response3_1: "Discounts",
    response3_2: "Bonuses (cashback)",
    response3_3: "Stamp cards",
    response3_4: "Club cards",
    response3_5: "Other",

    question4:
      "Do you keep a customer database? If yes, how do you identify the customer?",
    response4_1: "No",
    response4_2: "Scan the card",
    response4_3: "Customer shows the card",
    response4_4: "Customer provides phone number",
    response4_5: "Other",

    question5: "Your contact details",
    name: "Your name",
    confirm: "You confirm that you have read our ",
    confirmLink: "Privacy Policy",
    submit: "Submit",

    thank: "Thank you for your interest in our service",
    staffWillContact:
      "Our staff will contact you shortly with a proposal and further instructions for launching your loyalty program.",
    visitSite: "Visit our website for more information about our service.",

    question: "Question",
    from: "from",

    start: "Start",
    back: "Back",
    next: "Next",
  },
  ru: {
    startHeader: "Ответьте на несколько вопросов",
    startText: "и мы подберем наилучшее решение для вашего бизнеса",
    question1: "Для какого бизнеса необходима программа лояльности / карты?",
    response1_1: "Кофейня, ресторан, кафе",
    response1_2: "Автомойка, СТО, Шиномонтаж",
    response1_3: "Барбершоп, салон",
    response1_4: "Спортивный клуб",
    response1_5: "Розничная торговля",
    response1_6: "Прочие услуги",
    response1_7: "Онлайн бизнес",
    response1_8: "Другое",

    question2: "Какую программу используете?",
    response2_1: "Не использую",
    response2_2: "HugeProfit",
    response2_3: "Skynum",
    response2_4: "Poster",
    response2_5: "SkyService",
    response2_6: "Altegio",
    response2_7: "Syrve",
    response2_8: "TurboPOS",
    response2_9: "BAS / 1C",
    response2_10: "Другое",

    question3:
      "Какую программу лояльности планируете использовать или уже используете?",
    response3_1: "Скидки",
    response3_2: "Бонусы (кешбек)",
    response3_3: "Штамп-карты",
    response3_4: "Клубные карты",
    response3_5: "Другое",

    question4:
      "Вы ведете клиентскую базу? Если да, то как определяете клиента?",
    response4_1: "Нет",
    response4_2: "Сканируем карту",
    response4_3: "Клиент показывает карту",
    response4_4: "Клиент диктует номер телефона",
    response4_5: "Другое",

    question5: "Ваши контактные данные",
    name: "Ваше имя",
    confirm: "Вы подтверждаете, что ознакомились с ",
    confirmLink: "Политикой Конфиденциальности",
    submit: "Отправить",

    thank: "Спасибо за проявленный интерес к нашему сервису",
    staffWillContact:
      "Наш сотрудник свяжется с вами в ближайшее время с предложением и дальнейшими инструкциями для запуска вашей программы лояльности.",
    visitSite:
      "Посетите наш сайт для получения дополнительной информации о нашем сервисе.",

    question: "Вопрос",
    from: "из",

    start: "Начать",
    back: "Назад",
    next: "Далее",
  },
  pl: {
    startHeader: "Odpowiedz na kilka pytań",
    startText: "a my znajdziemy najlepsze rozwiązanie dla Twojej firmy",
    question1:
      "Dla jakiego rodzaju biznesu potrzebny jest program lojalnościowy / karty?",
    response1_1: "Kawiarnia, restauracja, kawiarnia",
    response1_2: "Myjnia samochodowa, warsztat samochodowy, serwis opon",
    response1_3: "Barbershop, salon",
    response1_4: "Siłownia, klub fitness",
    response1_5: "Sklep detaliczny",
    response1_6: "Inne usługi",
    response1_7: "Biznes online",
    response1_8: "Inne",

    question2: "Jakiego programu używasz?",
    response2_1: "Nie używam",
    response2_2: "HugeProfit",
    response2_3: "Skynum",
    response2_4: "Plakat",
    response2_5: "SkyService",
    response2_6: "Altegio",
    response2_7: "Syrve",
    response2_8: "TurboPOS",
    response2_9: "BAS / 1C",
    response2_10: "Inne",

    question3: "Jaki program lojalnościowy planujesz użyć lub już używasz?",
    response3_1: "Rabaty",
    response3_2: "Bonusy (cashback)",
    response3_3: "Karty stemplowane",
    response3_4: "Karty klubowe",
    response3_5: "Inne",

    question4:
      "Czy prowadzisz bazę klientów? Jeśli tak, to jak identyfikujesz klienta?",
    response4_1: "Nie",
    response4_2: "Skanuj kartę",
    response4_3: "Klient pokazuje kartę",
    response4_4: "Klient podaje numer telefonu",
    response4_5: "Inne",

    question5: "Twoje dane kontaktowe",
    name: "Twoje imię",
    confirm: "Potwierdzasz, że przeczytałeś naszą ",
    confirmLink: "Polityka prywatności",
    submit: "Prześlij",

    thank: "Dziękujemy za zainteresowanie naszą usługą",
    staffWillContact:
      "Nasz personel skontaktuje się z Tobą wkrótce z propozycją i dalszymi instrukcjami dotyczącymi uruchomienia Twojego programu lojalnościowego.",
    visitSite:
      "Odwiedź naszą stronę internetową, aby uzyskać więcej informacji na temat naszej usługi.",

    question: "Pytanie",
    from: "z",

    start: "Rozpocznij",
    back: "Wstecz",
    next: "Dalej",
  },
  de: {
    startHeader: "Beantworten Sie einige Fragen",
    startText: "und wir finden die beste Lösung für Ihr Unternehmen",
    question1:
      "Für welche Art von Unternehmen wird ein Treueprogramm / Karten benötigt?",
    response1_1: "Café, Restaurant, Kaffeehaus",
    response1_2: "Autowaschanlage, Autowerkstatt, Reifenservice",
    response1_3: "Barbershop, Salon",
    response1_4: "Fitnessstudio, Fitnessclub",
    response1_5: "Einzelhandelsgeschäft",
    response1_6: "Andere Dienstleistungen",
    response1_7: "Online-Geschäft",
    response1_8: "Andere",

    question2: "Welches Programm verwenden Sie?",
    response2_1: "Keines verwenden",
    response2_2: "HugeProfit",
    response2_3: "Skynum",
    response2_4: "Plakat",
    response2_5: "SkyService",
    response2_6: "Altegio",
    response2_7: "Syrve",
    response2_8: "TurboPOS",
    response2_9: "BAS / 1C",
    response2_10: "Andere",

    question3:
      "Welches Treueprogramm planen Sie zu verwenden oder verwenden Sie bereits?",
    response3_1: "Rabatte",
    response3_2: "Bonusse (Cashback)",
    response3_3: "Stempelkarten",
    response3_4: "Clubkarten",
    response3_5: "Andere",

    question4:
      "Führen Sie eine Kundendatenbank? Wenn ja, wie identifizieren Sie den Kunden?",
    response4_1: "Nein",
    response4_2: "Karte scannen",
    response4_3: "Kunde zeigt die Karte",
    response4_4: "Kunde gibt Telefonnummer an",
    response4_5: "Andere",

    question5: "Ihre Kontaktdaten",
    name: "Dein Name",
    confirm: "Sie bestätigen, dass Sie unsere gelesen haben ",
    confirmLink: "Datenschutz-Bestimmungen",
    submit: "Einreichen",

    thank: "Vielen Dank für Ihr Interesse an unserem Service",
    staffWillContact:
      "Unser Mitarbeiter wird sich in Kürze mit einem Vorschlag und weiteren Anweisungen zur Einrichtung Ihres Treueprogramms bei Ihnen melden.",
    visitSite:
      "Besuchen Sie unsere Website für weitere Informationen zu unserem Service.",

    question: "Frage",
    from: "aus",

    start: "Anfangen",
    back: "Zurück",
    next: "Weiter",
  },
  es: {
    startHeader: "Responde algunas preguntas",
    startText: "y encontraremos la mejor solución para tu negocio",
    question1:
      "¿Qué tipo de negocio necesita un programa de lealtad / tarjetas?",
    response1_1: "Café, restaurante, cafetería",
    response1_2: "Lavado de coches, taller mecánico, servicio de neumáticos",
    response1_3: "Barbería, salón",
    response1_4: "Gimnasio, club de fitness",
    response1_5: "Tienda minorista",
    response1_6: "Otros servicios",
    response1_7: "Negocio en línea",
    response1_8: "Otro",

    question2: "¿Qué programa utilizas?",
    response2_1: "No uso ninguno",
    response2_2: "HugeProfit",
    response2_3: "Skynum",
    response2_4: "Póster",
    response2_5: "SkyService",
    response2_6: "Altegio",
    response2_7: "Syrve",
    response2_8: "TurboPOS",
    response2_9: "BAS / 1C",
    response2_10: "Otro",

    question3: "¿Qué programa de lealtad planeas usar o ya estás usando?",
    response3_1: "Descuentos",
    response3_2: "Bonificaciones (cashback)",
    response3_3: "Tarjetas de sellos",
    response3_4: "Tarjetas de club",
    response3_5: "Otro",

    question4:
      "¿Mantiene una base de datos de clientes? En caso afirmativo, ¿cómo identifica al cliente?",
    response4_1: "No",
    response4_2: "Escanear la tarjeta",
    response4_3: "El cliente muestra la tarjeta",
    response4_4: "El cliente proporciona un número de teléfono",
    response4_5: "Otro",

    question5: "Tus datos de contacto",
    name: "Tu nombre",
    confirm: "Confirmas que has leído nuestra ",
    confirmLink: "Política de privacidad",
    submit: "Enviar",

    thank: "Gracias por tu interés en nuestro servicio",
    staffWillContact:
      "Nuestro personal se pondrá en contacto contigo en breve con una propuesta y más instrucciones para lanzar tu programa de lealtad.",
    visitSite:
      "Visita nuestro sitio web para obtener más información sobre nuestro servicio.",

    question: "Pregunta",
    from: "de",

    start: "Comenzar",
    back: "Atrás",
    next: "Siguiente",
  },
};
