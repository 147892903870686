export default (options = {}) => {

    const languages = navigator.languages

    if (!languages) return localStorage.language = 'en'

    const trimmedLocale = languages[0].trim()

    const language = navigator.language ? navigator.language.split(/-|_/)[0].trim() : trimmedLocale.split(/-|_/)[0]

    console.log(language);
    switch (language) {
        case 'ru':
        case 'uk':
        case 'pl':
        case 'de':
        case 'es':
            localStorage.language = language
            break
        case 'he':
        case 'az':
        case 'kk':
        case 'be':
        case 'hy':
            localStorage.language = 'ru'
            break
        default:
            localStorage.language = 'en'
    }

}
