import React, {useState, useEffect} from "react"
import axios from "../../utils/axiosConfig"
import {useNavigate} from "react-router-dom";
import Header from "../../layout/Header";
import {settingsHeader, editPass, cardsDisplay, alerts, header, swal} from "../../constants/translations"
import SettingsHeader from "../../layout/SettingsHeader";
import Birthday from "./Birthday";
import RegularMessage from "./RegularMessage";
import Segment from "./Segment";
import Swal from 'sweetalert2'

export default () => {
    const language = localStorage.language
    const role = localStorage.role
    const [isError, setIsError] = useState(false)
    const [isInfo, setIsInfo] = useState(false)
    const [alert, setAlert] = useState('Error')
    const [disabled, setDisabled] = useState(false)

    const [isLoading, setIsLoading] = useState(true)
    const navigate = useNavigate()
    const [passSettings, setPassSettings] = useState()
    const [category, setCategory] = useState(false)
    const [birthday, setBirthday] = useState()
    const [messages, setMessages] = useState([])
    const [segments, setSegments] = useState([])

    useEffect(() => {
        axios.get('/api/passes/settings')
            .then(response => {
                if (response.status !== 200) return setIsLoading(false)
                setPassSettings(response.data)
                setCategory(response.data.category.length > 0)
                setBirthday(response.data.birthday)
                setIsLoading(false)
                setDisabled(!response.data.marketing)


                if(response.data?.test || !response.data?.marketing){
                    Swal.fire({
                        title: 'Marketing',
                        text: swal[language].demoMarketingSettingsText,
                        confirmButtonText: 'OK',
                        confirmButtonColor: "#0d6efd"
                    })
                }

            })
            .catch(e => {
                console.log('ERRORR', e.response.status)
                setIsLoading(false)
                e.response.status === 401 && navigate('/user/login')
                e.response.status === 402 && navigate('/402')
                e.response.status === 403 && navigate('/403')
                e.response.status === 404 && setPassSettings([])
            })

        axios.get('/api/marketing/messages')
            .then(response => {
                if (response.status !== 200) return setIsLoading(false)
                setMessages(response.data)
                setIsLoading(false)
            })
            .catch(e => {
                console.log('ERRORR', e.response.status)
                setIsLoading(false)
                e.response.status === 401 && navigate('/user/login')
                e.response.status === 402 && navigate('/402')
                e.response.status === 403 && navigate('/403')
                e.response.status === 404 && setMessages([])
            })

        axios.get('/api/marketing/segments')
            .then(response => {
                if (response.status !== 200) return setIsLoading(false)
                setSegments(response.data)
                setIsLoading(false)
            })
            .catch(e => {
                console.log('ERRORR', e.response.status)
                setIsLoading(false)
                e.response.status === 401 && navigate('/user/login')
                e.response.status === 402 && navigate('/402')
                e.response.status === 403 && navigate('/403')
                e.response.status === 404 && setSegments([])
            })

    }, [])

    const handleChange = (event) => {
        let {name, value, type, checked} = event.target
        if (name === 'category') value = value.replace(/\s/g, '').split(',')
        type === 'checkbox' ? setPassSettings({...passSettings, [name]: checked}) : setPassSettings({...passSettings, [name]: value})
    }

    const handleChangeCategory = () => {
        setCategory(prevState => {
            if (prevState) {
                setPassSettings({...passSettings, category: []})
            }
            return !prevState
        })
    }

    const handleSubmit = async () => {
        setIsLoading(true)
        setDisabled(true)
        try {
            await axios.put('/api/passes/settings', passSettings)
                .then(response => {

                    if (response.status !== 200) return setIsLoading(false)
                    setIsLoading(false)
                    setIsInfo(true)
                    setAlert(alerts[language].success)
                    setTimeout(() => {
                        setIsInfo(false)
                        setDisabled(false)
                    }, 2000)

                })
                .catch(e => {
                    console.log('ERRORR', e.response.status)
                    setIsLoading(false)

                    e.response.status === 401 && navigate('/user/login')
                    e.response.status === 402 && navigate('/402')
                    e.response.status === 403 && navigate('/403')
                })
        } catch (e) {
            console.error(e);
        }
    }

    return (
        <>
            {isInfo && (
                <div className="alert alert-info" role="alert">
                    {alert}
                </div>
            )}
            {isError && (
                <div className="alert alert-danger" role="alert">
                    {alert}
                </div>
            )}

            <h4 className={"text-primary"}>{header[language].settings}</h4>
            <hr className={"mt-0 mb-0"} />
            <SettingsHeader/>

            <Birthday passSettings={passSettings}/>
            <Segment passSettings={passSettings} segments={segments}/>
            <RegularMessage passSettings={passSettings} messages={messages}/>

            {passSettings &&
            <div className='card p-3 col-md-12 col-xl-9 m-auto mb-5'>
                <div className="input-group-sm">

                    {/**/}
                    <h4 className='mt-3'>{settingsHeader[language].additionalData}</h4>
                    <hr/>

                    <div className="form-check form-check">
                        <input className="form-check-input"
                               disabled={disabled}
                               type="checkbox"
                               id="expirationDate"
                               name="expirationDate"
                               checked={passSettings?.expirationDate}
                               onChange={handleChange}/>
                        <label className="form-check-label"
                               htmlFor="expirationDate">{editPass[language].expirationDate}</label>
                    </div>
                    <div className="form-check form-check">
                        <input className="form-check-input"
                               disabled={disabled}
                               type="checkbox"
                               id="period"
                               name="period"
                               checked={passSettings?.period}
                               onChange={handleChange}/>
                        <label className="form-check-label" htmlFor="period">{settingsHeader[language].period}</label>
                    </div>
                    <div className="form-check form-check">
                        <input className="form-check-input"
                               disabled={disabled}
                               type="checkbox"
                               id="photo"
                               name="photo"
                               checked={passSettings?.photo}
                               onChange={handleChange}/>
                        <label className="form-check-label" htmlFor="photo">{editPass[language].photo}</label>
                    </div>
                    <div className="form-check form-check">
                        <input className="form-check-input"
                               disabled={disabled}
                               type="checkbox"
                               id="category"
                               checked={category}
                               onChange={handleChangeCategory}/>
                        <label className="form-check-label" htmlFor="category">{editPass[language].category}</label>
                    </div>
                    {category &&
                    <input
                        disabled={disabled}
                        type="text"
                        className="form-control"
                        placeholder={settingsHeader[language].arrayData}
                        name='category'
                        id="category"
                        value={passSettings?.category}
                        onChange={handleChange}
                    />}


                    <div className={'input-group-sm'}>
                        <label htmlFor="currentNumber">{settingsHeader[language].currentNumber}</label>
                        <input
                            disabled={disabled}
                            type="number"
                            className="form-control"
                            name='currentNumber'
                            id="currentNumber"
                            value={passSettings?.currentNumber}
                            onChange={handleChange}
                        />
                    </div>
                    <div className='float-end'>
                        <button
                            disabled={disabled || role !== 'admin'}
                            className={'btn btn-outline-primary'}
                            onClick={handleSubmit}>
                            {editPass[language].submitButton}
                        </button>
                    </div>
                </div>
            </div>
            }
        </>
    )
}
