import React, {useState, useEffect} from "react"
import axios from "../utils/axiosConfig"
import {useParams, useNavigate} from "react-router-dom"
import { ThreeDots } from  'react-loader-spinner'
import {cardsDisplay, transaction} from "../constants/translations"
import XLSX from 'xlsx'

export default () => {

    const {passTypeIdentifier, serialNumber} = useParams()
    const navigate = useNavigate()
    const language = localStorage.language

    const [transactions, setTransactions] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [qty, setQty] = useState(10)

    let points = 0
    let sum = 0
    let balance = 0
    let add = 0
    let sub = 0

    useEffect(() => {
        let url = `/api/loyalty/transaction?qty=${qty}`
        if (passTypeIdentifier && serialNumber) url = `/api/loyalty/transaction/${passTypeIdentifier}/${serialNumber}?qty=${qty}`

        axios.get(url)
            .then(res => {
                setTransactions(res.data)
                setIsLoading(false)
            })
            .catch(e => {
                setIsLoading(false)
                e.response.status === 401 && navigate({pathname: '/user/login', search: 'scan'})
                //todo to error page
                e.response.status === 402 && navigate('/402')
                e.response.status === 403 && navigate('/403')
                e.response.status === 404 && setTransactions([])
                console.log(e)
            })

    }, [qty, navigate])

    const tableBody = transactions.map((item, index) => {
        item.points && !isNaN(item.points) && (points += +item.points)
        item.sum && !isNaN(item.sum) && (sum += +item.sum)
        item.balance && !isNaN(item.balance) && (balance += +item.balance)

        if(item.points && !isNaN(item.points)){
            if (item.action === 'accumulation') {
                add += +item.points
            }
            if (item.action === 'redemption') {
                sub += +item.points
            }
        }

        return (
            <tr key={index}>
                <td>{(new Date(item.createdAt)).toLocaleDateString()}</td>
                <td>{(new Date(item.createdAt)).toLocaleTimeString()}</td>
                <td>{item.firstName} {item.lastName}</td>
                <td>{item.action === 'accumulation' ?
                    transaction[language].accumulation :
                    item.action === 'redemption' ?
                        transaction[language].redemption :
                        item.action === 'discount' ?
                            transaction[language].discount :
                            item.action === 'referrer' ?
                                transaction[language].referrer :
                                item.action === 'referral' ?
                                    transaction[language].referral :
                                    ''}
                </td>
                <td>{item.points}</td>
                <td>{item.sum}</td>
                <td>{item.balance}</td>

                <td>{item.discount && item.discount + '%'}</td>

                <td>{item.user}</td>
            </tr>)
    })

    const downloadTransactions = () => {
        const data = transactions.map(el => {
            delete el._id
            delete el.organisationId
            delete el.updatedAt
            return el
        })
        const fileName = (new Date()).toLocaleString().replace( /[, .:]/g, '_')
        const ws = XLSX.utils.json_to_sheet(data)
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, 'transactions')
        XLSX.writeFile(wb, `${fileName}.xlsx`)
    }


    return (
        <>
            <h4 className={"text-primary"}>{transaction[language].transaction} </h4>
            <hr className={"mt-0 mb-5"} />

            <div>
                <div className="btn-toolbar justify-content-between" role="toolbar" >
                    <div className="btn-group mb-3">
                        <button
                            type="button"
                            className={"btn btn-outline-secondary"}
                            onClick={() => setQty(50)}>
                            50
                        </button>
                        <button
                            type="button"
                            className={"btn btn-outline-secondary"}
                            onClick={() => setQty(100)}>
                            100
                        </button>
                        <button
                            type="button"
                            className={"btn btn-outline-secondary"}
                            onClick={() => setQty(250)}>
                            250
                        </button>
                        <button
                            type="button"
                            className={"btn btn-outline-secondary"}
                            onClick={() => setQty(500)}>
                            500
                        </button>
                        <button
                            type="button"
                            className={"btn btn-outline-secondary"}
                            onClick={() => setQty(500)}>
                            {transaction[language].showAll}
                        </button>
                    </div>
                    <div>
                        <button
                            type={"button"}
                            className={"btn btn-outline-secondary"}
                            onClick={() => navigate('/transaction')}
                        >
                            {transaction[language].clearFilter}
                        </button>
                        <button
                            type={"button"}
                            className={"btn btn-outline-secondary ms-3"}
                            onClick={() => downloadTransactions()}
                        >
                            {transaction[language].download}
                        </button>
                    </div>
                </div>

            </div>
            {
                isLoading ? <ThreeDots
                        height="80"
                        width="80"
                        radius="9"
                        color="#0d6efd"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                    /> :
                    <div className="table-responsive">
                        <table className="table mt-5 table-sm">
                            <thead>
                            <tr>
                                <th>{transaction[language].date}</th>
                                <th>{transaction[language].time}</th>
                                <th>{transaction[language].name}</th>
                                <th>{transaction[language].action}</th>
                                <th>{transaction[language].points}</th>
                                <th>{transaction[language].sum}</th>
                                <th>{transaction[language].balance}</th>
                                <th>{transaction[language].discount}</th>
                                <th>{transaction[language].user}</th>
                            </tr>
                            </thead>

                            <tbody>
                            {tableBody}
                            </tbody>
                            <tfoot>
                            <tr>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th>{(add !== 0) && transaction[language].accumulation + ': ' + (Math.round(add *100))/100}</th>
                                <th>{(sub !== 0) && transaction[language].redemption + ': ' + (Math.round(sub *100))/100}</th>
                                <th>{(sum !== 0) && transaction[language].sum +': ' + (Math.round(sum *100))/100}</th>
                                {/*<th>{(balance !== 0) && transaction[language].balance +': ' + (Math.round(balance *100))/100}</th>*/}
                            </tr>


                            </tfoot>
                        </table>
                    </div>
            }

        </>
    )
}