import React, { useEffect } from 'react'

function Radio({data, client, handleChange, setDisabled}) {

    useEffect(()=>{
        !client[data[0].name] ? setDisabled(true) : setDisabled(false);
    }, [client, data])

    console.log(client[data[0].name]);

      return data?.map((q, index) => (
        <div key={index} className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name={q.name}
            value={q.value}
            id={index}
            onChange={handleChange}
            checked={client[q.name] === q.value}
          />
          <label className="form-check-label" htmlFor={index}>
            {q.value}
          </label>
        </div>
      ));
}

export default Radio