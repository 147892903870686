import React, {useState, useEffect} from "react"
import axios from "../utils/axiosConfig"
import {useNavigate} from "react-router-dom";
import Header from "../layout/Header";
import {header, userDisplay} from "../constants/translations"

export default () => {
    const language = localStorage.language
    const [users, setUsers] = useState([])

    const navigate = useNavigate()

    useEffect( () => {
        axios
            .get('/api/users')
            .then(response => setUsers(response.data))
            .catch(e => {
                console.log(e.response.status)
                e.response.status === 401 && navigate('/user/login')
            })
    }, [navigate])

    // users && console.log(users)

    const handleDelete = (_id, email) => {
        console.log(_id, email);
        axios
            .delete(`/api/users/${_id}`)
            .then(response => {
                if (response.status === 200) setUsers(prevState => prevState.filter(user => user._id !== _id))
            })
            .catch(e => console.log(e))
    }

    const handleEdit = (_id, email) => {
        console.log(_id, email);
        // axios
        //     .delete(`/api/users/${_id}`)
        //     .then(response => {
        //         if (response.status === 200) setUsers(prevState => prevState.filter(user => user._id !== _id))
        //     })
        //     .catch(e => console.log(e))
    }

    const handleResetPassword = async (_id, email) => {
        console.log(_id, email);


        // axios
        //     .delete(`/api/users/${_id}`)
        //     .then(response => {
        //         if (response.status === 200) setUsers(prevState => prevState.filter(user => user._id !== _id))
        //     })
        //     .catch(e => console.log(e))
    }

    const handleClick = () => {
        navigate('/user/create')
    }


    return (
        <div>

            {/*<Header />*/}
            <h4 className={"text-primary"}>{header[language].users}</h4>
            <hr className={"mt-0 mb-5"} />
            <div className="table-responsive">
                <table className="table">
                    <thead>
                    <tr>
                        <th>{userDisplay[language].department}</th>
                        <th>{userDisplay[language].email}</th>
                        <th>{userDisplay[language].role}</th>
                        <th>{userDisplay[language].status}</th>
                        {/*<th></th>*/}
                        <th></th>
                        <th>
                            <button style={{width: '100%'}} onClick={handleClick} className="btn btn-outline-primary btn-sm">{userDisplay[language].newUser}</button>
                        </th>
                    </tr>
                    </thead>

                    <tbody>
                    {users.map(user => (
                        <tr key={user._id}>
                            <th>{user.department}</th>
                            <td>{user.email}</td>
                            <td>{user.role}</td>
                            <td>{user.status}</td>
                            {/*<td>*/}
                            {/*    <button*/}
                            {/*        style={{width: '100%'}}*/}
                            {/*        className={'btn btn-outline-secondary btn-sm'}*/}
                            {/*        onClick={(e) => handleResetPassword(user._id, user.email)}*/}
                            {/*    >*/}
                            {/*        {userDisplay[language].resetPassword}*/}
                            {/*    </button>*/}
                            {/*</td>*/}
                            <td>
                                <button
                                    style={{width: '100%'}}
                                    className={'btn btn-outline-secondary btn-sm'}
                                    onClick={() => navigate(`/user/edit/${user.email}`)}
                                >
                                    {userDisplay[language].edit}
                                </button>

                            </td>
                            <td>
                                <button
                                    style={{width: '100%'}}
                                    className={'btn btn-outline-danger btn-sm'}
                                    onClick={(e) => handleDelete(user._id, user.email)}
                                >
                                    {userDisplay[language].delete}
                                </button>
                            </td>
                        </tr>
                    ))}
                    </tbody>

                </table>
            </div>

        </div>
    )
}
