import React, { useEffect, useState } from "react";
import axios from "../utils/axiosConfig";
import { error } from "../constants/translations";
import LiqPayButton from "../components/LiqPayButton";

export default (props) => {
  const language = localStorage.language;
  const [btnData, setBtnData] = useState({});

  useEffect(() => {
    if (+localStorage.hp === 110) {
      axios
        .post(`/api/pass/payments/card/${localStorage.organisationId}`)
        .then((res) => {
          setBtnData(res.data);
        })
        .catch((e) => console.log(e));
    }
  }, []);

  return (
    <div id="notfound">
      <div className="notfound">
        <div className="notfound-404">
          <h1>Oops!</h1>
          <h2>402 - {error[language].notPaid}</h2>
        </div>
        {localStorage.organisationId &&
        +localStorage.hp !== 110 &&
        +localStorage.hp !== 120 ? (
          <LiqPayButton
            organisationId={localStorage.organisationId}
            email={localStorage.email}
            role={localStorage.role}
          />
        ) : btnData?.url ? (
          <form method="POST" action={btnData.url} acceptCharset="utf-8">
            <button>
              {language === "uk" ? "Сплатити карткою" : "Pay by Card"}
            </button>
          </form>
        ) : (
          <a href="https://t.me/loyalcards">{error[language].goToContact}</a>
        )}
      </div>
    </div>
  );
};
