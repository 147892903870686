import React, {useEffect, useState} from "react"
import {NavLink, useNavigate} from "react-router-dom";

import {settingsHeader} from '../constants/translations'

export default () => {

    const language = localStorage.language
    const navigate = useNavigate()
    const [role, setRole] = useState("user")
    useEffect(() => {
        setRole(localStorage.role)
    }, [navigate, role])

    return (
        <div className={"d-flex mb-5 navbar"}>
            <ul className="nav nav-pills">
                {/*<li className="nav-item">*/}
                {/*    {role === 'admin' && <NavLink className="nav-link" to={"/settings"}>{settingsHeader[language].general}</NavLink>}*/}
                {/*</li>*/}
                {/*<li className="nav-item">*/}
                {/*    {role === 'admin' && <NavLink className="nav-link" to={"/design-settings"}>{settingsHeader[language].design}</NavLink>}*/}
                {/*</li>*/}
                <li className="nav-item">
                    {(role === 'admin' || role === 'test') && <NavLink className="nav-link" to={"/messages/push"}>Apple & Google Wallet</NavLink>}
                </li>
                <li className="nav-item">
                    {(role === 'admin' || role === 'test') && <NavLink className="nav-link" to={"/messages/telegram"}>Telegram</NavLink>}
                </li>
            </ul>
        </div>
    )
}