import React, {useState, useEffect} from "react"
import axios from "../utils/axiosConfig"
import {useParams, useNavigate} from "react-router-dom"
import QRCode from "react-qr-code"
import Modal from "react-modal"
import getBrowserLocales from "../utils/getBrowserLocales"
import {scan, transaction as transactions} from '../constants/translations'

export default () => {

    if (!localStorage.language) {
        getBrowserLocales()
    }

    const language = localStorage.language

    const [disabled, setDisabled] = useState(true)
    const [card, setCard] = useState({})
    const [points, setPoints] = useState()
    const [balance, setBalance] = useState(0)
    const [completed, setCompleted] = useState(false)
    const [placeholder, setPlaceholder] = useState(scan[language].points)
    const [input, setInput] = useState()
    const [bonus, setBonus] = useState()
    const [freeNumber, setFreeNumber] = useState()

    const [changeImage, setChangeImage] = useState(false)
    const [discounts, setDiscounts] = useState([])
    const [calculateDiscount, setCalculateDiscount] = useState(false)

    const [action, setAction] = useState('')

    const [isOpen, setIsOpen] = useState(false)
    const [modalStyle, setModalStyle] = useState({})
    const [modalData, setModalData] = useState()

    const navigate = useNavigate()

    const {passTypeIdentifier, serialNumber} = useParams()

    let transaction = {}

    useEffect(() => {
        axios.get(`/api/v1/passes/${passTypeIdentifier}/${serialNumber}/values`)
            .then(res => {
                setCard(res.data)
                setBalance(+res.data?.balance)
                if (res.data?.bonus){
                    setPlaceholder(scan[language].totalValue)
                    setBonus(+res.data?.bonus)
                }
                else if (res.data?.freeNumber){
                    setFreeNumber(+res.data?.freeNumber)
                    // setFreeNumber(5)
                    setDisabled(false)
                    setInput('')
                } else if (res.data?.discounts?.length > 0){
                    setDiscounts(res?.data?.discounts)
                    setDisabled(false)
                    setInput('')
                }
            })
            .catch(e => {
                e.response.status === 401 && navigate({pathname: '/user/login', search: 'scan'})
                e.response.status === 402 && navigate('/402')
                e.response.status === 403 && navigate('/403')
                e.response.status === 404 && navigate('/error')
                // console.log(e.response);
            })

    }, [])

    const handleChange = e => {
        if (!isNaN(+e.target.value)) setDisabled(false)
        setInput(Math.round(+e.target.value * 100)/100)
        if (bonus){
            setPoints(Math.round(+e.target.value * bonus) / 100)
        } else setPoints(Math.round(+e.target.value * 100)/100)
    }


    const handleClick = (e, arg) => {
        let newBalance
        setDisabled(true)
        e.preventDefault()

        if (freeNumber){
            if (arg === 'add') {
                setAction('add')
                setBalance(prevState => Math.round((prevState + 1) * 100) / 100)
                newBalance = Math.round((balance + 1) * 100) / 100
                // setPoints(1)
                Object.assign(transaction, {action: 'accumulation', points: 1, balance: newBalance})
            } else if (arg === 'sub') {
                setBalance(prevState => Math.round((prevState - freeNumber) * 100) / 100)
                newBalance = Math.round((balance - freeNumber) * 100) / 100
                // setPoints(freeNumber)
                Object.assign(transaction, {action: 'redemption', points: freeNumber, balance: newBalance})
            }
        } else
        if (discounts && Array.isArray(discounts) && discounts?.length > 1){
            if (arg === 'add') {
                setAction('add')
                setBalance(prevState => Math.round((prevState + 1) * 100) / 100)
                newBalance = Math.round((balance + 1) * 100) / 100
                console.log(balance);
                // setPoints(1)
                Object.assign(transaction, {action: 'accumulation', points: 1, balance: newBalance, discount: discounts[newBalance-1]})
            } else if (arg === 'sub') {
                setBalance(prevState => Math.round((prevState - (discounts.length -1)) * 100) / 100)
                newBalance = 0
                // setPoints(discounts.length -1)
                Object.assign(transaction, {action: 'redemption', points: discounts.length -1, balance: newBalance, discount: discounts[discounts.length-1]})
            }
        }
            // else if (finalNumber){
            //     if (arg === 'add') {
            //         setAction('add')
            //         setBalance(prevState => Math.round((prevState + 1) * 100) / 100)
            //         newBalance = Math.round((balance + 1) * 100) / 100
            //         // setPoints(1)
            //         console.log(intermediateNumber, newBalance);
            //         Object.assign(transaction, {action: 'accumulation', points: 1, balance: newBalance})
            //         if (intermediateNumber && intermediateNumber === newBalance) Object.assign(transaction, {
            //             action: 'discount', discount: intermediateDiscount})
            //         if (finalNumber === newBalance) Object.assign(transaction, {
            //             action: 'discount', discount: finalDiscount})
            //
            //     } else if (arg === 'sub') {
            //         setBalance(prevState => Math.round((prevState - finalNumber) * 100) / 100)
            //         newBalance = Math.round((balance - finalNumber) * 100) / 100
            //         // setPoints(finalNumber)
            //         Object.assign(transaction, {action: 'redemption', points: finalNumber, balance: newBalance})
            //     } else return
        // }

        else {
            if (arg === 'add') {
                setAction('add')
                setBalance(prevState => Math.round((prevState + points) * 100) / 100)
                newBalance = Math.round((balance + points) * 100) / 100
                Object.assign(transaction, {action: 'accumulation', points, balance: newBalance})
                if (input > points) Object.assign(transaction, {sum: input})
            } else if (arg === 'sub') {
                setBalance(prevState => Math.round((prevState - input) * 100) / 100)
                newBalance = Math.round((balance - input) * 100) / 100
                setPoints(input)
                Object.assign(transaction, {action: 'redemption', points: input, balance: newBalance})
            }
        }

        Object.assign(transaction, {
            passTypeIdentifier: card.passTypeIdentifier,
            serialNumber: card.serialNumber,
            firstName: card.firstName,
            lastName: card.lastName,
            memberId: card.memberId
        })

        //update balance
        axios.put(
            `/api/v1/passes/${passTypeIdentifier}/${serialNumber}/values/balance`,
            {value: newBalance}
        )
            .then(() => {
                setCompleted(true)
                axios.post('/api/loyalty/transaction', transaction)
                    .then(() => {
                        if (card.referrer){
                            const refData = {
                                passTypeIdentifier: card.passTypeIdentifier,
                                templateId: card.templateId,
                                referrer: card.referrer,
                                referral: card.serialNumber,
                                points: transaction?.points
                            }
                            console.log("referrer", refData);
                            axios.post('/api/loyalty/ref', refData)
                                .catch(e => console.log('LOYALTY card.referrer post ERROR\n',e))
                        }
                    })
                    .catch(e => {
                        console.log('transaction wasn\`t saved\n', e)
                        e.response.status === 401 && navigate({pathname: '/user/login', search: 'scan'})
                    })
            })
            .catch(e => {
                console.log(e)
                e.response.status === 401 && navigate({pathname: '/user/login', search: 'scan'})
            })


    }

    const goBackHandler = () => {
        setCompleted(false)
        setAction('')
    }

    const showTransactions = (passTypeIdentifier, serialNumber) => {
        if (passTypeIdentifier && serialNumber){
            navigate(`/transaction/${passTypeIdentifier}/${serialNumber}`)
        }
    }



    const showQr = memberId => {
        setIsOpen(true)
        setModalStyle({
            overlay: {
                zIndex: 2,
                backgroundColor: "rgba(0, 0, 0, .8)",
            },
            content: {
                height: '296px',
                width: '296px',
                margin: 'auto',
                padding: '20px'
            }
        })
        setModalData(<QRCode value={memberId}/>)
    }

    return (
        <>
            <div className="row justify-content-center mt-5">

                <div className={"mt-5 mb-3"} style={{textAlign: "center"}}>
                    <h4 className={"text-primary mb-1"}>{card.firstName} {card.lastName}</h4>
                    <p className={"text-secondary mb-0"}>TEL: {card.tel}</p>
                    <p className={"text-secondary mb-0"}>ID: {card.memberId}</p>
                    {card.birthday && <p className={"text-secondary mb-1"}>BD: {new Date(card.birthday).toLocaleDateString()}</p>}
                    <button className={'btn btn-outline-secondary btn-sm mb-5'} onClick={() => showQr(card.memberId)}>QR</button>
                    <h5>{!completed
                        ? scan[language].balance + ': ' + balance
                        : scan[language].newBalance + ': ' + balance }
                    </h5>
                    <h6>
                        {!completed && discounts?.length > 0 &&
                        transactions[language].discount + ': ' + discounts[balance] + '%' }
                    </h6>
                    {!freeNumber && !discounts?.length > 0 &&
                    <h5 className={action === 'add' ? "text-success" : "text-danger"}> {
                        completed && (action === 'add'
                            ? scan[language].add
                            : scan[language].sub) + ': ' + points}
                    </h5>}
                </div>
                {!completed && (
                    <form className={"col-6 col-md-4"}>
                        {!freeNumber && !discounts?.length > 0 && <div className="mb-3">
                            <input
                                type="number"
                                className="form-control"
                                id="points"
                                required
                                placeholder={placeholder}
                                name={"points"}
                                onChange={handleChange}
                            />
                        </div>}
                        <div className="d-grid">
                            <button
                                type="submit"
                                className="btn btn-primary mt-2"
                                onClick={e => handleClick(e, 'add')}
                                disabled={disabled || discounts?.length -1 === balance}
                            >{scan[language].accumulate} {points}</button>
                            <button
                                type="submit"
                                className="btn btn-danger mt-2"
                                disabled={disabled || card.balance < input || card.balance < freeNumber || card.balance < discounts?.length -1}
                                onClick={e => handleClick(e, 'sub')}
                            >{scan[language].redeem} {input}</button>

                        </div>
                    </form>
                )}

                {completed &&
                <div className={'text-center'}>
                    <button
                        className="btn btn-secondary mt-5 mb-5"
                        disabled={false}
                        onClick={goBackHandler}
                    >
                        {scan[language].goBack}
                    </button>
                </div>
                }
            </div>



            {
                (localStorage.role === 'admin' || localStorage.role === 'user' || localStorage.role === 'test') && !completed &&
                <div className={'text-center mt-5'}>
                    <button
                        className="btn btn-outline-primary m-2"
                        disabled={false}
                        onClick={() => navigate('/cards')}
                    >
                        {scan[language].toMain}
                    </button>
                    <button
                        className="btn btn-outline-primary m-2"
                        disabled={false}
                        onClick={() => showTransactions(card.passTypeIdentifier, card.serialNumber)}
                    >
                        {scan[language].transactions}
                    </button>
                </div>
            }

            <Modal
                style={modalStyle}
                ariaHideApp={false}
                isOpen={isOpen}
                onRequestClose={() => setIsOpen(false)}>
                {modalData}
            </Modal>
        </>
    )
}