import React, {useEffect, useState} from "react";

import getBrowserLocales from "../utils/getBrowserLocales";
import {useNavigate, useLocation} from "react-router-dom";
import {alerts, posts, editPass, header, multiselect} from "../constants/translations";
import axios from "../utils/axiosConfig";

export default () => {

    if (!localStorage.language) {
        getBrowserLocales()
    }

    const language = localStorage.language

    const navigate = useNavigate()

    const location = useLocation()

    const [post, setPost] = useState(location.state)
    const [isError, setIsError] = useState(false)
    const [isInfo, setIsInfo] = useState(false)
    const [alert, setAlert] = useState('Error')
    const [disabled, setDisabled] = useState(false)

    const handleChange = (event) => {
        const {name, value} = event.target
        setPost({...post, [name]: value})
    }


    const handlerSubmit = (event) => {
        event.preventDefault()
        setDisabled(true)
        setIsInfo(true)
        setAlert(alerts[language].successChanged)

        console.log(event);
        console.log(post);

        axios
            .put(`/api/posts`, post)
            .then(response => {
                if (response.status !== 200) return
                setTimeout(() => {
                    navigate('/posts')
                }, 3000)
            })
            .catch(e => {
                console.log(e.response.status)
                e.response.status === 401 && navigate('/user/login')
                //todo to error page
                e.response.status === 402 && navigate('/402')
                e.response.status === 403 && navigate('/403')
            })


    }

    return (
        <div>
            {/*<Header/>*/}
            <h4 className={"text-primary"}>{header[language].posts}</h4>
            <hr className={"mt-0 mb-5"} />

            <div className="row justify-content-center mt-5">
                {isInfo && (
                    <div className="alert alert-info" role="alert">
                        {alert}
                    </div>
                )}
                {isError && (
                    <div className="alert alert-danger" role="alert">
                        {alert}
                    </div>
                )}
                <h4 className={"mt-5 mb-5"} style={{textAlign: "center"}}>{editPass[language].title}</h4>

                <form className={"col-md-10"}>

                    <div className="mb-3">
                        <p>{posts[language].language} "{post.language}"</p>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="date">{posts[language].date}</label>
                        <input
                            type="date"
                            id="date"
                            required
                            name={"date"}
                            value={post.date}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="mb-2">
                        <label htmlFor="title">{posts[language].title}</label>
                        <textarea
                            id="title"
                            required
                            name={"title"}
                            value={post.title}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="description">{posts[language].description}</label>
                        <textarea
                            rows='3'
                            id="description"
                            required
                            name={"description"}
                            value={post.description}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="text">{posts[language].text}</label>
                        <textarea
                            rows='15'
                            id="text"
                            required
                            name={"text"}
                            value={post.text}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="keywords">{posts[language].keywords}</label>
                        <textarea
                            rows='3'
                            id="keywords"
                            required
                            name={"keywords"}
                            value={post.keywords}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="articleUrl">{posts[language].articleUrl}</label>
                        <input
                            type="text"
                            id="articleUrl"
                            required
                            name={"articleUrl"}
                            value={post.articleUrl}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="fbImgUrl">{posts[language].fbImgUrl}</label>
                        <input
                            type="text"
                            id="fbImgUrl"
                            required
                            name={"fbImgUrl"}
                            value={post.fbImgUrl}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="twImgUrl">{posts[language].twImgUrl}</label>
                        <input
                            type="text"
                            id="twImgUrl"
                            required
                            name={"twImgUrl"}
                            value={post.twImgUrl}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="fileName">{posts[language].fileName}</label>
                        <input
                            type="text"
                            id="fileName"
                            required
                            name={"fileName"}
                            value={post.fileName}
                            onChange={handleChange}
                        />
                    </div>


                    <div className="d-grid mb-5">
                        <button
                            type="submit"
                            className="btn btn-primary mt-3"
                            disabled={disabled}
                            onClick={handlerSubmit}
                        >{editPass[language].submitButton}
                        </button>
                        <button
                            className="btn btn-secondary mt-3"
                            disabled={disabled}
                            onClick={() => navigate('/posts')}
                        >{editPass[language].goBack}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}