import React, {useState, useEffect} from "react"
import axios from "../utils/axiosConfig"
import base64url from "base64url"
import {useNavigate, Link} from "react-router-dom";
import Header from "../layout/Header";
import {header, distribution} from "../constants/translations"
import CopyToClipboard from "react-copy-to-clipboard"
import QRCode from "react-qr-code"

export default () => {
    const language = localStorage.language

    const [copied, setCopied] = useState('')
    const navigate = useNavigate()
    const [passSettings, setPassSettings] = useState()
    const [host, setHost] = useState('loyal.ws')
    const [registrationPages, setRegistrationPages] = useState([])

    useEffect(() => {
        axios.get('/api/passes/settings')
            .then(response => {
                if (response?.data?.test && response?.data?.filial){
                    response.data.filial = response.data.filial.filter(f => f.email === localStorage.email)
                }
                setPassSettings(response.data)
                if (response.data?.whiteLabel?.domen){
                    setHost(response.data.whiteLabel.domen)
                }
                // setPassTypeId(response.data.passTypeIdentifier[0])
            })
            .catch(err => {
                console.error(err)
                err.response.status === 401 && navigate('/user/login')
                err.response.status === 403 && navigate('/user/login')
                err.response.status === 404 && navigate('/')
            })
    }, [])

    // console.log(passSettings);
    // passSettings && passSettings.templateId.forEach(e => console.log(e));

    // passSettings && passSettings.filial && passSettings.filial.forEach(e => console.log(e));

    const getUrl = () => {
        const urls = []
        passSettings?.templateId?.map(template => {
            if (passSettings?.filial?.length > 0) {
                passSettings?.filial?.map(f => {
                    let obj = {
                        templateId: template,
                        filialName: f.title,
                        walletUrl: passSettings.test ?
                            `https://${host}/d/${passSettings?.organisationId}/${template}?user=${localStorage.email}&acc=${f.id}` :
                            `https://${host}/d/${passSettings?.organisationId}/${template}?acc=${f.id}`,
                        telegramUrl: passSettings.telegram && (passSettings.test ?
                            `https://t.me/${passSettings?.telegram}?start=${base64url(localStorage.email)}` :
                            `https://t.me/${passSettings?.telegram}`)
                    }
                    urls.push(obj)
                })
            } else {
                let obj = {
                    templateId: template,
                    walletUrl: passSettings.test ?
                        `https://${host}/d/${passSettings?.organisationId}/${template}?user=${localStorage.email}` :
                        `https://${host}/d/${passSettings?.organisationId}/${template}`,
                    telegramUrl: passSettings.telegram && (passSettings.test ?
                        `https://t.me/${passSettings?.telegram}?start=${base64url(localStorage.email)}` :
                        `https://t.me/${passSettings?.telegram}`)
                }
                urls.push(obj)
            }
        })

        return (
            urls && <div>
                <h4 className={"text-primary"}>{header[language].distribution}</h4>
                <hr className={"mt-0 mb-5"} />
                <div className={'container mx-auto'}>
                    <h5 style={{maxWidth: '500px', textAlign: 'center', margin: 'auto'}}>{distribution[language].description}</h5>
                    {urls.map((item, index) => {
                            return <div key={index} className={"mx-auto card mt-5 text-center"}>
                                <h4 className={"mt-3"}>Template: "{item.templateId}" {item.filialName &&  "  (" + item.filialName+')'}</h4>

                                {passSettings.wallets && <div>
                                    <h5>Wallets</h5>
                                    <div id={"qr-gen"} className={"m-3"}><QRCode
                                        value={item.walletUrl}/></div>

                                    <CopyToClipboard text={item.walletUrl}
                                                     onCopy={() => setCopied(index)}>
                                        <button role={"button"}
                                                className={"btn btn-outline-secondary m-3" + (index === copied ? ' active' : '')}>{distribution[language].copyUrl}</button>
                                    </CopyToClipboard>
                                    <a role="button" className="btn btn-outline-secondary m-3"
                                       href={item.walletUrl}>{distribution[language].followUrl}</a>
                                    {/*<button role={"button"} className="btn btn-outline-secondary m-3">Сохранить QR</button>*/}
                                </div>}
                                {item.telegramUrl && <div>
                                    <h5>Telegram</h5>
                                    <div id={"qr-gen"} className={"m-3"}><QRCode
                                        value={item.telegramUrl}/></div>

                                    <CopyToClipboard text={item.telegramUrl}
                                                     onCopy={() => setCopied(index+1)}>
                                        <button role={"button"}
                                                className={"btn btn-outline-secondary m-3" + (index+1 === copied ? ' active' : '')}>{distribution[language].copyUrl}</button>
                                    </CopyToClipboard>
                                    <a role="button" className="btn btn-outline-secondary m-3"
                                       href={item.telegramUrl}>{distribution[language].followUrl}</a>
                                    {/*<button role={"button"} className="btn btn-outline-secondary m-3">Сохранить QR</button>*/}
                                </div>}
                            </div>
                    })}
                </div>
            </div>
        )
    }

    return getUrl()

}
