import React, {useState, useEffect} from "react"
import axios from "../utils/axiosConfig"
import Resizer from "react-image-file-resizer"
import jwtDecode from "jwt-decode";
import {useNavigate} from "react-router-dom"

import { MultiSelect } from "react-multi-select-component"
import getBrowserLocales from "../utils/getBrowserLocales"
import {alerts, editPass, header, multiselect, sendMessages} from '../constants/translations'
import Header from "../layout/Header";
import MessageHeader from "../layout/MessageHeader";

export default () => {

    if (!localStorage.language) {
        getBrowserLocales()
    }

    const language = localStorage.language
    const role = localStorage.role

    const [passSettings, setPassSettings] = useState({})
    const [message, setMessage] = useState({})
    const [messageType, setMessageType] = useState('text')
    const [isError, setIsError] = useState(false)
    const [isInfo, setIsInfo] = useState(false)
    const [alert, setAlert] = useState('Error')
    const [disabled, setDisabled] = useState(false)

    const navigate = useNavigate()

    const [options, setOptions] = useState([])
    const [selected, setSelected] = useState([])
    const [filter, setFilter] = useState('')
    const [filters, setFilters] = useState([{label: sendMessages[language].pushAll, value: 'All'}])
    const [selectedFile, setSelectedFile] =useState(null)

    useEffect( () => {
        axios
            .get('/api/passes/settings')
            .then(response => {
                if (response.status !== 200) return
                setPassSettings(response.data)

                if (response.data?.formData?.city?.length > 1) {
                    setFilters(prevState => [...prevState, {
                        label: sendMessages[language].city,
                        value: 'city'
                    }])
                }

                if (response.data?.category?.length >= 1) {
                    // setCategories(response.data.category)
                    setFilters(prevState => [...prevState, {
                        label: sendMessages[language].category,
                        value: 'category'
                    }])
                }

                if (response.data?.segment?.length >= 1) {
                    // setSegments(prevState => [...prevState, segment.name])
                    setFilters(prevState => [...prevState, {
                        label: sendMessages[language].segment,
                        value: 'segment'
                    }])
                }
            })
            .catch(e => {
                console.log(e.response.status)
                e.response.status === 401 && navigate('/user/login')
                //todo to error page
                e.response.status === 402 && navigate('/402')
                e.response.status === 403 && navigate('/403')
                // e.response.status === 404 && setPasses([])
            })
    }, [navigate])

    const handleChange = (event) => {
        const {name, value} = event.target

        if (messageType === 'text' && name === 'text'){
            setMessage({text: value, url: '', image: false})
        } else if (messageType === 'url' && (name === 'text' || name === 'url')){
            setMessage({...message, [name]: value, image: false})
        }

        // messageType
        if (name === 'messageType') {
            setMessageType(value)
            value === 'image' && setMessage({text: '', image: true})
        }

        if (name === 'filter') {
            setFilter(value)
            if (value === 'All'){
                setFilter('')
                setOptions([])
                setSelected([])

            }
            if (value === 'city'){
                setOptions([])
                setSelected([])
                passSettings.formData.city.map(item => {
                    setOptions(prevState => [...prevState, {label: item, value: item}])
                })
            }
            if (value === 'category'){
                setOptions([])
                setSelected([])
                passSettings.category.map(item => {
                    setOptions(prevState => [...prevState, {label: item, value: item}])
                })
            }
            // segment
            if (value === 'segment'){
                setOptions([])
                setSelected([])
                passSettings.segment.map(item => {
                    setOptions(prevState => [...prevState, {label: item.name, value: item.name}])
                })
            }
        }
    }

    const handleImg = (event) => {
        let fileInput = false;
        if (event.target.files[0]) {
            fileInput = true;
        }
        if (fileInput) {
            try {
                Resizer.imageFileResizer(
                    event.target.files[0],
                    1080,
                    1080,
                    "JPEG",
                    80,
                    0,
                    (uri) => {
                        // console.log(uri);
                        setSelectedFile(uri);
                    },
                    "file"
                );
            } catch (err) {
                console.log(err);
            }
        }
    }

    const handlerSubmit = async (event) => {
        event.preventDefault()

        const data = {message}
        filter && (data.filter = {[filter]: selected.map(i => i.value)})

        if (data.message.image) {
            const fd = new FormData()
            if (selectedFile) {
                fd.append('file', selectedFile, 'promo.jpeg')
            }
            axios
                .post("/api/bots/telegram/message", fd, {
                    onUploadProgress: progressEvent => {
                        console.log(`Uploaded progress: ${progressEvent.loaded / progressEvent.total}`)
                    }
                })
                .then(r => {
                    if (r.status === 200) {
                        setDisabled(true)
                        setIsInfo(true)
                        setAlert(alerts[language].successSend)
                        setTimeout(() => {
                            setIsInfo(false)
                            setDisabled(false)
                            navigate('/cards')
                        }, 3000)

                    }
                })
                .catch(e => {
                    if (e.response.status === 429){
                        setIsError(true)
                        setDisabled(true)
                        setAlert(`${alerts[language].tooManyReq} ${e.response.headers['retry-after']} sec`)
                        setTimeout(() => {
                            setIsError(false)
                            setDisabled(false)
                        }, (e.response.headers['retry-after'] * 1000))
                    }
                    if (e.response.status === 400){
                        setIsError(true)
                        setAlert(alerts[language].badReq)
                        setTimeout(() => {
                            setIsError(false)
                        }, 3000)
                    }
                    if (e.response.status === 401){
                        setIsError(true)
                        setAlert(alerts[language].badAuth)
                        setTimeout(() => {
                            setIsError(false)
                        }, 3000)
                    }
                    if (e.response.status === 404){
                        setIsError(true)
                        setAlert(alerts[language].userNotFound)
                        setTimeout(() => {
                            setIsError(false)
                        }, 3000)
                    }
                    if (e.response.status === 500){
                        setIsError(true)
                        setAlert(alerts[language].serverError)
                        setTimeout(() => {
                            setIsError(false)
                        }, 3000)
                    }
                })
        } else {
            await axios
                .post("/api/bots/telegram/message", data)
                .then(r => {
                    if (r.status === 200) {
                        setDisabled(true)
                        setIsInfo(true)
                        setAlert(alerts[language].successSend)
                        setTimeout(() => {
                            setIsInfo(false)
                            setDisabled(false)
                            navigate('/cards')
                        }, 3000)

                    }
                })
                .catch(e => {
                    if (e.response.status === 429){
                        setIsError(true)
                        setDisabled(true)
                        setAlert(`${alerts[language].tooManyReq} ${e.response.headers['retry-after']} sec`)
                        setTimeout(() => {
                            setIsError(false)
                            setDisabled(false)
                        }, (e.response.headers['retry-after'] * 1000))
                    }
                    if (e.response.status === 400){
                        setIsError(true)
                        setAlert(alerts[language].badReq)
                        setTimeout(() => {
                            setIsError(false)
                        }, 3000)
                    }
                    if (e.response.status === 401){
                        setIsError(true)
                        setAlert(alerts[language].badAuth)
                        setTimeout(() => {
                            setIsError(false)
                        }, 3000)
                    }
                    if (e.response.status === 404){
                        setIsError(true)
                        setAlert(alerts[language].userNotFound)
                        setTimeout(() => {
                            setIsError(false)
                        }, 3000)
                    }
                    if (e.response.status === 500){
                        setIsError(true)
                        setAlert(alerts[language].serverError)
                        setTimeout(() => {
                            setIsError(false)
                        }, 3000)
                    }
                })
        }



    }

    const messageTypes = [{label: 'Text message', value: 'text'}, {label: 'Image message', value: 'image'}, {label: 'Link message', value: 'url'}]
    const messageOptions = messageTypes.map((item, index) => {
        return (
            <option key={index} value={item.value}>{item.label}</option>
        )
    })
    const filterOptions = filters.map((item, index) => {
        return (
            <option key={index} value={item.value}>{item.label}</option>
        )
    })

    // console.log(filter, selected);

    return (
        <div>
            {/*<Header />*/}
            <h4 className={"text-primary"}>{header[language].push_message}</h4>
            <hr className={"mt-0 mb-0"} />
            <MessageHeader/>

            <div>
                {isInfo && (
                    <div className="alert alert-info" role="alert">
                        {alert}
                    </div>
                )}
                {isError && (
                    <div className="alert alert-danger" role="alert">
                        {alert}
                    </div>
                )}
                <div className='card p-3 col-md-6 col-xl-4 m-auto mb-5'>
                    <h4 style={{textAlign: "center"}}>Telegram</h4>
                    <form onSubmit={handlerSubmit}>
                        <div className="mb-3">
                            {(messageTypes.length > 1) && <>
                                <label htmlFor="passTmessageTypeypeId" className="form-label">Message Type</label>
                                <select className="form-select mb-5" id={"messageType"} name={"messageType"} onChange={handleChange}>
                                    {messageOptions}
                                </select>
                            </>}
                            {(filters.length > 1) && <>
                                <label htmlFor="filter" className="form-label">{sendMessages[language].filter}</label>
                                <select className="form-select mb-3" id={"filter"} name={"filter"} onChange={handleChange}>
                                    {filterOptions}
                                </select>
                            </>}
                            {filter && <div className="mb-3">
                                {/*<pre>{JSON.stringify(selectedCategory)}</pre>*/}
                                <MultiSelect
                                    id={filter}
                                    options={options}
                                    overrideStrings={multiselect[language]}
                                    hasSelectAll={false}
                                    disableSearch={true}
                                    closeOnChangedValue={true}
                                    value={selected}
                                    onChange={setSelected}
                                />
                            </div>}
                            {(messageType === 'text' || messageType === 'url') &&
                                <>
                                    <label htmlFor="text" className="form-label">{sendMessages[language].message}</label>
                                    <textarea
                                        className="form-control"
                                        id="text"
                                        required
                                        name={"text"}
                                        onChange={handleChange}
                                    />
                                </>
                            }
                            {messageType === 'url' && <>
                                <label htmlFor="url" className="form-label">URL (https://example.com)</label>
                                <input
                                    type="url"
                                    className="form-control"
                                    id="url"
                                    required
                                    name={"url"}
                                    onChange={handleChange}
                                />
                            </>
                            }
                            {messageType === 'image' &&
                                <div className="mb-3">
                                    <label htmlFor="relevantDate">{editPass[language].uploadImg}</label>
                                    <input
                                        className={"btn btn-light btn-sm"}
                                        type={'file'}
                                        onChange={handleImg}
                                    />
                                </div>}

                            <div className="form-text">
                                {sendMessages[language].subTitle}
                            </div>

                        </div>
                        <div className="d-grid">
                            <button
                                type="submit"
                                className="btn btn-primary mt-3"
                                disabled={disabled || (role !== 'admin' && role !== 'test') || (!message.text && !message.url && !selectedFile) || !passSettings.telegram}
                            >{sendMessages[language].submitButton}</button>
                        </div>
                    </form>
                </div>

            </div>
        </div>
    )
}
