import React, {useEffect, useState} from "react"
import axios from "../utils/axiosConfig"
import {useNavigate, useParams} from "react-router-dom";
import jwtDecode from "jwt-decode";
import Header from "../layout/Header";
import {alerts, createPass, header, userCreate, userEdit} from "../constants/translations"

export default () => {

    const language = localStorage.language

    const navigate = useNavigate()

    const {email} = useParams()

    const [user, setUser] = useState({email: email})
    const [isValid, setIsValid] = useState(true)
    const [isError, setIsError] = useState(false)
    const [alert, setAlert] = useState(alerts[language].processing)
    const [departments, setDepartments] = useState([])
    const [roles] = useState(['admin', 'user', 'staff'])
    const [status] = useState(['pending', 'active', 'blocked'])
    const [disabled, setDisabled] = useState(false)

    useEffect(() => {
        axios.get(`/api/organisations/${localStorage.organisationId}`)
            .then(res => {
                setDepartments(res.data.departments)
            })
            .catch(e => {
                if (e.response.status === 401) navigate('/user/login')
                if (e.response.status === 409){
                    setIsError(true)
                    setAlert(alerts[language].userExist)
                    setTimeout(() => {
                        navigate('/user')
                    }, 3000)
                }
                console.log(e)
            })
    }, [navigate])

    const handleChange = (event) => {
        const {name, value, type, checked} = event.target
        type === 'checkbox' ? setUser({...user, [name]: checked}) : setUser({...user, [name]: value})
    }

    const handlerSubmit = (event) => {
        event.preventDefault()
        axios
            .put('/api/users', user)
            .then(r => {
                if (r.status === 200) navigate('/user')
            })
            .catch(e => {
                if (e.response.status === 409){
                    setIsError(true)
                    setAlert(alerts[language].userExist)
                    setTimeout(() => {
                        navigate('/user')
                    }, 3000)
                }
                if (e.response.status === 401){
                    setIsError(true)
                    setAlert(alerts[language].notAuthorized)
                    setTimeout(() => {
                        navigate('/user/login')
                    }, 3000)
                }
                console.log(e)
                // navigate('/user/login')
            })
    }

    return (
        <div>
            {/*<Header />*/}
            <h4 className={"text-primary"}>{header[language].users}</h4>
            <hr className={"mt-0 mb-5"} />
            {isError && (
                <div className="alert alert-danger" role="alert">
                    {alert}
                </div>
            )}
            <div className="d-grid mt-5">
                <div className="form-card">
                    <h4>{userEdit[language].header}</h4>
                    <form onSubmit={handlerSubmit}>
                        <input
                            placeholder= {email}
                            disabled
                        />
                        <div>
                            <label htmlFor="department">{userEdit[language].department}</label>
                            <select
                                className="form-select mb-3"
                                id="department"
                                required
                                name={"department"}
                                onChange={handleChange}
                            >
                                <option value={0}>...</option>
                                {departments.map(value => (
                                    <option key={value} value={value}>{value}</option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label htmlFor="role">{userEdit[language].role}</label>
                            <select
                                className="form-select mb-3"
                                id="role"
                                required
                                name={"role"}
                                onChange={handleChange}
                            >
                                <option value={0}>...</option>
                                {roles.map(value => (
                                    <option key={value} value={value}>{value}</option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label htmlFor="status">{userEdit[language].status}</label>
                            <select
                                className="form-select mb-3"
                                id="status"
                                required
                                name={"status"}
                                onChange={handleChange}
                            >
                                <option value={0}>...</option>
                                {status.map(value => (
                                    <option key={value} value={value}>{value}</option>
                                ))}
                            </select>
                        </div>

                        <div className="d-grid">
                            <button
                                type="submit"
                                className="btn btn-primary mt-3"
                                disabled={!isValid}
                            >{userEdit[language].save}</button>
                            <button
                                className="btn btn-secondary mt-3"
                                disabled={disabled}
                                // onClick={() => navigate(-1)}
                                onClick={() => navigate('/user')}
                            >{userEdit[language].exit}
                            </button>
                        </div>
                    </form>
                </div>
            </div>




        </div>
    )
}
