import React from "react"
import {Routes, Route} from "react-router-dom";

import UserCreate from './user/UserCreate'
import UserLogin from './user/UserLogin'
import UserDisplay from './user/UserDisplay'
import RegisterDisplay from './pass/RegisterDisplay'
import CardsDisplay from './pass/CardsDisplay'

import UserChangePassword from "./user/UserChangePassword";
import SendPass from './pass/SendPass'
import Scan from './loyalty/ScanPass';
import Error from "./layout/ErrorPage";
import Err402 from "./layout/Err402";
import Err403 from "./layout/Err403";
import PushMessage from "./pass/PushMessage";
import CreatePass from "./pass/CreatePass";
import CreatePassAdmin from "./pass/CreatePassAdmin";
import EditPassAdmin from "./pass/EditPassAdmin";
import Statistic from "./statistic/Statistic";
import Settings from "./settings/Settings";
import Sidebar from "./layout/Sidebar";
import Transaction from "./loyalty/Transaction";
import Quiz from "./quiz/Quiz";

export default () => {
    return (
        // <div className={"container-fluid"}>
        <div>
            {/*<Sidebar/>*/}
            <Routes>
                <Route path={'/user/login'} element={<UserLogin/>} />
                {/*<Route path={'/user/create'} element={<UserCreate/>} />*/}
                {/*<Route path={'/user'} element={<UserDisplay/>} />*/}
                {/*<Route path={'/cards/create'} element={<CreatePassAdmin/>} />*/}
                {/*<Route path={'/cards/edit'} element={<EditPassAdmin/>} />*/}
                {/*<Route path={'/cards'} element={<CardsDisplay/>} />*/}
                {/*<Route path={'/push_message'} element={<PushMessage/>} />*/}
                {/*<Route path={'/statistic'} element={<Statistic/>} />*/}
                {/*<Route path={'/register'} element={<RegisterDisplay/>} />*/}
                {/*<Route path={'/settings'} element={<Settings/>} />*/}
                <Route path={'/change_password/:email/:hash'} element={<UserChangePassword/>} />
                {/*<Route path={'/pass/d/:hash/:passTypeIdentifier/:serialNumber'} element={<SendPass/>} />*/}
                {/*<Route path={'/pass/c/:organisationId/:templateId'} element={<CreatePass/>} />*/}
                <Route path={'/scan/:passTypeIdentifier/:serialNumber'} element={<Scan/>} />
                {/*<Route path={'/transaction/:passTypeIdentifier/:serialNumber'} element={<Transaction/>} />*/}
                {/*<Route path={'/transaction'} element={<Transaction/>} />*/}
                <Route path={'/error'} element={<Error/>} />
                <Route path={'/402'} element={<Err402/>} />
                <Route path={'/403'} element={<Err403/>} />
                <Route path={'*'} element={<Sidebar/>} />
                {/*<Route path={'*'} element={<Error/>} />*/}
                <Route path={'/quiz'} element={<Quiz />} />
                <Route path={'/'} element={<UserLogin/>} />
            </Routes>

        </div>
    )
}
