import React from "react"
import {settingsHeader} from "../../constants/translations";

export default props => {
    // console.log(props);
    return (
        <>
            <label htmlFor={props?.name}>{props.label}</label>
            <input
                type="text"
                className="form-control"
                name={props?.name}
                id={props?.name}
                defaultValue={props?.value}
                onChange={props.handleChange}
                />
        </>
    )
}