import React, {useState, useEffect} from "react"
import axios from "../../utils/axiosConfig"
import {useNavigate, useLocation} from "react-router-dom";
import Header from "../../layout/Header";
import {settingsHeader, editPass, cardsDisplay, alerts} from "../../constants/translations"
import SettingsHeader from "../../layout/SettingsHeader";
import Birthday from "./Birthday";

export default () => {
    const location = useLocation()
    const settings = location.state

    const language = localStorage.language
    const role = localStorage.role
    const [isError, setIsError] = useState(false)
    const [isInfo, setIsInfo] = useState(false)
    const [alert, setAlert] = useState('Error')
    const [disabled, setDisabled] = useState(!settings?.marketing)
    const [isLoading, setIsLoading] = useState(true)
    const navigate = useNavigate()
    const [passSettings, setPassSettings] = useState(settings)


    const [birthday, setBirthday] = useState(settings?.birthday)

    const handleChangeBirthday = (event) => {
        let {name, value} = event.target
        setBirthday({...birthday, [name]: value})
        setPassSettings({...passSettings, birthday: {...birthday, [name]: value}})
    }

    // const handleDelete = () => {
    //     passSettings.birthday = {}
    //     console.log(passSettings);
    // }

    const handleSubmit = async () => {
        setIsLoading(true)
        setDisabled(true)
        // console.log(passSettings);
        try {
            await axios.put('/api/passes/settings', passSettings)
                .then(response => {

                    if (response.status !== 200) return setIsLoading(false)
                    setIsLoading(false)
                    setIsInfo(true)
                    setAlert(alerts[language].success)
                    setTimeout(() => {
                        setIsInfo(false)
                        setDisabled(false)
                        navigate('/settings/marketing')
                    }, 2000)

                })
                .catch(e => {
                    console.log('ERRORR', e.response.status)
                    setIsLoading(false)

                    e.response.status === 401 && navigate('/user/login')
                    e.response.status === 402 && navigate('/402')
                    e.response.status === 403 && navigate('/403')
                })
        } catch (e) {
            console.error(e);
        }
    }

    return (<>
        {isInfo && (
            <div className="alert alert-info" role="alert">
                {alert}
            </div>
        )}
        {isError && (
            <div className="alert alert-danger" role="alert">
                {alert}
            </div>
        )}
        {passSettings &&
        <div className='card p-3 col-md-12 col-xl-9 m-auto mb-5 mt-3'>
            <div className="input-group-sm">
                <h4 className='mt-3'>{settingsHeader[language].birthday}</h4>

                {<div className="input-group-sm">
                    <label htmlFor="bDayBeforeDays">{settingsHeader[language].bDayBeforeDays}</label>
                    <input
                        type="text"
                        className="form-control"
                        name='bDayBeforeDays'
                        id='bDayBeforeDays'
                        defaultValue={birthday?.bDayBeforeDays}
                        onChange={handleChangeBirthday}/>
                    <label htmlFor="bDayBeforeText">{settingsHeader[language].bDayBeforeText}</label>
                    <textarea
                        name="bDayBeforeText"
                        className="form-control"
                        id="bDayBeforeText"
                        rows="3"
                        defaultValue={birthday?.bDayBeforeText}
                        onChange={handleChangeBirthday}/>

                    <label htmlFor="birthDayText">{settingsHeader[language].birthDayText}</label>
                    <textarea
                        name="birthDayText"
                        className="form-control"
                        id="birthDayText"
                        rows="3"
                        defaultValue={birthday?.birthDayText}
                        onChange={handleChangeBirthday}/>

                    <label htmlFor="bDayAfterDays">{settingsHeader[language].bDayAfterDays}</label>
                    <input
                        type="text"
                        name='bDayAfterDays'
                        className="form-control"
                        id='bDayAfterDays'
                        defaultValue={birthday?.bDayAfterDays}
                        onChange={handleChangeBirthday}/>
                    <label htmlFor="bDayAfterText">{settingsHeader[language].bDayAfterText}</label>
                    <textarea
                        name="bDayAfterText"
                        className="form-control"
                        id="bDayAfterText"
                        rows="3"
                        defaultValue={birthday?.bDayAfterText}
                        onChange={handleChangeBirthday}/>

                    <div className='float-end'>
                        {/*<button*/}
                        {/*    disabled={!settings?.marketing || disabled}*/}
                        {/*    className='btn btn-outline-danger me-3'*/}
                        {/*    onClick={handleDelete}>*/}
                        {/*    {cardsDisplay[language].delete}*/}
                        {/*</button>*/}
                        <button
                            className='btn btn-outline-danger me-3'
                            onClick={() => navigate('/settings/marketing')}>
                            {editPass[language].goBack}
                        </button>
                        <button
                            className='btn btn-outline-primary'
                            disabled={!settings?.marketing || disabled || role !== 'admin'}
                            onClick={handleSubmit}>
                            {editPass[language].submitButton}
                        </button>
                    </div>
                </div>}
            </div>
        </div>
        }
    </>)

}