import React, {useState, useEffect} from "react"
import axios from "../utils/axiosConfig"
import {useNavigate} from "react-router-dom";
import Header from "../layout/Header";
import {settingsHeader, editPass, alerts, header, loyalty, swal} from "../constants/translations"
import SettingsHeader from "../layout/SettingsHeader";
import Swal from 'sweetalert2'

export default () => {
    const language = localStorage.language
    const role = localStorage.role
    const [isError, setIsError] = useState(false)
    const [isInfo, setIsInfo] = useState(false)
    const [alert, setAlert] = useState('Error')
    const [disabled, setDisabled] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const navigate = useNavigate()
    const [passSettings, setPassSettings] = useState()
    const [templateId, setTemplateId] = useState()

    const [loyaltyProgram, setLoyaltyProgram] = useState('')
    const [condition, setCondition] = useState({minAmount: 0, percent: 0})
    const [conditions, setConditions] = useState([])
    const [active, setActive] = useState(true)
    const [bonusSystem, setBonusSystem] = useState({})
    const [discountSystem, setDiscountSystem] = useState({})
    const [stampSystem, setStampSystem] = useState()

    useEffect(() => {
        axios.get('/api/passes/settings')
            .then(response => {
                if (response.status !== 200) return setIsLoading(false)
                setPassSettings(response.data)
                setTemplateId(response.data?.templateId[0])
                setIsLoading(false)

                if(response.data?.test || !response.data?.loyaltyProgram?.active){
                    Swal.fire({
                        title: 'Loyalty',
                        text: swal[language].demoLoyaltySettingsText,
                        confirmButtonText: 'OK',
                        confirmButtonColor: "#0d6efd"
                    })
                }

            })
            .catch(e => {
                console.log('ERRORR', e.response.status)
                setIsLoading(false)
                e.response.status === 401 && navigate('/user/login')
                e.response.status === 402 && navigate('/402')
                e.response.status === 403 && navigate('/403')
                e.response.status === 404 && setPassSettings([])
            })
    }, [])

    useEffect(() => {
        if (templateId && passSettings?.loyaltyProgram) {
            for (const key in passSettings.loyaltyProgram) {
                if (key === "referralSystem") continue
                const item = passSettings.loyaltyProgram[key];
                if (item.templateId && item.templateId.trim() !== '' && item.templateId === templateId) {
                    // Виконуємо операції тільки для об'єктів з не пустим templateId
                    setLoyaltyProgram(key);

                    setConditions(passSettings.loyaltyProgram[key].condition)

                    break
                }
            }
        }

    }, [templateId])

    const handleTemplateId = (e) => {
        setTemplateId(e.target.value);
    };

    const templateIdOptions = passSettings?.templateId?.map((item, index) => <option key={index} value={item}>{item}</option>)

    const clearLoyaltyPrograms = () => {
        setPassSettings(passSettings => {
            const settings = {...passSettings}
            console.log(templateId);

            for (const key in settings.loyaltyProgram) {
                if (settings.loyaltyProgram[key].templateId === templateId){
                    settings.loyaltyProgram[key] = {}
                }
            }

            return settings
        })
    }

    const handleChange = async (event) => {
        const {name, value, type, checked} = event.target
        console.log(name, value, type, checked);
        if (name === 'loyaltyProgram') {
            console.log(value);
            await clearLoyaltyPrograms()
            // setActive(true)

            return setLoyaltyProgram(value)
        }

        else if (name === 'percent'){

            setPassSettings(passSettings => {
                const settings = {...passSettings}
                settings.loyaltyProgram[loyaltyProgram].templateId = templateId
                if (loyaltyProgram === 'stampStepBonusSystem' || loyaltyProgram === 'stampStepDiscountSystem'){
                    settings.loyaltyProgram[loyaltyProgram].percent = value.replace(' ', '').split(',')
                } else {
                    settings.loyaltyProgram[loyaltyProgram].percent = value
                }

                return settings
            })
        }

        else if (name === 'freeNumber'){

            // await clearLoyaltyPrograms()

            setPassSettings(passSettings => {
                const settings = {...passSettings}
                settings.loyaltyProgram.stampSystem.freeNumber = value
                settings.loyaltyProgram.stampSystem.templateId = templateId

                return settings
            })
        }

        else if (name === 'referrer'){

            // await clearLoyaltyPrograms()

            setPassSettings(passSettings => {
                const settings = {...passSettings}
                settings.loyaltyProgram.referralSystem.referrerPercent = value
                settings.loyaltyProgram.referralSystem.templateId = templateId

                return settings
            })
        }

        else if (name === 'referral'){

            // await clearLoyaltyPrograms()

            setPassSettings(passSettings => {
                const settings = {...passSettings}
                settings.loyaltyProgram.referralSystem.referralPercent = value
                settings.loyaltyProgram.referralSystem.templateId = templateId

                return settings
            })
        }

        else if (name === 'active'){
            setPassSettings(passSettings => {
                const settings = {...passSettings}
                settings.loyaltyProgram.active = !settings.loyaltyProgram.active
                !settings.loyaltyProgram.active && (settings.loyaltyProgram.scan = false)

                return settings
            })
        }

        else if (name === 'scan'){
            setPassSettings(passSettings => {
                const settings = {...passSettings}
                settings.loyaltyProgram.scan = !settings.loyaltyProgram.scan

                return settings
            })
        } else if (type === 'checkbox') {
            setPassSettings({...passSettings, [name]: checked})
        } else setPassSettings({...passSettings, [name]: value})
        // setPassSettings({...passSettings})
    }

    // console.log(passSettings);

    // const handleActive = (event) => {
    //     const {checked} = event.target
    //     setActive(prevState => !prevState)
    //     if (!checked) {
    //         setLoyaltyProgram('')
    //     }
    // }

    const handleSubmit = async () => {
        setIsLoading(true)
        setDisabled(true)
        try {
            await axios.put('/api/passes/settings', passSettings)
                .then(response => {

                    if (response.status !== 200) return setIsLoading(false)
                    setIsLoading(false)
                    setIsInfo(true)
                    setAlert(alerts[language].success)
                    setTimeout(() => {
                        setIsInfo(false)
                        setDisabled(false)
                    }, 2000)

                })
                .catch(e => {
                    console.log('ERRORR', e.response.status)
                    setIsLoading(false)

                    e.response.status === 401 && navigate('/user/login')
                    e.response.status === 402 && navigate('/402')
                    e.response.status === 403 && navigate('/403')
                })
        } catch (e) {
            console.error(e);
        }
    }

    // const conditions = [
    //     { minAmount: 500, percent: 10 },
    //     { minAmount: 1000, percent: 15 },
    //     { minAmount: Infinity, percent: 20 }
    // ]

    const handleDeleteCondition = id => {
        const newConditions = passSettings.loyaltyProgram[loyaltyProgram].condition.filter((item, index) => index !== id)
        setConditions(newConditions)

        setPassSettings(passSettings => {
            const settings = {...passSettings}
            settings.loyaltyProgram[loyaltyProgram].condition = newConditions

            return settings
        })
    }

    const showCondition = conditions?.map((item, index) => {
        return (
            <div key={index} className="input-group input-group-sm">
                <input type="text" className="form-control" placeholder={item.minAmount + ' +'} disabled={true}/>
                <input type="text" className="form-control" placeholder={item.percent +'%'} disabled={true}/>
                <button type="button" style={{"margin": "10px 0"}} className="btn btn-danger" onClick={() => handleDeleteCondition(index)}>-</button>
            </div>
        )
    })

    const handleChangeCondition = e => {
        const {name, value, type, checked} = e.target
        if (isNaN(value)) return
        console.log(name, value);
        setCondition({...condition, [name]: +value})
    }

    const handleAddCondition = () => {
        setPassSettings(passSettings => {
            const settings = {...passSettings}
            settings.loyaltyProgram[loyaltyProgram].templateId = templateId
            settings.loyaltyProgram[loyaltyProgram].condition.push(condition)
            settings.loyaltyProgram[loyaltyProgram].condition.sort((a, b) => a.minAmount - b.minAmount);

            setConditions(settings.loyaltyProgram[loyaltyProgram].condition)

            return settings
        })
    }

    // console.log(passSettings);
    // console.log(loyaltyProgram);

    return (
        <>
            {isInfo && (
                <div className="alert alert-info" role="alert">
                    {alert}
                </div>
            )}
            {isError && (
                <div className="alert alert-danger" role="alert">
                    {alert}
                </div>
            )}
            <h4 className={"text-primary"}>{header[language].settings}</h4>
            <hr className={"mt-0 mb-0"} />
            <SettingsHeader/>
            {passSettings && passSettings?.loyaltyProgram &&
            <div className='card p-3 col-md-6 col-xl-4 m-auto mb-5'>
                <h2>{settingsHeader[language].loyaltyProgram}</h2>

                {/*Activation toggle*/}
                <div className="form-check form-switch">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="active"
                        name='active'
                        checked={passSettings.loyaltyProgram.active}
                        onChange={handleChange}
                    />
                    <label className="form-check-label" htmlFor="active">{settingsHeader[language].active}</label>
                </div>

                {/*Template Identifier*/}
                {passSettings && templateIdOptions && <div>
                    <label htmlFor="floatingSelect">{editPass[language].templateId}</label>
                    <div className="input-group-sm">
                        <select
                            className="form-select"
                            id="floatingSelect"
                            aria-label="Floating label select example"
                            name='templateId'
                            onChange={handleTemplateId}
                            defaultValue={passSettings.language}
                        >
                            {templateIdOptions}
                        </select>
                    </div>
                </div>}

                <div className='raw'>

                    <h6 className={"mt-4"}>{settingsHeader[language].bonusSystem}</h6>
                    {/*// Bonus System ++++++++++++++++++++++++++++++*/}
                    <div className="form-check mt-2">
                        <input className="form-check-input" type="radio" name="loyaltyProgram" id="bonusSystem"
                               checked={loyaltyProgram === 'bonusSystem'}
                               onChange={handleChange}
                               value="bonusSystem"/>
                        <label className="form-check-label" htmlFor="bonusSystem">
                            {loyalty[language].bonusSystem}
                        </label>
                    </div>

                    {loyaltyProgram === 'bonusSystem' &&
                    <div className="input-group-sm">

                        <div className={'m-3'}><i>{loyalty[language].bonusSystemDescription}<br/></i></div>

                        <label htmlFor="bonusPercent">{loyalty[language].bonusSystemPlaceholder}</label>
                        <input className="form-control"
                               type="number"
                               id="bonusPercent"
                               name="percent"
                               defaultValue={passSettings?.loyaltyProgram?.bonusSystem?.percent}
                               onChange={handleChange}
                        />
                        <label htmlFor="balance">{settingsHeader[language].balance}</label>
                        <input className="form-control mb-5"
                               type="number"
                               id="balance"
                               name="balance"
                               defaultValue={passSettings?.balance}
                               onChange={handleChange}
                        />
                    </div>}

                    {/*/!* Bonus Bill System ++++++++++++++++++++++++++++++*!/*/}
                    {/*<div className="form-check mt-2">*/}
                    {/*    <input className="form-check-input" type="radio" name="loyaltyProgram" id="bonusBill"*/}
                    {/*           checked={loyaltyProgram === 'bonusBill'}*/}
                    {/*           onChange={handleChange}*/}
                    {/*           value="bonusBill"/>*/}
                    {/*    <label className="form-check-label" htmlFor="bonusBill">*/}
                    {/*        {loyalty[language].bonusBill}*/}
                    {/*    </label>*/}
                    {/*</div>*/}

                    {/*{loyaltyProgram === 'bonusBill' &&*/}
                    {/*<div className="input-group input-group-sm mb-3">*/}

                    {/*    <div className={'m-3'}><i>{loyalty[language].bonusBillDescription}<br/></i></div>*/}

                    {/*    {showCondition}*/}
                    {/*    <input type="number" name={'minAmount'} className="form-control" placeholder={"Min"} onChange={handleChangeCondition}/>*/}
                    {/*    <input type="number" name={'percent'} className="form-control" placeholder={"Percent"} onChange={handleChangeCondition}/>*/}
                    {/*    /!*<button type="button" style={{"margin": "10px 0"}} className="btn btn-danger ">-</button>*!/*/}
                    {/*    <button*/}
                    {/*        type="button"*/}
                    {/*        style={{"margin": "10px 0"}}*/}
                    {/*        className="btn btn-success "*/}
                    {/*        disabled={!condition.percent}*/}
                    {/*        onClick={handleAddCondition}*/}
                    {/*    >*/}
                    {/*        +*/}
                    {/*    </button>*/}
                    {/*</div>*/}

                    {/*}*/}

                    {/*/!* Bonus Summ System ++++++++++++++++++++++++++++++*!/*/}
                    {/*<div className="form-check mt-2">*/}
                    {/*    <input className="form-check-input" type="radio" name="loyaltyProgram" id="bonusSum"*/}
                    {/*           checked={loyaltyProgram === 'bonusSum'}*/}
                    {/*           onChange={handleChange}*/}
                    {/*           value="bonusSum"/>*/}
                    {/*    <label className="form-check-label" htmlFor="bonusSum">*/}
                    {/*        {settingsHeader[language].bonusSum} bonusSum*/}
                    {/*    </label>*/}
                    {/*</div>*/}

                    {/*{loyaltyProgram === 'bonusSum' &&*/}
                    {/*<div className="input-group-sm">*/}
                    {/*    <label htmlFor="bonusSumPercent">{settingsHeader[language].bonusPercent}</label>*/}
                    {/*    <input className="form-control"*/}
                    {/*           type="number"*/}
                    {/*           id="bonusSumPercent"*/}
                    {/*           name="condition"*/}
                    {/*           defaultValue={passSettings?.loyaltyProgram?.bonusSum?.percent}*/}
                    {/*           onChange={handleChange}*/}
                    {/*    />*/}
                    {/*</div>}*/}

                    {/*/!* Bonus Visit System ++++++++++++++++++++++++++++++*!/*/}
                    {/*<div className="form-check mt-2">*/}
                    {/*    <input className="form-check-input" type="radio" name="loyaltyProgram" id="bonusVisit"*/}
                    {/*           checked={loyaltyProgram === 'bonusVisit'}*/}
                    {/*           onChange={handleChange}*/}
                    {/*           value="bonusVisit"/>*/}
                    {/*    <label className="form-check-label" htmlFor="bonusVisit">*/}
                    {/*        {settingsHeader[language].bonusVisit} bonusVisit*/}
                    {/*    </label>*/}
                    {/*</div>*/}

                    {/*{loyaltyProgram === 'bonusVisit' &&*/}
                    {/*<div className="input-group-sm">*/}
                    {/*    <label htmlFor="bonusVisitPercent">{settingsHeader[language].bonusVisit}</label>*/}
                    {/*    <input className="form-control"*/}
                    {/*           type="number"*/}
                    {/*           id="bonusVisitPercent"*/}
                    {/*           name="condition"*/}
                    {/*           defaultValue={passSettings?.loyaltyProgram?.bonusVisit?.percent}*/}
                    {/*           onChange={handleChange}*/}
                    {/*    />*/}
                    {/*</div>}*/}

                    {/*/!* Bonus Last Visit System ++++++++++++++++++++++++++++++*!/*/}
                    {/*<div className="form-check mt-2">*/}
                    {/*    <input className="form-check-input" type="radio" name="loyaltyProgram" id="bonusLastVisit"*/}
                    {/*           checked={loyaltyProgram === 'bonusLastVisit'}*/}
                    {/*           onChange={handleChange}*/}
                    {/*           value="bonusLastVisit"/>*/}
                    {/*    <label className="form-check-label" htmlFor="bonusLastVisit">*/}
                    {/*        {settingsHeader[language].bonusLastVisit} bonusLastVisit*/}
                    {/*    </label>*/}
                    {/*</div>*/}

                    {/*{loyaltyProgram === 'bonusLastVisit' &&*/}
                    {/*<div className="input-group-sm">*/}
                    {/*    <label htmlFor="bonusLastVisitPercent">{settingsHeader[language].bonusLastVisit}</label>*/}
                    {/*    <input className="form-control"*/}
                    {/*           type="number"*/}
                    {/*           id="bonusLastVisitPercent"*/}
                    {/*           name="condition"*/}
                    {/*           defaultValue={passSettings?.loyaltyProgram?.bonusLastVisit?.percent}*/}
                    {/*           onChange={handleChange}*/}
                    {/*    />*/}
                    {/*</div>}*/}

                    <h6 className={"mt-4"}>{settingsHeader[language].discountSystem}</h6>
                    {/*// Discount System ++++++++++++++++++++++++++++++*/}
                    <div className="form-check mt-2">
                        <input className="form-check-input" type="radio" name="loyaltyProgram" id="discountSystem"
                               checked={loyaltyProgram === 'discountSystem'}
                               onChange={handleChange}
                               value="discountSystem"/>
                        <label className="form-check-label" htmlFor="discountSystem">
                            {loyalty[language].discountSystem}
                        </label>
                    </div>
                    {loyaltyProgram === 'discountSystem' &&
                    <div className="input-group-sm">

                        <div className={'m-3'}><i>{loyalty[language].discountSystemDescription}<br/></i></div>

                        <label htmlFor="discountPercent">{loyalty[language].discountSystemPlaceholder}</label>
                        <input className="form-control"
                               type="number"
                               id="discountPercent"
                               name="percent"
                               defaultValue={passSettings?.loyaltyProgram?.discountSystem?.percent}
                               onChange={handleChange}
                        />
                    </div>}

                    {/*/!* Discount Bill System ++++++++++++++++++++++++++++++*!/*/}
                    {/*<div className="form-check mt-2">*/}
                    {/*    <input className="form-check-input" type="radio" name="loyaltyProgram" id="discountBill"*/}
                    {/*           checked={loyaltyProgram === 'discountBill'}*/}
                    {/*           onChange={handleChange}*/}
                    {/*           value="discountBill"/>*/}
                    {/*    <label className="form-check-label" htmlFor="discountBill">*/}
                    {/*        {settingsHeader[language].discountBill} discountBill*/}
                    {/*    </label>*/}
                    {/*</div>*/}
                    {/*{loyaltyProgram === 'discountBill' &&*/}
                    {/*<div className="input-group-sm">*/}
                    {/*    <label htmlFor="discountBillPercent">{settingsHeader[language].discountPercent}</label>*/}
                    {/*    <input className="form-control"*/}
                    {/*           type="number"*/}
                    {/*           id="discountBillPercent"*/}
                    {/*           name="percent"*/}
                    {/*           defaultValue={passSettings?.loyaltyProgram?.discountBill?.percent}*/}
                    {/*           onChange={handleChange}*/}
                    {/*    />*/}
                    {/*</div>}*/}

                    {/*/!* Discount Summ System ++++++++++++++++++++++++++++++*!/*/}
                    {/*<div className="form-check mt-2">*/}
                    {/*    <input className="form-check-input" type="radio" name="loyaltyProgram" id="discountSum"*/}
                    {/*           checked={loyaltyProgram === 'discountSum'}*/}
                    {/*           onChange={handleChange}*/}
                    {/*           value="discountSum"/>*/}
                    {/*    <label className="form-check-label" htmlFor="discountSum">*/}
                    {/*        {settingsHeader[language].discountSum} discountSum*/}
                    {/*    </label>*/}
                    {/*</div>*/}
                    {/*{loyaltyProgram === 'discountSum' &&*/}
                    {/*<div className="input-group-sm">*/}
                    {/*    <label htmlFor="discountSumPercent">{settingsHeader[language].discountPercent}</label>*/}
                    {/*    <input className="form-control"*/}
                    {/*           type="number"*/}
                    {/*           id="discountSumPercent"*/}
                    {/*           name="condition"*/}
                    {/*           defaultValue={passSettings?.loyaltyProgram?.discountSum?.condition}*/}
                    {/*           onChange={handleChange}*/}
                    {/*    />*/}
                    {/*</div>}*/}

                    {/*/!* Discount Visit System ++++++++++++++++++++++++++++++*!/*/}
                    {/*<div className="form-check mt-2">*/}
                    {/*    <input className="form-check-input" type="radio" name="loyaltyProgram" id="discountVisit"*/}
                    {/*           checked={loyaltyProgram === 'discountVisit'}*/}
                    {/*           onChange={handleChange}*/}
                    {/*           value="discountVisit"/>*/}
                    {/*    <label className="form-check-label" htmlFor="discountVisit">*/}
                    {/*        {settingsHeader[language].discountVisit} discountVisit*/}
                    {/*    </label>*/}
                    {/*</div>*/}
                    {/*{loyaltyProgram === 'discountVisit' &&*/}
                    {/*<div className="input-group-sm">*/}
                    {/*    <label htmlFor="discountVisitPercent">{settingsHeader[language].discountPercent}</label>*/}
                    {/*    <input className="form-control"*/}
                    {/*           type="number"*/}
                    {/*           id="discountVisitPercent"*/}
                    {/*           name="condition"*/}
                    {/*           defaultValue={passSettings?.loyaltyProgram?.discountVisit?.condition}*/}
                    {/*           onChange={handleChange}*/}
                    {/*    />*/}
                    {/*</div>}*/}

                    {/*/!* Discount Visit System ++++++++++++++++++++++++++++++*!/*/}
                    {/*<div className="form-check mt-2">*/}
                    {/*    <input className="form-check-input" type="radio" name="loyaltyProgram" id="discountLastVisit"*/}
                    {/*           checked={loyaltyProgram === 'discountLastVisit'}*/}
                    {/*           onChange={handleChange}*/}
                    {/*           value="discountLastVisit"/>*/}
                    {/*    <label className="form-check-label" htmlFor="discountLastVisit">*/}
                    {/*        {settingsHeader[language].discountLastVisit} discountVisit*/}
                    {/*    </label>*/}
                    {/*</div>*/}
                    {/*{loyaltyProgram === 'discountLastVisit' &&*/}
                    {/*<div className="input-group-sm">*/}
                    {/*    <label htmlFor="discountLastVisitPercent">{settingsHeader[language].discountPercent}</label>*/}
                    {/*    <input className="form-control"*/}
                    {/*           type="number"*/}
                    {/*           id="discountLastVisitPercent"*/}
                    {/*           name="condition"*/}
                    {/*           defaultValue={passSettings?.loyaltyProgram?.discountLastVisit?.condition}*/}
                    {/*           onChange={handleChange}*/}
                    {/*    />*/}
                    {/*</div>}*/}

                    <h6 className={"mt-4"}>{settingsHeader[language].stampSystem}</h6>
                    {/* Stamp System ++++++++++++++++++++++++++++++*/}
                    <div className="form-check mt-2">
                        <input className="form-check-input" type="radio" name="loyaltyProgram" id="stampSystem"
                               checked={loyaltyProgram === 'stampSystem'}
                               onChange={handleChange}
                               value="stampSystem"/>
                        <label className="form-check-label" htmlFor="stampSystem">
                            {loyalty[language].stampSystem}
                        </label>
                    </div>

                    {loyaltyProgram === 'stampSystem' &&
                    <div className="input-group-sm">

                        <div className={'m-3'}><i>{loyalty[language].stampSystemDescription}<br/></i></div>

                        <label htmlFor="freeNumber">{loyalty[language].stampSystemPlaceholder}</label>
                        <input className="form-control"
                               type="number"
                               id="freeNumber"
                               name="freeNumber"
                               defaultValue={passSettings?.loyaltyProgram?.stampSystem?.freeNumber}
                               onChange={handleChange}
                        />
                    </div>}

                    {/*/!* Stamp Step Bonus System ++++++++++++++++++++++++++++++*!/*/}
                    {/*<div className="form-check mt-2">*/}
                    {/*    <input className="form-check-input" type="radio" name="loyaltyProgram" id="stampStepBonusSystem"*/}
                    {/*           checked={loyaltyProgram === 'stampStepBonusSystem'}*/}
                    {/*           onChange={handleChange}*/}
                    {/*           value="stampStepBonusSystem"/>*/}
                    {/*    <label className="form-check-label" htmlFor="stampStepBonusSystem">*/}
                    {/*        {settingsHeader[language]?.stampStepBonusSystem} stampStepBonusSystem*/}
                    {/*    </label>*/}
                    {/*</div>*/}

                    {/*{loyaltyProgram === 'stampStepBonusSystem' &&*/}
                    {/*<div className="input-group-sm">*/}
                    {/*    <label htmlFor="stampStepBonus">{settingsHeader[language].freeNumber}</label>*/}
                    {/*    <input className="form-control"*/}
                    {/*           type="text"*/}
                    {/*           id="stampStepBonus"*/}
                    {/*           name="percent"*/}
                    {/*           defaultValue={passSettings?.loyaltyProgram?.stampStepBonusSystem?.percent}*/}
                    {/*           onChange={handleChange}*/}
                    {/*    />*/}
                    {/*</div>*/}
                    {/*}*/}

                    {/* Stamp Step Discount System ++++++++++++++++++++++++++++++*/}
                    <div className="form-check mt-2">
                        <input className="form-check-input" type="radio" name="loyaltyProgram" id="stampStepDiscountSystem"
                               checked={loyaltyProgram === 'stampStepDiscountSystem'}
                               onChange={handleChange}
                               value="stampStepDiscountSystem"/>
                        <label className="form-check-label" htmlFor="stampStepDiscountSystem">
                            {loyalty[language]?.stampStepDiscountSystem}
                        </label>
                    </div>

                    {loyaltyProgram === 'stampStepDiscountSystem' &&
                    <div className="input-group-sm">

                        <div className={'m-3'}><i>{loyalty[language].stampStepDiscountSystemDescription}<br/></i></div>

                        <label htmlFor="stampStepDiscount">{loyalty[language].stampStepDiscountSystemPlaceholder}</label>
                        <input className="form-control"
                               type="text"
                               id="stampStepDiscount"
                               name="percent"
                               defaultValue={passSettings?.loyaltyProgram?.stampStepDiscountSystem?.percent}
                               onChange={handleChange}
                        />
                    </div>
                    }




                    {/* Referrer ++++++++++++++++++++++++++++++*/}
                    {(loyaltyProgram === 'stampSystem' || loyaltyProgram === 'bonusSystem') && passSettings.marketing &&
                    <div className="input-group-sm">
                        <h6 className={"mt-4"}>Referral Program</h6>
                        <p>Enter Percentage of the first referral bonus accrual</p>
                        <label htmlFor="referrer">Referrer Awards, %</label>
                        <input className="form-control"
                               type="number"
                               id="referrer"
                               name="referrer"
                               defaultValue={passSettings?.loyaltyProgram?.referralSystem?.referrerPercent}
                               onChange={handleChange}
                        />
                        <label htmlFor="referral">Referral Awards, %:</label>
                        <input className="form-control"
                               type="number"
                               id="referral"
                               name="referral"
                               defaultValue={passSettings?.loyaltyProgram?.referralSystem?.referralPercent}
                               onChange={handleChange}
                        />

                    </div>
                    }

                </div>



                {passSettings.loyaltyProgram.active &&
                <div className="form-check form-switch mt-4 mb-1">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="scan"
                        name='scan'
                        checked={passSettings.loyaltyProgram.scan}
                        onChange={handleChange}
                    />
                    <label className="form-check-label" htmlFor="scan">{settingsHeader[language].scan}</label>
                </div>}


                <button
                    disabled={disabled || !passSettings.loyaltyProgram.active || role !== 'admin'}
                    className='btn btn-primary'
                    onClick={handleSubmit}>
                    {editPass[language].submitButton}
                </button>
            </div>}



        </>
    )
}
