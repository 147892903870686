import React, {useState, useEffect} from "react"
import axios from "../utils/axiosConfig"
import jwtDecode from "jwt-decode";
import {useNavigate} from "react-router-dom"

import { MultiSelect } from "react-multi-select-component"
import getBrowserLocales from "../utils/getBrowserLocales"
import {alerts, editPass, header, multiselect, sendMessages} from '../constants/translations'
import Header from "../layout/Header";
import MessageHeader from "../layout/MessageHeader";

export default () => {

    if (!localStorage.language) {
        getBrowserLocales()
    }

    const language = localStorage.language
    const role = localStorage.role

    const [passSettings, setPassSettings] = useState({})
    const [passes, setPasses] = useState([])
    const [passTypeIds, setPassTypeIds] = useState([])
    const [passTypeId, setPassTypeId] = useState('')
    const [message, setMessage] = useState('')
    const [isError, setIsError] = useState(false)
    const [isInfo, setIsInfo] = useState(false)
    const [alert, setAlert] = useState('Error')
    const [disabled, setDisabled] = useState(false)

    const navigate = useNavigate()

    const [options, setOptions] = useState([])
    const [selected, setSelected] = useState([])
    const [filter, setFilter] = useState('')
    const [filters, setFilters] = useState([{label: sendMessages[language].pushAll, value: 'All'}])
    const [city, setCity] = useState([])
    const [cities, setCities] = useState([])
    const [category, setCategory] = useState([])
    const [categories, setCategories] = useState([])
    const [segment, setSegment] = useState('')
    const [segments, setSegments] = useState([])

    useEffect( () => {
        axios
            .get('/api/passes/settings')
            .then(response => {
                if (response.status !== 200) return
                setPassSettings(response.data)
                setPassTypeId(response.data.passTypeIdentifier[0])

                if (response.data.passTypeIdentifier.length > 1) {
                    setPassTypeIds(response.data.passTypeIdentifier)
                    // setFilters(prevState => [...prevState, {
                    //     label: sendMessages[language].passTypeIdentifier,
                    //     value: 'passTypeId'
                    // }])
                }
                if (response.data?.templateId?.length > 1) {
                    // setTemplateId(response.data.templateId)
                    setFilters(prevState => [...prevState, {
                        label: sendMessages[language].templateId,
                        value: 'templateId'
                    }])
                }

                if (response.data?.formData?.city?.length > 1) {
                    // setCities(response.data.formData?.city)
                    setFilters(prevState => [...prevState, {
                        label: sendMessages[language].city,
                        value: 'city'
                    }])
                }

                if (response.data?.category?.length >= 1) {
                    // setCategories(response.data.category)
                    setFilters(prevState => [...prevState, {
                        label: sendMessages[language].category,
                        value: 'category'
                    }])
                }

                if (response.data?.segment?.length >= 1) {
                    // setSegments(prevState => [...prevState, segment.name])
                    setFilters(prevState => [...prevState, {
                        label: sendMessages[language].segment,
                        value: 'segment'
                    }])
                }
                // todo Move to submit section
                axios
                    .get('/api/v1/passes')
                    .then(response => {
                        if (response.status !== 200) return
                        setPasses(response.data)
                    })
                    .catch(e => {
                        console.log(e.response.status)
                        e.response.status === 401 && navigate('/user/login')
                        e.response.status === 402 && navigate('/402')
                        //todo to error page
                        e.response.status === 403 && navigate('/403')
                        e.response.status === 404 && setPasses([])
                    })
            })
            .catch(e => {
                console.log(e.response.status)
                e.response.status === 401 && navigate('/user/login')
                //todo to error page
                e.response.status === 402 && navigate('/402')
                e.response.status === 403 && navigate('/403')
                e.response.status === 404 && setPasses([])
            })
    }, [navigate])

    const handleChange = (event) => {
        const {name, value} = event.target
        name === 'message' && setMessage(value)
        name === 'passTypeId' && setPassTypeId(value)
        if (name === 'filter') {
            setFilter(value)
            if (value === 'All'){
                setFilter('')
                setOptions([])
                setSelected([])

            }
            if (value === 'passTypeId'){
                setOptions([])
                setSelected([])
                passSettings.passTypeIdentifier.map(item => {
                    setOptions(prevState => [...prevState, {label: item, value: item}])
                })
            }
            // placeholders
            if (value === 'templateId'){
                setOptions([])
                setSelected([])
                passSettings.templateId.map(item => {
                    setOptions(prevState => [...prevState, {label: item, value: item}])
                })
            }
            if (value === 'city'){
                setOptions([])
                setSelected([])
                passSettings.formData.city.map(item => {
                    setOptions(prevState => [...prevState, {label: item, value: item}])
                })
            }
            if (value === 'category'){
                setOptions([])
                setSelected([])
                passSettings.category.map(item => {
                    setOptions(prevState => [...prevState, {label: item, value: item}])
                })
            }
            // segment
            if (value === 'segment'){
                setOptions([])
                setSelected([])
                passSettings.segment.map(item => {
                    setOptions(prevState => [...prevState, {label: item.name, value: item.name}])
                })
            }
        }
    }

    // const handleChangeSelect = event => {
    //     setPassTypeId(event.target.value)
    // }

    const handlerSubmit = async (event) => {
        event.preventDefault()


        let selectedArr = selected.map(item => item.value)

        let filteredPasses = passes.filter(item => item.passTypeIdentifier === passTypeId)

        if (!selectedArr){

        }

            // if (filter === 'passTypeId'){
        //     // filteredPasses = passes.filter(item => selected.every(val => item.passTypeIdentifier.includes(val.value)))
        //     filteredPasses = filteredPasses.filter(item => selectedArr.includes(item.passTypeIdentifier))
        // }

        if (filter === 'templateId'){
            filteredPasses = []
            await axios
                .get(`/api/v1/passes?selectQuery[]=placeholders.templateId`)
                .then(response => {
                    if (response.status !== 200) return
                    filteredPasses = response.data.filter(
                        item => selectedArr.includes(item?.placeholders?.templateId))
                        .filter(item => item.passTypeIdentifier === passTypeId)
                })
                .catch(e => {
                    console.log(e.response.status)
                    e.response.status === 401 && navigate('/user/login')
                    //todo to error page
                    e.response.status === 402 && navigate('/402')
                    e.response.status === 403 && navigate('/403')
                    e.response.status === 404 && setPasses([])
                })
        }

        if (filter === 'city'){
            filteredPasses = []
            await axios
                .get(`/api/v1/passes?selectQuery[]=placeholders.city`)
                .then(response => {
                    if (response.status !== 200) return
                    filteredPasses = response.data.filter(
                        item => selectedArr.includes(item?.placeholders?.city))
                        .filter(item => item.passTypeIdentifier === passTypeId)
                })
                .catch(e => {
                    console.log(e.response.status)
                    e.response.status === 401 && navigate('/user/login')
                    //todo to error page
                    e.response.status === 402 && navigate('/402')
                    e.response.status === 403 && navigate('/403')
                    e.response.status === 404 && setPasses([])
                })
        }

        if (filter === 'category'){
            filteredPasses = []
            await axios
                .get(`/api/v1/passes?selectQuery[]=placeholders.category`)
                .then(response => {
                    if (response.status !== 200) return
                    // filteredPasses = response.data.filter(item => selectedArr.includes(item?.placeholders?.category))
                    filteredPasses = response?.data?.filter(
                        item => item?.placeholders?.category && item?.placeholders?.category.every(val => selectedArr.includes(val)))
                        .filter(item => item.passTypeIdentifier === passTypeId)
                })
                .catch(e => {
                    console.log(e.response.status)
                    e.response.status === 401 && navigate('/user/login')
                    //todo to error page
                    e.response.status === 402 && navigate('/402')
                    e.response.status === 403 && navigate('/403')
                    e.response.status === 404 && setPasses([])
                })
        }

        if (filter === 'segment'){
            filteredPasses = []
            for (const segment of selectedArr) {
                await axios
                    .get(`/api/v1/passes?segment=${segment}`)
                    .then(response => {
                        if (response.status !== 200) return
                        filteredPasses = [...filteredPasses, ...response.data].filter(item => item.passTypeIdentifier === passTypeId)
                    })
                    .catch(e => {
                        console.log(e.response.status)
                        e.response.status === 401 && navigate('/user/login')
                        //todo to error page
                        e.response.status === 402 && navigate('/402')
                        e.response.status === 403 && navigate('/403')
                        e.response.status === 404 && setPasses([])
                    })
            }
        }
        // console.log(filteredPasses);
        // console.log(filteredPasses.length);


        if (filteredPasses.length === 0) {
            setIsError(true)
            setAlert(alerts[language].cardRegistryEmpty)
            return setTimeout(() => {
                setIsError(false)
            }, 3000)
        }
        for (let item of filteredPasses) {
            item.message = message
        }

        // console.log(selectedArr);
        axios
            .put(`/api/v1/passes/${passTypeId}/values`, filteredPasses)
            .then(r => {
                if (r.status === 200) {
                    setDisabled(true)
                    setIsInfo(true)
                    setAlert(alerts[language].successSend)
                    setTimeout(() => {
                        setIsInfo(false)
                        setDisabled(false)
                        navigate('/cards')
                    }, 3000)

                }
            })
            .catch(e => {
                if (e.response.status === 429){
                    setIsError(true)
                    setDisabled(true)
                    setAlert(`${alerts[language].tooManyReq} ${e.response.headers['retry-after']} sec`)
                    setTimeout(() => {
                        setIsError(false)
                        setDisabled(false)
                    }, (e.response.headers['retry-after'] * 1000))
                }
                if (e.response.status === 400){
                    setIsError(true)
                    setAlert(alerts[language].badReq)
                    setTimeout(() => {
                        setIsError(false)
                    }, 3000)
                }
                if (e.response.status === 401){
                    setIsError(true)
                    setAlert(alerts[language].badAuth)
                    setTimeout(() => {
                        setIsError(false)
                    }, 3000)
                }
                if (e.response.status === 404){
                    setIsError(true)
                    setAlert(alerts[language].userNotFound)
                    setTimeout(() => {
                        setIsError(false)
                    }, 3000)
                }
                if (e.response.status === 500){
                    setIsError(true)
                    setAlert(alerts[language].serverError)
                    setTimeout(() => {
                        setIsError(false)
                    }, 3000)
                }
            })

    }

    const passTypeOptions = passTypeIds.map((item, index) => {
        return (
            <option key={index} value={item}>{item}</option>
        )
    })
    const filterOptions = filters.map((item, index) => {
        return (
            <option key={index} value={item.value}>{item.label}</option>
        )
    })

    // console.log(cities, categories, segments, filters);
    // console.log(filter, selected);

    return (
        <div>
            {/*<Header />*/}
            <h4 className={"text-primary"}>{header[language].push_message}</h4>
            <hr className={"mt-0 mb-0"} />
            <MessageHeader/>

            <div>
                {isInfo && (
                    <div className="alert alert-info" role="alert">
                        {alert}
                    </div>
                )}
                {isError && (
                    <div className="alert alert-danger" role="alert">
                        {alert}
                    </div>
                )}
                <div className='card p-3 col-md-6 col-xl-4 m-auto mb-5'>
                    <h4 style={{textAlign: "center"}}>{sendMessages[language].title}</h4>
                    <form onSubmit={handlerSubmit}>
                        <div className="mb-3">
                            {(passTypeIds.length > 1) && <>
                                <label htmlFor="passTypeId" className="form-label">{sendMessages[language].passTypeIdentifier}</label>
                                <select className="form-select mb-5" id={"passTypeId"} name={"passTypeId"} onChange={handleChange}>
                                    {passTypeOptions}
                                </select>
                            </>}
                            {(filters.length > 1) && <>
                                <label htmlFor="filter" className="form-label">{sendMessages[language].filter}</label>
                                <select className="form-select mb-3" id={"filter"} name={"filter"} onChange={handleChange}>
                                    {filterOptions}
                                </select>
                            </>}
                            {filter && <div className="mb-3">
                                {/*<pre>{JSON.stringify(selectedCategory)}</pre>*/}
                                <MultiSelect
                                    id={filter}
                                    options={options}
                                    overrideStrings={multiselect[language]}
                                    hasSelectAll={false}
                                    disableSearch={true}
                                    closeOnChangedValue={true}
                                    value={selected}
                                    onChange={setSelected}
                                />
                            </div>}
                            <label htmlFor="message" className="form-label">{sendMessages[language].message}</label>
                            <textarea
                                className="form-control"
                                id="message"
                                required
                                name={"message"}
                                onChange={handleChange}
                            />
                            <div className="form-text">
                                {sendMessages[language].subTitle}
                            </div>
                        </div>
                        <div className="d-grid">
                            <button
                                type="submit"
                                className="btn btn-primary mt-3"
                                disabled={disabled || !message || (role !== 'admin' && role !== 'test')}
                            >{sendMessages[language].submitButton}</button>
                        </div>
                    </form>
                </div>

            </div>
        </div>
    )
}
