import React, {useEffect, useState} from "react"
import axios from "../utils/axiosConfig"
import {useNavigate, useParams} from "react-router-dom";
import {userLogin} from "../constants/translations";
import getBrowserLocales from "../utils/getBrowserLocales";

export default () => {

    if (!localStorage.language) {
        getBrowserLocales()
    }

    const language = localStorage.language

    const navigate = useNavigate()

    const {email, hash} = useParams()
    // console.log(hash);

    const [isError, setIsError] = useState(false)
    const [user, setUser] = useState({hash, email, password: '', passwordConfirmation: '', confirmTerms: false})
    const [isValid, setIsValid] = useState(true)

    useEffect(() => {
        if (user.password !== user.passwordConfirmation){
            setIsValid(false)
        } else setIsValid(true)
    }, [user])

    const handleChange = (event) => {
        const {name, value, type, checked} = event.target
        type === 'checkbox' ? setUser({...user, [name]: checked}) : setUser({...user, [name]: value})
    }

    const handlerSubmit = (event) => {
        event.preventDefault()
        axios
            .post('/api/auth/recover', user)
            .then(r => {
                if (r.status === 200) {
                    // localStorage.token = r.data.token
                    navigate('/cards')
                }
            })
            .catch(e => {
                console.log(e)
                navigate('/user/login')
            })
    }

    return (
        <div className={"auth-form d-grid"}>
            {isError && (
                <div className="alert alert-danger" role="alert">
                    {alert}
                </div>
            )}
            <div className="form-card">
                <h4>{userLogin[language].changePassword}</h4>
                <form onSubmit={handlerSubmit}>
                    <input
                        disabled
                        value={user.email}
                    />
                    <input
                        type="password"
                        id="password"
                        placeholder={userLogin[language].password}
                        autoComplete={"new-password"}
                        required
                        name={"password"}
                        value={user.password}
                        onChange={handleChange}
                    />
                    <input
                        type="password"
                        id="passwordConfirmation"
                        placeholder={userLogin[language].confirmPassword}
                        autoComplete={"new-password"}
                        required
                        name={"passwordConfirmation"}
                        value={user.passwordConfirmation}
                        onChange={handleChange}
                    />
                    <div className="d-grid">
                        <button
                            type="submit"
                            className="btn btn-primary mt-3"
                            disabled={!isValid}
                        >{userLogin[language].saveBtn}</button>
                    </div>
                </form>
            </div>

        </div>
    )
}
