import React, {useState, useEffect} from "react"
import axios from "../utils/axiosConfig"
import {cardsDisplay, header, statistic} from "../constants/translations"
import {useNavigate} from "react-router-dom";

import { ThreeDots } from  'react-loader-spinner'

export default () => {
    const language = localStorage.language

    const [cards, setCards] = useState([])
    const [passSettings, setPassSettings] = useState({})
    const [isLoading, setIsLoading] = useState(true)

    const navigate = useNavigate()

    useEffect( () => {

        const currentMonth = (new Date).getMonth() + 1
        const currentYear = (new Date).getFullYear()
        const start = Date.parse(currentYear + "-" + ('0'+currentMonth).slice(-2) + "-01")
        const end = Date.now()

        axios
            .get(`/api/passes/passes?since=${start}&till=${end}`)
            .then(response => {
                if (response.status !== 200) return setIsLoading(false)
                setCards(response.data)
                 setIsLoading(false)
            })
            .catch(e => {
                e.response.status === 401 && navigate('/user/login')
                //todo to error page
                e.response.status === 402 && navigate('/402')
                e.response.status === 403 && navigate('/403')
                e.response.status === 404 && setCards([])
                 setIsLoading(false)
            })

        axios.get('/api/passes/settings')
            .then(response => {
                setPassSettings(response.data)
            })
            .catch(err => console.log(err))


    }, [])


    const issuedCards = cards.length
    const issuedByDepartments = () => {
        const departments = [...new Set(cards.map(item => item.department))]
        return departments.map((item, index) => {
            const length = cards.filter(card => card.department === item).length
            return (
                <div key={index} className="d-flex justify-content-between align-items-start">
                    <div className="ms-3 me-auto">
                        <div>{item || statistic[language].selfService}</div>
                    </div>
                    <span className="badge bg-secondary">{new Intl.NumberFormat().format(length)}</span>
                </div>
            )
        })
    }
    const issuedBySource = () => {
        const sources = [...new Set(cards.map(item => item.source))].filter(i => i)
        return sources.map((item, index) => {
            const length = passSettings.marketing && cards.filter(card => card.source === item).length
            return (
                <div key={index} className="d-flex justify-content-between align-items-start">
                    <div className="ms-3 me-auto">
                        <div>{item === "register" ? statistic[language].register : item}</div>
                    </div>
                    <span className="badge bg-secondary">{Number.isInteger(length) ? new Intl.NumberFormat().format(length) : 'n/a'}</span>
                </div>
            )
        })
    }

    const issuedByUser = passSettings.marketing && cards.filter(card => card.user && !card.source).length
    const issuedByReferral = passSettings.marketing && cards.filter(card => card.referral).length
    const issuedByMainLink = passSettings.marketing && cards.filter(card => !card.user && !card.source).length

    const appleInstalled = passSettings.marketing && cards.filter(card => card.installed.apple).length
    const androidInstalled = passSettings.marketing && cards.filter(card => card.installed.android).length

    const inactiveDays = passSettings.inactive || 35
    const activeClients = passSettings.marketing && cards.filter(card => card.lastVisit > (Date.now() - inactiveDays * 24*60*60000) || Date.parse(card.createdAt) > (Date.now() - 35*24*60*60000)).length

    const genderM = passSettings.marketing && cards.filter(card => card.gender === 'm').length
    const genderF = passSettings.marketing && cards.filter(card => card.gender === 'f').length

    const getCards = (period) => {
        setIsLoading(true)
        if (!period.start || !period.end){
            axios
                .get(`/api/passes/passes`)
                .then(response => {
                    if (response.status === 200) {
                        setCards(response.data)
                    } else setCards([])
                    setIsLoading(false)
                })
                .catch(e => {
                    console.log(e.response.status)
                    e.response.status === 401 && navigate('/user/login')
                    //todo to error page
                    e.response.status === 402 && navigate('/402')
                    e.response.status === 403 && navigate('/403')
                    e.response.status === 404 && setCards([])
                    setIsLoading(false)
                })
        }
        else {
            axios
                .get(`/api/passes/passes?since=${period.start}&till=${period.end}`)
                .then(response => {
                    if (response.status === 200) {
                        setCards(response.data)
                    } else setCards([])
                    setIsLoading(false)
                })
                .catch(e => {
                    console.log(e.response.status)
                    e.response.status === 401 && navigate('/user/login')
                    //todo to error page
                    e.response.status === 402 && navigate('/402')
                    e.response.status === 403 && navigate('/403')
                    e.response.status === 404 && setCards([])
                    setIsLoading(false)
                })
        }
    }

    const handleChange = e => {
        const {name, value} = e.target

        const currentMonth = (new Date).getMonth() + 1
        const currentYear = (new Date).getFullYear()
        if (name === 'period' && value === 'current'){
            const start = Date.parse(currentYear + "-" + ('0'+currentMonth).slice(-2) + "-01")
            const end = Date.now()
            getCards({start, end})
        } else if (name === 'period' && value === 'previous'){
            let previousMonth = currentMonth - 1
            let previousYear = currentYear
            if (currentMonth === 1) {
                previousMonth = 12
                previousYear -= 1
            }
            const start = Date.parse(previousYear + "-" + ('0'+previousMonth).slice(-2) + "-01")
            const end = Date.parse(currentYear + "-" + ('0'+currentMonth).slice(-2) + "-01")
            getCards({start, end})
        } else {
            getCards({})
        }
    }

    return (
        <div>
            {/*<Header />*/}
            <h4 className={"text-primary"}>{header[language].statistic}</h4>
            <hr className={"mt-0 mb-5"} />

            <div className="input-group mt-5 mb-5"  style={{maxWidth: 275}}>
                <span className="input-group-text">{statistic[language].period}</span>
                <select className="form-select" id={"passType"} name={"period"} onChange={handleChange}>
                    <option key={1} value={'current'}>{statistic[language].currentMonth}</option>
                    <option key={2} value={'previous'}>{statistic[language].previousMonth}</option>
                    {/*<option key={3} value={'sixMonths'}>{statistic[language].sixMonths}</option>*/}
                    {/*<option key={4} value={'twelveMonths'}>{statistic[language].twelveMonths}</option>*/}
                    <option key={5} value={'full'}>{statistic[language].fullPeriod}</option>
                    {/*{passTypeIds.map((item, index) => (<option key={index} value={item}>{item}</option>))}*/}
                </select>
            </div>

            {isLoading ? <ThreeDots
                    height="80"
                    width="80"
                    radius="9"
                    color="#0d6efd"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                /> :

                <div className="container-fluid row justify-content-evenly">
                    <div className="m-3 p-3 card" style={{maxWidth: 450}}>
                        <div>
                            <div className="d-flex justify-content-between align-items-start">
                                <div className="me-auto">
                                    <h4 className="fw-bold">{statistic[language].issuedTotal}</h4>
                                </div>
                                <h4><span
                                    className="badge bg-primary">{new Intl.NumberFormat().format(issuedCards)}</span>
                                </h4>
                            </div>
                            {issuedByDepartments()}
                        </div>
                    </div>

                    <div className="m-3 p-3 card" style={{maxWidth: 450}}>
                        <div>
                            <div className="d-flex justify-content-between align-items-start">
                                <div className="me-auto">
                                    <h4 className="fw-bold">{statistic[language].source}</h4>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-start">
                                <div className="ms-3 me-auto">
                                    <div>{statistic[language].mainLink}</div>
                                </div>
                                <span
                                    className="badge bg-secondary">{Number.isInteger(issuedByMainLink) ? new Intl.NumberFormat().format(issuedByMainLink) : 'n/a'}</span>
                            </div>
                            <div className="d-flex justify-content-between align-items-start">
                                <div className="ms-3 me-auto">
                                    <div>{statistic[language].cabinet}</div>
                                </div>
                                <span
                                    className="badge bg-secondary">{Number.isInteger(issuedByUser) ? new Intl.NumberFormat().format(issuedByUser) : 'n/a'}</span>
                            </div>
                            {issuedByReferral > 0 && <div className="d-flex justify-content-between align-items-start">
                                <div className="ms-3 me-auto">
                                    <div>{statistic[language].referral}</div>
                                </div>
                                <span
                                    className="badge bg-secondary">{Number.isInteger(issuedByReferral) ? new Intl.NumberFormat().format(issuedByReferral) : 'n/a'}</span>
                            </div>}
                            {issuedBySource()}
                        </div>
                    </div>

                    <div className="m-3 p-3 card" style={{maxWidth: 450}}>
                        <div>
                            <div className="d-flex justify-content-between align-items-start">
                                <div className="me-auto">
                                    <h4 className="fw-bold">{statistic[language].installedAt}</h4>
                                </div>
                                <h4><span
                                    className="badge bg-primary">{(Number.isInteger(appleInstalled) && Number.isInteger(androidInstalled)) ? new Intl.NumberFormat().format(appleInstalled + androidInstalled) : 'n/a'}</span>
                                </h4>
                            </div>
                            <div className="d-flex justify-content-between align-items-start">
                                <div className="ms-3 me-auto">
                                    <div>iOS</div>
                                </div>
                                <span
                                    className="badge bg-secondary">{Number.isInteger(appleInstalled) ? new Intl.NumberFormat().format(appleInstalled) : 'n/a'}</span>
                            </div>
                            <div className="d-flex justify-content-between align-items-start">
                                <div className="ms-3 me-auto">
                                    <div>Android</div>
                                </div>
                                <span
                                    className="badge bg-secondary">{Number.isInteger(androidInstalled) ? new Intl.NumberFormat().format(androidInstalled) : 'n/a'}</span>
                            </div>
                        </div>
                    </div>

                    <div className="m-3 p-3 card" style={{maxWidth: 450}}>
                        <div>
                            <div className="d-flex justify-content-between align-items-start">
                                <div className="me-auto">
                                    <h4 className="fw-bold">{statistic[language].clients}</h4>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-start">
                                <div className="ms-3 me-auto">
                                    <div>{statistic[language].active}</div>
                                </div>
                                <span
                                    className="badge bg-secondary">{Number.isInteger(activeClients) ? new Intl.NumberFormat().format(activeClients) : 'n/a'}</span>
                            </div>
                            <div className="d-flex justify-content-between align-items-start">
                                <div className="ms-3 me-auto">
                                    <div>{statistic[language].passive}</div>
                                </div>
                                <span
                                    className="badge bg-secondary">{(Number.isInteger(issuedCards) && Number.isInteger(activeClients)) ? new Intl.NumberFormat().format(issuedCards - activeClients) : 'n/a'}</span>
                            </div>
                            <div className={'ms-3'}>
                                <i>{statistic[language].criterion}</i>
                            </div>
                        </div>
                    </div>

                    <div className="m-3 p-3 card" style={{maxWidth: 450}}>
                        <div>
                            <div className="d-flex justify-content-between align-items-start">
                                <div className="me-auto">
                                    <h4 className="fw-bold">{statistic[language].clientsGender}</h4>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-start">
                                <div className="ms-3 me-auto">
                                    <div>{statistic[language].male}</div>
                                </div>
                                <span
                                    className="badge bg-secondary">{Number.isInteger(genderM) ? new Intl.NumberFormat().format(genderM) : 'n/a'}</span>
                            </div>
                            <div className="d-flex justify-content-between align-items-start">
                                <div className="ms-3 me-auto">
                                    <div>{statistic[language].female}</div>
                                </div>
                                <span
                                    className="badge bg-secondary">{Number.isInteger(genderF) ? new Intl.NumberFormat().format(genderF) : 'n/a'}</span>
                            </div>
                            <div className="d-flex justify-content-between align-items-start">
                                <div className="ms-3 me-auto">
                                    <div>{statistic[language].notDetermined}</div>
                                </div>
                                <span
                                    className="badge bg-secondary">{(Number.isInteger(genderM) && Number.isInteger(genderF)) ? new Intl.NumberFormat().format(issuedCards - genderM - genderF) : 'n/a'}</span>
                            </div>
                        </div>
                    </div>

                </div>}

            {!passSettings.marketing && <div className={"mb-5 mt-5 text-danger"}>
                <i>"n/a" - {cardsDisplay[language].availableWithMarketing}</i>
            </div>}

        </div>
    )
}
