import React, {useState, useEffect} from "react"
import axios from "../utils/axiosConfig"
import {useParams, useNavigate} from "react-router-dom"
import { ThreeDots } from  'react-loader-spinner'
import {alerts} from "../constants/translations";

export default () => {

    const navigate = useNavigate()
    const language = localStorage.language

    const [posts, setPosts] = useState([])
    const [filteredPosts, setFilteredPosts] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    const [isInfo, setIsInfo] = useState(false)
    const [alert, setAlert] = useState('Error')
    const [disabled, setDisabled] = useState(false)

    const [hidden, setHidden] = useState(false)

    useEffect(() => {
        let url = '/api/posts'

        axios.get(url)
            .then(res => {
                setPosts(res.data)
                setFilteredPosts(res.data)
                setIsLoading(false)
            })
            .catch(e => {
                setIsLoading(false)
                e.response.status === 401 && navigate({pathname: '/user/login', search: 'admin'})
                //todo to error page
                e.response.status === 402 && navigate('/402')
                e.response.status === 403 && navigate('/403')
                e.response.status === 404 && setPosts([])
                console.log(e)
            })

    }, [navigate])

    const editPost = (post) => {
        // console.log(post);
        navigate('/posts/edit', {state: post})
    }

    const createHtml = (fileName) => {
        if (fileName){
            const selectedPosts = posts.filter(el => el.fileName === fileName)
            axios.post('api/posts/create-post-files', selectedPosts)
                .then(r => {
                    if (r.status === 200) {
                        setDisabled(true)
                        setIsInfo(true)
                        setAlert(alerts[language].success)
                        setTimeout(() => {
                            setIsInfo(false)
                            setDisabled(false)
                            navigate('/posts')
                        }, 3000)

                    }
                })
                .catch(e => console.log(e))

        }
    }

    const deletePost = (fileName) => {
        if (fileName){

            axios.delete('api/posts', {data: {fileName}})
                .then(r => {
                    if (r.status === 200) {
                        setDisabled(true)
                        setIsInfo(true)
                        setAlert(alerts[language].success)
                        setTimeout(() => {
                            setIsInfo(false)
                            setDisabled(false)
                            navigate('/posts')
                        }, 3000)

                    }
                })
                .catch(e => console.log(e))
        }
    }

    const handleLanguage = lang => {
        setFilteredPosts(
            posts.filter(item => item.language === lang)
        )
    }

    const content = filteredPosts.map((item, index) => {
        return (
            <div key={index} className="card ">
                {isInfo && (
                    <div className="alert alert-info w-100" role="alert">
                        {alert}
                    </div>
                )}
                <div className="m-5">
                    <div className="df jc-sb">
                        <p>{item.language}</p>
                        <p>{item.published}</p>
                        <p>{item.fileName}</p>
                        <p>{item.date}</p>
                    </div>
                    <p>{item.title}</p>
                    <div className={hidden ? 'hidden' : ''}>
                        <p>{item.description}</p>
                        <p>{item.text}</p>
                        <p>{item.keywords}</p>
                    </div>
                    <div className="df jc-sa">
                        <button
                            className="btn btn-warning"
                            onClick={()=>editPost(item)}
                            disabled={disabled}
                        >Edit</button>
                        <button
                            className="btn btn-success"
                            onClick={() => createHtml(item.fileName)}
                            disabled={disabled}
                        >Publish html</button>
                        <button
                            className="btn btn-primary"
                            disabled={disabled}

                        >Publish FB</button>
                        <button
                            className="btn btn-info"
                            disabled={disabled}

                        >Publish TW</button>
                        <button
                            className="btn btn-danger"
                            onClick={() => deletePost(item.fileName)}
                            disabled={disabled}
                        >Delete</button>
                    </div>
                </div>
            </div>
        )
    })

// todo Update content displat after deleting or editing

    return (
        <>
            <div className="df jc-sb">
                <h4 className={"text-primary"}>{/*{transaction[language].transaction}*/ "Posts"} </h4>
                <div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="lang" id="inlineRadio1"
                               onClick={()=>handleLanguage('uk')}
                               value="uk"/>
                        <label className="form-check-label" htmlFor="inlineRadio1">UK</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="lang" id="inlineRadio2"
                               onClick={()=>handleLanguage('pl')}
                               value="pl"/>
                        <label className="form-check-label" htmlFor="inlineRadio2">PL</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="lang" id="inlineRadio3"
                               onClick={()=>handleLanguage('en')}
                               value="en"/>
                        <label className="form-check-label" htmlFor="inlineRadio3">EN</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="lang" id="inlineRadio4"
                               onClick={()=>handleLanguage('ru')}
                               value="ru"/>
                        <label className="form-check-label" htmlFor="inlineRadio4">RU</label>
                    </div>
                </div>


                <button
                    className="btn btn-primary"
                    onClick={() => setHidden(prevState => !prevState)}
                >{hidden ? 'Show' : 'Hide'}</button>
            </div>
            <hr className={"mt-0 mb-5"} />

            {
                isLoading ? <ThreeDots
                        height="80"
                        width="80"
                        radius="9"
                        color="#0d6efd"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                    /> :
                    <div>
                        {content}
                    </div>
            }

        </>
    )
}