import React, { useState, useEffect } from "react";
import axios from "../utils/axiosConfig";
import { useNavigate } from "react-router-dom";
import Header from "../layout/Header";
import {
  settingsHeader,
  editPass,
  error,
  alerts,
  header,
  loyalty,
  swal,
} from "../constants/translations";
import SettingsHeader from "../layout/SettingsHeader";
import Swal from "sweetalert2";

export default () => {
  const language = localStorage.language;
  const role = localStorage.role;
  const [isError, setIsError] = useState(false);
  const [isInfo, setIsInfo] = useState(false);
  const [alert, setAlert] = useState("Error");
  const [disabled, setDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [passSettings, setPassSettings] = useState();

  const [formData, setFormData] = useState();
  const [city, setCity] = useState(false);

  useEffect(() => {
    axios
      .get("/api/passes/settings")
      .then((response) => {
        if (response.status !== 200) return setIsLoading(false);
        setPassSettings(response.data);
        setFormData(response.data.formData);
        setCity(response?.data?.formData?.city.length > 0);
        setIsLoading(false);
        if (response.data?.test) {
          Swal.fire({
            title: "Demo",
            text: swal[language].demoAccSettingsText,
            confirmButtonText: "OK",
            confirmButtonColor: "#0d6efd",
          });
        }
      })
      .catch((e) => {
        console.log("ERRORR", e.response.status);
        setIsLoading(false);
        e.response.status === 401 && navigate("/user/login");
        e.response.status === 402 && navigate("/402");
        e.response.status === 403 && navigate("/403");
        e.response.status === 404 && setPassSettings([]);
      });
  }, []);

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    type === "checkbox"
      ? setPassSettings({ ...passSettings, [name]: checked })
      : setPassSettings({ ...passSettings, [name]: value });
  };
  const handleChangeForm = (event) => {
    let { name, value, type, checked } = event.target;
    if (name === "city") {
      value = value.replace(/\s/g, "").split(",");
    }
    type === "checkbox"
      ? setFormData({ ...formData, [name]: checked })
      : setFormData({ ...formData, [name]: value });
    type === "checkbox"
      ? setPassSettings({
          ...passSettings,
          formData: { ...formData, [name]: checked },
        })
      : setPassSettings({
          ...passSettings,
          formData: { ...formData, [name]: value },
        });
  };

  const cityHandler = () => {
    setCity((prevState) => {
      if (prevState) {
        setPassSettings({
          ...passSettings,
          formData: { ...formData, city: [] },
        });
      }
      return !prevState;
    });
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    setDisabled(true);
    try {
      await axios
        .put("/api/passes/settings", passSettings)
        .then((response) => {
          if (response.status !== 200) return setIsLoading(false);
          setIsLoading(false);
          setIsInfo(true);
          setAlert(alerts[language].success);
          setTimeout(() => {
            setIsInfo(false);
            setDisabled(false);
          }, 2000);
        })
        .catch((e) => {
          console.log("ERRORR", e.response.status);
          setIsLoading(false);

          e.response.status === 401 && navigate("/user/login");
          e.response.status === 402 && navigate("/402");
          e.response.status === 403 && navigate("/403");
        });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      {isInfo && (
        <div className="alert alert-info" role="alert">
          {alert}
        </div>
      )}
      {isError && (
        <div className="alert alert-danger" role="alert">
          {alert}
        </div>
      )}

      <h4 className={"text-primary"}>{header[language].settings}</h4>
      <hr className={"mt-0 mb-0"} />
      <SettingsHeader />
      {/*<hr className={"mt-0 mb-5"} />*/}
      {passSettings && formData && (
        <div className="card p-3 col-md-6 col-xl-4 m-auto mb-5">
          <h4 className="mt-3">{settingsHeader[language].formData}</h4>
          <label htmlFor="floatingSelect">
            {settingsHeader[language].language}
          </label>
          <div className="input-group-sm">
            <select
              className="form-select"
              id="floatingSelect"
              aria-label="Floating label select example"
              name="language"
              onChange={handleChange}
              defaultValue={passSettings.language}
            >
              <option value="">auto</option>
              <option value="uk">Українська</option>
              <option value="pl">Polski</option>
              <option value="en">English</option>
              <option value="de">Deutsche</option>
              <option value="es">Español</option>
              <option value="cs">Český</option>
              <option value="lt">Lietuvių</option>
              <option value="bg">Български</option>
              <option value="ru">русский</option>
            </select>
          </div>

          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="checkbox"
              id="firstName"
              name="firstName"
              checked={formData?.firstName}
              onChange={handleChangeForm}
            />
            <label className="form-check-label" htmlFor="firstName">
              {editPass[language].firstName}
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="checkbox"
              id="lastName"
              name="lastName"
              checked={formData?.lastName}
              onChange={handleChangeForm}
            />
            <label className="form-check-label" htmlFor="lastName">
              {editPass[language].lastName}
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="checkbox"
              id="tel"
              name="tel"
              checked={formData?.tel}
              onChange={handleChangeForm}
            />
            <label className="form-check-label" htmlFor="tel">
              {editPass[language].tel}
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="checkbox"
              id="birthday"
              name="birthday"
              checked={formData?.birthday}
              onChange={handleChangeForm}
            />
            <label className="form-check-label" htmlFor="birthday">
              {editPass[language].birthday}
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="checkbox"
              id="email"
              name="email"
              checked={formData?.email}
              onChange={handleChangeForm}
            />
            <label className="form-check-label" htmlFor="email">
              Email
            </label>
          </div>
          <div className="form-check form-check input-group-sm">
            <input
              className="form-check-input"
              type="checkbox"
              id="city"
              checked={city}
              onChange={cityHandler}
            />
            <label className="form-check-label" htmlFor="city">
              {settingsHeader[language].city}
            </label>
            {city && (
              <input
                type="text"
                className="form-control"
                placeholder={settingsHeader[language].arrayData}
                name="city"
                id="city"
                value={passSettings?.formData?.city}
                onChange={handleChangeForm}
              />
            )}
          </div>

          <div className=" input-group-sm">
            <label htmlFor="confidentialUrl">Privacy Policy URL</label>
            <input
              className="form-control"
              type="text"
              id="confidentialUrl"
              name="confidentialUrl"
              defaultValue={passSettings?.confidentialUrl}
              onChange={handleChange}
              placeholder={"https://loyalcards.com.ua/privacy-policy.html"}
            />
          </div>

          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckChecked"
              name="selfCreation"
              checked={passSettings?.selfCreation}
              onChange={handleChange}
            />
            <label
              className="form-check-label"
              htmlFor="flexSwitchCheckChecked"
            >
              {settingsHeader[language].selfCreation}
            </label>
          </div>

          {/*<div className='card p-3'>*/}
          {/*    <h2>{settingsHeader[language].clientData}</h2>*/}
          {/*</div>*/}

          <button
            disabled={disabled || role !== "admin"}
            className="btn btn-primary"
            onClick={handleSubmit}
          >
            {editPass[language].submitButton}
          </button>
        </div>
      )}
    </>
  );
};
