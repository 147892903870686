import React, {useEffect, useState} from "react"
import axios from "../../utils/axiosConfig"
import {useNavigate, useLocation} from "react-router-dom";
import {
    settingsHeader,
    editPass,
    settingsSegment,
    alerts,
    userDisplay,
    cardsDisplay
} from "../../constants/translations"
import SettingsInput from "../../components/inputs/SettingsInput"
import SettingsSelect from "../../components/inputs/SettingsSelect"

export default () => {
    const language = localStorage.language
    const role = localStorage.role
    const navigate = useNavigate()
    const location = useLocation()
    const {segment, passSettings} = location.state

    const [isError, setIsError] = useState(false)
    const [isInfo, setIsInfo] = useState(false)
    const [alert, setAlert] = useState('Error')
    const [isLoading, setIsLoading] = useState(true)
    const [disabled, setDisabled] = useState(true)
    const [seg, setSeg] = useState(segment)
    const [segItem, setSegItem] = useState({})
    const [segItems, setSegItems] = useState([])
    const [operators, setOperators] = useState([])
    const [values, setValues] = useState([])

    const indicators = Object.keys(seg).filter(i => i !== '_id' && i !== '__v' && i !== 'organisationId' && i !== 'name' && i !== 'description')

    useEffect(() => {
        const segArr = []
        for (const indicator of indicators) {
            const operators = Object.keys(seg[indicator])
            if (typeof seg[indicator] === "object" && typeof seg[indicator] !== null && !Array.isArray(seg[indicator])){
                for (const operator of operators) {
                    if (seg[indicator][operator]) {
                        segArr.push({indicator, operator, value: seg[indicator][operator]})
                    }
                }
            } else if (Array.isArray(seg[indicator])) {
                for (const value of seg[indicator]) {
                    value && segArr.push({indicator, value})
                }
            } else seg[indicator] && segArr.push({indicator, value: seg[indicator]})
        }
        setSegItems(segArr)
    }, [])

    const emptySegment = {
        lastVisit: {},
        balance: {},
        discount: {},
        status: '',
        installed: '',
        gender: '',
        referrer: false,
        passTypeIdentifier: [],
        templateId: [],
        source: [],
        createdByUser: [],
        department: []
    }

    const handleChange = (event) => {
        setDisabled(passSettings?.marketing && false)
        let {name, value} = event.target
        if (name === 'name' || name === 'description') return setSeg({...seg, [name]: value})
        setSegItem({...segItem, [name]: value})
        if (name === 'indicator' && (value === 'lastVisit' || value === 'balance' || value === 'discount')) {
            setOperators(['less', 'more'])
            setValues([])
        } else if (name === 'indicator' && (value === 'passTypeIdentifier' || value === 'templateId')){
            setValues(passSettings[value])
            setOperators([])
        } else if (name === 'indicator' && (value === 'gender')){
            setValues(['m', 'f'])
            setOperators([])
        } else if (name === 'indicator' && (value === 'status')){
            setValues(['active', 'inactive'])
            setOperators([])
        } else if (name === 'indicator' && (value === 'installed')){
            setValues(['apple', 'android'])
            setOperators([])
        }else if (name === 'indicator' && (value === 'referrer')){
            setValues([true, false])
            setOperators([])
        } else if (name === 'indicator' && (value === 'source' || value === 'createdByUser' || value === 'department')) {
            setValues([])
            setOperators([])
        }

    }

    const handleDelete = el => {
        setSegItems(prevState => prevState.filter((i) => el !== i))
        setDisabled(false)

    }

    const handleAdd = () => {
        setSegItems(prevState => [...prevState, segItem])
        setSegItem({...segItem, indicator:'', operator:'', value:''})
        setValues([])
        setOperators([])
    }

    const handleSubmit = async () => {
        setIsLoading(true)
        setDisabled(true)

        if (seg._id) emptySegment._id = seg._id
        if (seg.name) emptySegment.name = seg.name
        if (seg.description) emptySegment.description = seg.description

        segItems.map(i => {
            if (i.indicator && i.operator && i.value) {
                emptySegment[i.indicator][i.operator] = i.value

            }
            else if (i.indicator && !i.operator && i.value){
                if (Array.isArray(emptySegment[i.indicator])){
                    !emptySegment[i.indicator].includes(i.value) && (emptySegment[i.indicator]).push(i.value)
                } else emptySegment[i.indicator] = i.value
            }
        })



        try {
            await axios.post('/api/marketing/segments', emptySegment)
                .then(response => {

                    if (response.status !== 200) return setIsLoading(false)
                    setIsLoading(false)
                    setIsInfo(true)
                    setAlert(alerts[language].success)
                    setTimeout(() => {
                        setIsInfo(false)
                        setDisabled(false)
                        navigate('/settings/marketing')
                    }, 2000)

                })
                .catch(e => {
                    console.log('ERRORR', e.response.status)
                    setIsLoading(false)

                    e.response.status === 401 && navigate('/user/login')
                    e.response.status === 402 && navigate('/402')
                    e.response.status === 403 && navigate('/403')
                })
        } catch (e) {
            console.error(e);
        }
    }

    const addedCriterions = segItems.map((i,index) => {
        return (
            <tr key={index}>
                    <th scope="row">{index + 1}</th>
                    <td>{settingsSegment[language][i.indicator]}</td>
                    <td>{settingsSegment[language][i.operator] || i.operator}</td>
                    <td>{settingsSegment[language][i.value] || i.value}</td>
                    <td>
                        <div className="d-grid mx-auto ">
                            <button
                                className='btn btn-sm btn-outline-danger'
                                onClick={() => handleDelete(i)}>
                                {cardsDisplay[language].delete}
                            </button>
                        </div>
                    </td>
            </tr>
        )
    })

    const indicatorOption = indicators.map((i, index) => <option key={index} value={i}>{settingsSegment[language][i] || i}</option>)
    const operatorOption = operators.map((i, index) => <option key={index} value={i}>{settingsSegment[language][i] || i}</option>)
    const valueOption = values.map((i, index) => <option key={index} value={i}>{settingsSegment[language][i] || i}</option>)


    return (<>
        {isInfo && (
            <div className="alert alert-info" role="alert">
                {alert}
            </div>
        )}
        {isError && (
            <div className="alert alert-danger" role="alert">
                {alert}
            </div>
        )}

        {passSettings &&
        <div className='card p-3 col-md-12 col-xl-9 m-auto mb-5 mt-5'>
            <div className="input-group-sm">
                <h4 className='mt-3'>{settingsHeader[language].segment}</h4>

                {<div className="input-group-sm mt-5">
                    <label htmlFor="name">{settingsSegment[language].name}</label>
                    <input
                        type="text"
                        className="form-control"
                        name='name'
                        id='name'
                        defaultValue={seg?.name}
                        onChange={handleChange}/>

                    <label htmlFor="description">{settingsSegment[language].description}</label>
                    <textarea
                        className="form-control"
                        name='description'
                        id='description'
                        defaultValue={seg?.description}
                        onChange={handleChange}/>


                    <table className="table table-sm fs-9 mt-5">
                        <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">{settingsSegment[language].indicator}</th>
                            <th scope="col">{settingsSegment[language].operator}</th>
                            <th scope="col">{settingsSegment[language].value}</th>
                            <th scope="col"></th>
                        </tr>
                        </thead>
                        <tbody>
                        {addedCriterions}
                        </tbody>
                    </table>


                    <div className="row mt-5">
                        <div className='col-4'>
                            {/*<SettingsSelect name='indicator' label='Indicator' options={indicatorOption}/>*/}
                            <label htmlFor="indicator">{settingsSegment[language].indicator}</label>
                            <select className="form-select" name='indicator' id='indicator' aria-label="indicator" onChange={handleChange} value={segItem.indicator}>
                                <option value=""></option>
                                {indicatorOption}
                            </select>
                        </div>

                        <div className='col-2'>
                            <label htmlFor="operator">{settingsSegment[language].operator}</label>
                            <select className="form-select" name='operator' id='operator' aria-label="operator" onChange={handleChange} value={segItem.operator} disabled={valueOption.length > 1}>
                                <option value=""></option>
                                {operatorOption}
                            </select>
                        </div>

                        {valueOption.length > 0 ?
                            <div className='col-4'>
                                <label htmlFor="value">{settingsSegment[language].value}</label>
                                <select className="form-select" name='value' id='value'
                                        aria-label="value"
                                        onChange={handleChange}>
                                    <option value=""></option>
                                    {valueOption}
                                </select>
                            </div> :
                            <div className='col-4'>
                                <label htmlFor="value">{settingsSegment[language].value}</label>
                                <input className='form-control form-control-sm m-0' required={true} name='value' id='value' aria-label="value" onChange={handleChange}/>
                            </div>
                        }

                        <button
                            className='col-2 btn btn-sm btn-outline-success'
                            disabled={!segItem.indicator || !segItem.value || ((segItem.indicator === 'lastVisit' || segItem.indicator === 'balance' || segItem.indicator === 'discount') && !segItem.operator)}
                            onClick={handleAdd}
                        >
                            {userDisplay[language].newUser}
                        </button>
                    </div>




                    <div className='float-end mt-5'>
                        <button
                            className='btn btn-outline-danger me-3'
                            onClick={() => navigate('/settings/marketing')}>
                            {editPass[language].goBack}
                        </button>
                        <button
                            className='btn btn-outline-primary'
                            disabled={disabled || !passSettings.marketing || !seg.name || role !== 'admin'}
                            onClick={handleSubmit}>
                            {editPass[language].submitButton}
                        </button>
                    </div>
                </div>}
            </div>

        </div>}
    </>)

}