import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import Resizer from "react-image-file-resizer";
import { MultiSelect } from "react-multi-select-component";
import "react-phone-input-2/lib/style.css";

import getBrowserLocales from "../utils/getBrowserLocales";
import { useNavigate, useLocation } from "react-router-dom";
import {
  alerts,
  createPass,
  editPass,
  header,
  multiselect,
} from "../constants/translations";
import axios from "../utils/axiosConfig";
import Header from "../layout/Header";
import UploadRegister from "./UploadRegister";

export default () => {
  if (!localStorage.language) {
    getBrowserLocales();
  }

  const language = localStorage.language;

  const navigate = useNavigate();

  const location = useLocation();
  const { card } = location.state;

  const [client, setClient] = useState(card);
  const [countryCode, setCountryCode] = useState("ua");
  const [isError, setIsError] = useState(false);
  const [isInfo, setIsInfo] = useState(false);
  const [alert, setAlert] = useState("Error");
  const [disabled, setDisabled] = useState(false);
  const [passSettings, setPassSettings] = useState({});
  const [formData, setFormData] = useState({});

  const [selectedFile, setSelectedFile] = useState(null);
  const [categories, setCategories] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState([]);

  useEffect(() => {
    // setClient(card)
    setCountryCode(localStorage.countryCode);
    // fetch('https://ipwhois.app/json/?objects=country_code')
    //     .then( res => res.json())
    //     .then(response => {
    //         setCountryCode(response?.country_code.toLowerCase())
    //     })
    //     .catch((data, status) => {
    //         console.error('Request failed:', data);
    //     })
    axios
      .get(`/api/passes/settings`)
      .then((res) => {
        if (!res.data) navigate("/error");
        setPassSettings(res.data);
        if (res.data?.category.length > 1) {
          res.data?.category?.map((category) => {
            setCategories((prevState) => [
              ...prevState,
              { label: category, value: category },
            ]);
          });
          Array?.isArray(card.category) &&
            card?.category?.map((category) => {
              setSelectedCategory((prevState) => [
                ...prevState,
                { label: category, value: category },
              ]);
            });
        }

        setFormData(res.data.formData);
      })
      .catch((e) => {
        console.error(e);
        e.response.status === 401 &&
          navigate({ pathname: "/user/login", search: "scan" });
        e.response.status === 404 && navigate("/error");
      });
  }, [navigate]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setClient({ ...client, [name]: value });
  };

  const handleImg = (event) => {
    let fileInput = false;
    if (event.target.files[0]) {
      fileInput = true;
      setClient({
        ...client,
        photo: `https://loyal.ws/api/v1/file/${
          client.passTypeIdentifier
        }/${client.serialNumber.replace(/[\W]/g, "")}/clientPhoto.jpeg`,
      });
    }
    if (fileInput) {
      try {
        Resizer.imageFileResizer(
          event.target.files[0],
          300,
          300,
          "JPEG",
          100,
          0,
          (uri) => {
            // console.log(uri);
            setSelectedFile(uri);
          },
          "file"
        );
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handlerSubmit = (event) => {
    event.preventDefault();
    setDisabled(true);
    setIsInfo(true);
    setAlert(alerts[language].successChanged);

    if (selectedCategory) {
      client.category = selectedCategory.map((el) => el.value);
    }

    const fd = new FormData();
    for (let key in client) {
      fd.append(key, client[key]);
    }
    if (selectedFile) {
      fd.append("file", selectedFile, "clientPhoto.jpeg");
    }

    axios
      .put(
        `/api/v1/passes/${client.passTypeIdentifier}/${client.serialNumber}/values`,
        fd,
        {
          onUploadProgress: (progressEvent) => {
            console.log(
              `Uploaded progress: ${progressEvent.loaded / progressEvent.total}`
            );
          },
        }
      )
      .then((response) => {
        if (response.status !== 200) return;
        setTimeout(() => {
          navigate("/cards");
        }, 3000);
      })
      .catch((e) => {
        console.log(e.response.status);
        e.response.status === 401 && navigate("/user/login");
        //todo to error page
        e.response.status === 402 && navigate("/402");
        e.response.status === 403 && navigate("/403");
      });
  };

  return (
    <div>
      {/*<Header/>*/}
      <h4 className={"text-primary"}>{header[language].cards}</h4>
      <hr className={"mt-0 mb-5"} />

      <div className="row justify-content-center mt-5">
        {isInfo && (
          <div className="alert alert-info" role="alert">
            {alert}
          </div>
        )}
        {isError && (
          <div className="alert alert-danger" role="alert">
            {alert}
          </div>
        )}
        <h4 className={"mt-5 mb-5"} style={{ textAlign: "center" }}>
          {editPass[language].title}
        </h4>

        <form className={"col-md-5"}>
          {formData.fullName && (
            <div className="mb-3">
              <label htmlFor="fullName">{editPass[language].fullName}</label>
              <input
                type="text"
                id="fullName"
                required
                name={"fullName"}
                value={client.fullName}
                onChange={handleChange}
              />
            </div>
          )}
          {formData.firstName && (
            <div className="mb-3">
              <label htmlFor="firstName">{editPass[language].firstName}</label>
              <input
                type="text"
                id="firstName"
                required
                name={"firstName"}
                value={client.firstName}
                onChange={handleChange}
              />
            </div>
          )}
          {formData.lastName && (
            <div className="mb-3">
              <label htmlFor="lastName">{editPass[language].lastName}</label>
              <input
                type="text"
                id="lastName"
                required
                name={"lastName"}
                value={client.lastName}
                onChange={handleChange}
              />
            </div>
          )}
          {formData.tel && (
            <div className="mb-3">
              <label htmlFor="tel">{editPass[language].tel}</label>
              <PhoneInput
                inputStyle={{
                  width: "100%",
                  height: "38px",
                  fontSize: "1rem",
                }}
                country={countryCode}
                id="tel"
                required
                value={client.tel}
                onChange={(tel) =>
                  setClient({ ...client, tel: tel.replace(/[^+\d]/g, "") })
                }
              />
            </div>
          )}
          {formData.birthday && (
            <div className="mb-3">
              <label htmlFor="birthday">{editPass[language].birthday}</label>
              <input
                type="date"
                id="birthday"
                required
                name={"birthday"}
                value={client.birthday}
                onChange={handleChange}
              />
            </div>
          )}
          {formData.email && (
            <div className="mb-3">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                required
                name={"email"}
                value={client.email}
                onChange={handleChange}
              />
            </div>
          )}
          <div className="mb-3">
            <label htmlFor="message">{editPass[language].message}</label>
            <textarea
              className="form-control"
              id="message"
              // required
              name={"message"}
              value={client.message}
              onChange={handleChange}
            />
            {/*<input*/}
            {/*    type="text"*/}
            {/*    id="message"*/}
            {/*    // required*/}
            {/*    name={"message"}*/}
            {/*    value={client.message}*/}
            {/*    onChange={handleChange}*/}
            {/*/>*/}
          </div>
          {passSettings.balance && !passSettings.integration && (
            <div className="mb-3">
              <label htmlFor="balance">{editPass[language].balance}</label>
              <input
                type="number"
                id="balance"
                // required
                name={"balance"}
                value={client.balance}
                onChange={handleChange}
              />
            </div>
          )}
          {passSettings?.loyaltyProgram?.discountSystem?.percent && (
            <div className="mb-3">
              <label htmlFor="discount">{editPass[language].discount}</label>
              <input
                type="number"
                id="discount"
                // required
                name={"discount"}
                value={client.discount}
                onChange={handleChange}
              />
            </div>
          )}
          {passSettings?.loyaltyProgram?.certificate?.templateId ===
            client?.templateId && (
            <div className="mb-3">
              <label htmlFor="certAmount">
                {editPass[language].certAmount}
              </label>
              <input
                type="number"
                id="certAmount"
                // required
                name={"certAmount"}
                value={client.certAmount}
                onChange={handleChange}
              />
            </div>
          )}
          {passSettings?.loyaltyProgram?.bonusSystem?.percent && (
            <div className="mb-3">
              <label htmlFor="bonus">{editPass[language].bonus}</label>
              <input
                type="number"
                id="bonus"
                // required
                name={"bonus"}
                value={client.bonus}
                onChange={handleChange}
              />
            </div>
          )}
          {(!passSettings.currentNumber ||
            passSettings.loyaltyProgram.scan ||
            passSettings.currentNumber === "empty" ||
            passSettings.formData?.memberId ||
            passSettings.formData?.customField?.key === "memberId") && (
            <div className="mb-3">
              <label htmlFor="memberId">{editPass[language].memberId}</label>
              <input
                type="text"
                id="memberId"
                // required
                name={"memberId"}
                value={client.memberId}
                onChange={handleChange}
              />
            </div>
          )}

          {/*=========*/}
          {formData?.city?.length === 1 && (
            <div className="mb-3">
              <label htmlFor="city">{editPass[language].city}</label>
              <input
                type="text"
                id="city"
                // required
                name={"city"}
                value={client.city}
                onChange={handleChange}
              />
            </div>
          )}
          {formData?.city?.length > 1 && (
            <div className="mb-3">
              <label htmlFor="city">{editPass[language].city}</label>
              <select
                className="form-select" //todo CHECK
                id="city"
                // required
                name={"city"}
                value={client.city}
                onChange={handleChange}
              >
                {formData?.city?.map((value, index) => (
                  <option key={index} value={value}>
                    {value}
                  </option>
                ))}
              </select>
            </div>
          )}
          {/*=========*/}

          {passSettings?.category?.length === 1 && (
            <div className="mb-3">
              <label htmlFor="category">{editPass[language].category}</label>
              <input
                type="text"
                id="category"
                // required
                name={"category"}
                value={client.category}
                onChange={handleChange}
              />
            </div>
          )}
          {/*{passSettings?.category?.length > 1 &&*/}
          {/*<div className="mb-3">*/}
          {/*    <label htmlFor="category">{editPass[language].category}</label>*/}
          {/*    <select*/}
          {/*        className="form-select" //todo CHECK*/}
          {/*        id="category"*/}
          {/*        multiple={false}*/}
          {/*        // required*/}
          {/*        name={"category"}*/}
          {/*        value={client.category}*/}
          {/*        onChange={handleChange}*/}
          {/*    >*/}
          {/*        {passSettings?.category?.map((value, index) => (*/}
          {/*            <option key={index} value={value}>{value}</option>*/}
          {/*        ))}*/}
          {/*    </select>*/}
          {/*</div>*/}
          {/*}*/}

          {categories.length > 1 && (
            <div className="mb-3">
              <label htmlFor="category">{editPass[language].category}</label>
              {/*<pre>{JSON.stringify(selectedCategory)}</pre>*/}
              <MultiSelect
                id={"category"}
                options={categories}
                overrideStrings={multiselect[language]}
                hasSelectAll={false}
                disableSearch={true}
                closeOnChangedValue={true}
                value={selectedCategory}
                onChange={setSelectedCategory}
              />
            </div>
          )}

          {passSettings?.templateId?.length > 1 && (
            <div className="mb-3">
              <label htmlFor="templateId">
                {editPass[language].templateId}
              </label>
              <select
                className="form-select" //todo CHECK
                id="templateId"
                // required
                name={"templateId"}
                value={client.templateId}
                onChange={handleChange}
              >
                {passSettings?.templateId?.map((value, index) => (
                  <option key={index} value={value}>
                    {value}
                  </option>
                ))}
              </select>
            </div>
          )}
          {passSettings.expirationDate && (
            <div className="mb-3">
              <label htmlFor="expirationDate">
                {editPass[language].expirationDate}
              </label>
              <input
                type="datetime-local"
                id="expirationDate"
                required
                name={"expirationDate"}
                value={client.expirationDate}
                onChange={handleChange}
              />
            </div>
          )}
          {passSettings.period && (
            <>
              <div className="mb-3">
                <label htmlFor="from">{createPass[language].from}</label>
                <input
                  type="text"
                  id="from"
                  required
                  name={"from"}
                  value={client.from}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="till">{createPass[language].till}</label>
                <input
                  type="text"
                  id="till"
                  required
                  name={"till"}
                  value={client.till}
                  onChange={handleChange}
                />
              </div>
            </>
          )}
          {passSettings.relevantDate && (
            <div className="mb-3">
              <label htmlFor="relevantDate">
                {editPass[language].relevantDate}
              </label>
              <input
                type="datetime-local"
                id="relevantDate"
                required
                name={"relevantDate"}
                value={client.relevantDate}
                onChange={handleChange}
              />
            </div>
          )}

          {passSettings?.photo && (
            <div className="mb-3">
              <label htmlFor="relevantDate">
                {editPass[language].uploadImg}
              </label>
              <input
                className={"btn btn-light btn-sm"}
                type={"file"}
                onChange={handleImg}
              />
            </div>
          )}

          <div className="d-grid mb-5">
            <button
              type="submit"
              className="btn btn-primary mt-3"
              disabled={disabled}
              onClick={handlerSubmit}
            >
              {editPass[language].submitButton}
            </button>
            <button
              className="btn btn-secondary mt-3"
              disabled={disabled}
              onClick={() => navigate("/cards")}
            >
              {editPass[language].goBack}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
