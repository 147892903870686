import React, {useState, useEffect} from "react"
import axios from "../utils/axiosConfig"
import {useNavigate} from "react-router-dom";
import Header from "../layout/Header";
import {settingsHeader, editPass, error, alerts, cardsDisplay, header, swal} from "../constants/translations"
import SettingsHeader from "../layout/SettingsHeader";
import Swal from 'sweetalert2'

export default () => {
    const language = localStorage.language
    const role = localStorage.role
    const [isError, setIsError] = useState(false)
    const [isInfo, setIsInfo] = useState(false)
    const [alert, setAlert] = useState('Error')
    const [disabled, setDisabled] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const navigate = useNavigate()
    const [templateIds, setTemplateIds] = useState([])
    const [bot, setBot] = useState({templateId: '', botName: '', token: ''})

    useEffect(() => {
        axios.get('/api/passes/settings')
            .then(response => {
                if (response.status !== 200) return setIsLoading(false)
                setTemplateIds(response.data.templateId)
                setBot({...bot, templateId: response.data.templateId[0]})

                axios.get('/api/bots/telegram')
                    .then(response => {
                        if (response.status !== 200) return setIsLoading(false)
                        response.data.botName && response.data.token && setBot(response.data)
                        setIsLoading(false)

                        if(response.data?.test || !response.data?.telegram){
                            Swal.fire({
                                title: 'Telegram',
                                text: swal[language].demoTelegramSettingsText,
                                confirmButtonText: 'OK',
                                confirmButtonColor: "#0d6efd"
                            })
                        }

                    })
                    .catch(e => {
                        console.log('ERRORR', e.response.status)
                        setIsLoading(false)
                        e.response.status === 401 && navigate('/user/login')
                        e.response.status === 402 && navigate('/402')
                        e.response.status === 403 && navigate('/403')
                        // e.response.status === 404 && setPassSettings([])
                    })

            })
            .catch(e => {
                console.log('ERRORR', e.response.status)
                setIsLoading(false)
                e.response.status === 401 && navigate('/user/login')
                e.response.status === 402 && navigate('/402')
                e.response.status === 403 && navigate('/403')
                e.response.status === 404 && setBot({})
            })
    }, [])

    const handleChange = (event) => {
        const {name, value} = event.target
        setBot({...bot, [name]: value})
    }

    const handleDelete = async (_id) => {
        console.log(_id);
        setIsLoading(true)
        setDisabled(true)
        try {
            await axios.delete('/api/bots/telegram', {data: {_id}})
                .then(response => {

                    if (response.status !== 200) return setIsLoading(false)
                    setIsLoading(false)
                    setIsInfo(true)
                    setAlert(alerts[language].success)
                    setBot({templateId: templateIds[0]})
                    setTimeout(() => {
                        setIsInfo(false)
                        setDisabled(false)
                    }, 2000)

                })
                .catch(e => {
                    console.log('ERRORR', e.response.status)
                    setIsLoading(false)

                    e.response.status === 401 && navigate('/user/login')
                    e.response.status === 402 && navigate('/402')
                    e.response.status === 403 && navigate('/403')
                })
        } catch (e) {
            console.error(e);
        }
    }

    const handleSubmit = async () => {
        setIsLoading(true)
        setDisabled(true)
        try {
            await axios.post('/api/bots/telegram', bot)
                .then(response => {

                    if (response.status !== 200) return setIsLoading(false)
                    setIsLoading(false)
                    setIsInfo(true)
                    setAlert(alerts[language].success)
                    setTimeout(() => {
                        setIsInfo(false)
                        setDisabled(false)
                    }, 2000)

                })
                .catch(e => {
                    console.log('ERRORR', e.response.status)
                    setIsLoading(false)

                    e.response.status === 401 && navigate('/user/login')
                    e.response.status === 402 && navigate('/402')
                    e.response.status === 403 && navigate('/403')
                })
        } catch (e) {
            console.error(e);
        }
    }

    // console.log(bot);

    return (
        <>
            {isInfo && (
                <div className="alert alert-info" role="alert">
                    {alert}
                </div>
            )}
            {isError && (
                <div className="alert alert-danger" role="alert">
                    {alert}
                </div>
            )}

            <h4 className={"text-primary"}>{header[language].settings}</h4>
            <hr className={"mt-0 mb-0"} />
            <SettingsHeader/>
            {bot &&
            <div className='card p-3 col-md-6 col-xl-4 m-auto mb-5'>
                <h4 className='mt-3'>Bot Settings</h4>

                <div className="">
                    {templateIds.length > 1 && <>
                        <label htmlFor="templateId">Template ID</label>
                        <input className="form-control"
                               type="text"
                               id="templateId"
                               name="templateId"
                               defaultValue={bot && bot.templateId}
                               onChange={handleChange}
                        />
                    </>
                    }
                    <label htmlFor="botName">Bot Name</label>
                    <input className="form-control"
                           type="text"
                           id="botName"
                           name="botName"
                           defaultValue={bot && bot.botName}
                           onChange={handleChange}
                    />
                    <label htmlFor="token">Token</label>
                    <input className="form-control"
                           type="text"
                           id="token"
                           name="token"
                           defaultValue={bot && bot.token}
                           onChange={handleChange}
                    />
                </div>

                <button
                    disabled={disabled || !bot || !bot.token || role !== 'admin'}
                    className='btn btn-outline-danger mt-3'
                    onClick={() => handleDelete(bot._id)}>
                    {cardsDisplay[language].delete}
                </button>
                <button
                    disabled={disabled || !bot || !bot.token || role !== 'admin'}
                    className='btn btn-outline-primary'
                    onClick={handleSubmit}>
                    {editPass[language].submitButton}
                </button>
            </div>}



        </>
    )
}