import React, {useState, useEffect} from "react"
import axios from "../utils/axiosConfig"
import XLSX from 'xlsx'
import {useNavigate} from "react-router-dom";
import UploadRegister from "./UploadRegister";
import Header from "../layout/Header";
import {header, registerDisplay} from "../constants/translations"
import { ThreeDots } from  'react-loader-spinner'

export default () => {
    const language = localStorage.language

    const [register, setRegister] = useState([])
    const [uploaded, setUploaded] = useState(0)
    const [isLoading, setIsLoading] = useState(true)

    const navigate = useNavigate()

    useEffect( () => {
        axios
            .get('/api/passes/register?qty=10')
            .then(response => {
                if (response.status !== 200) return setIsLoading(false)
                setRegister(response.data)
                setIsLoading(false)
            })
            .catch(e => {
                console.log(e.response.status)
                e.response.status === 401 && navigate('/user/login')
                //todo to error page
                e.response.status === 402 && navigate('/402')
                e.response.status === 403 && navigate('/403')
                e.response.status === 404 && setRegister([])
                setIsLoading(false)
            })
    }, [uploaded])

    const getRegister = (period) => {
        setIsLoading(true)
        if (!period.start || !period.end){
            axios
                .get(`/api/passes/register?qty=10`)
                .then(response => {
                    if (response.status === 200) {
                        setRegister(response.data)
                        setIsLoading(false)
                    }
                })
                .catch(e => {
                    console.log(e.response.status)

                    if (e.response.status === 401) {
                        navigate('/user/login')
                    } else if (e.response.status === 402) {
                        navigate('/402')
                    } else if (e.response.status === 403) {
                        navigate('/403')
                    } else if (e.response.status === 404) {
                        setRegister([])
                        setIsLoading(false)
                    } else {
                        navigate('/error')
                    }

                })
        } else {
            axios
                .get(`/api/passes/register?since=${period.start}&till=${period.end}`)
                .then(response => {
                    response.status === 200 && setRegister(response.data)
                    response.status === 204 && setRegister([])
                    setIsLoading(false)
                })
                .catch(e => {
                    console.log(e.response.status)

                    if (e.response.status === 401) {
                        navigate('/user/login')
                    } else if (e.response.status === 402) {
                        navigate('/402')
                    } else if (e.response.status === 403) {
                        navigate('/403')
                    } else if (e.response.status === 404) {
                        setRegister([])
                        setIsLoading(false)
                    } else {
                        navigate('/error')
                    }

                })
        }
    }

    const handleChange = e => {
        const {name, value} = e.target

        const currentMonth = (new Date).getMonth() + 1
        const currentYear = (new Date).getFullYear()
        if (name === 'period' && value === 'current'){
            const start = Date.parse(currentYear + "-" + ('0'+currentMonth).slice(-2) + "-01")
            const end = Date.now()
            getRegister({start, end})
        } else if (name === 'period' && value === 'previous'){
            let previousMonth = currentMonth - 1
            let previousYear = currentYear
            if (currentMonth === 1) {
                previousMonth = 12
                previousYear -= 1
            }
            const start = Date.parse(previousYear + "-" + ('0'+previousMonth).slice(-2) + "-01")
            const end = Date.parse(currentYear + "-" + ('0'+currentMonth).slice(-2) + "-01")
            getRegister({start, end})
        } else {
            getRegister({})
        }
    }


    const handleClick = (register) => {
        const data = register.cards.map(el => {
            delete el._id
            return el
        })
        const fileName = (new Date(+register.date)).toLocaleString().replace( /[, .:]/g, '_')
        const ws = XLSX.utils.json_to_sheet(data)
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, 'register')
        XLSX.writeFile(wb, `${fileName}.xlsx`)
    }

    const tableBody = register.map(item => {
            let date = new Date(+item.date)
            date = date.toLocaleString()
            const created = item.cards.filter(el => el.status === 'created').length
            const updated = item.cards.filter(el => el.status === 'updated').length
            const deleted = item.cards.filter(el => el.status === 'deleted').length
            const total = created + updated + deleted
            return (
                <tr key={item._id}>
                    <th>{date}</th>
                    <td>{item.department}</td>
                    <td>{item.email}</td>
                    <td>{created}</td>
                    <td>{updated}</td>
                    <td>{deleted}</td>
                    <td>{total}</td>
                    <td>
                        <button
                            style={{width: '100%'}}
                            className={'btn btn-outline-secondary btn-sm'}
                            onClick={() => handleClick(item)}
                        >
                            {registerDisplay[language].download}
                        </button>
                    </td>
                </tr>
            )
        }
    )


    return (
        <div>
            {/*<Header />*/}
            <h4 className={"text-primary"}>{header[language].registers}</h4>
            <hr className={"mt-0 mb-5"} />

            <UploadRegister uploaded={uploaded => setUploaded(prevState => prevState+uploaded)} />

            <div className="input-group mt-5" >
                <span className="input-group-text">{registerDisplay[language].period}</span>
                <select className="form-select" id={"passType"} name={"period"}  onChange={handleChange}>
                    <option key={0} value={''}>...</option>
                    <option key={1} value={'current'}>{registerDisplay[language].currentMonth}</option>
                    <option key={2} value={'previous'}>{registerDisplay[language].lastMonth}</option>
                    {/*{passTypeIds.map((item, index) => (<option key={index} value={item}>{item}</option>))}*/}
                </select>
            </div>

            {isLoading ? <ThreeDots
                    height="80"
                    width="80"
                    radius="9"
                    color="#0d6efd"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                /> :
                <div className="table-responsive">
                    <table className="table mt-5">
                        <thead>
                        <tr>
                            <th>{registerDisplay[language].date}</th>
                            <th>{registerDisplay[language].department}</th>
                            <th>{registerDisplay[language].user}</th>
                            <th>{registerDisplay[language].issued}</th>
                            <th>{registerDisplay[language].updated}</th>
                            <th>{registerDisplay[language].deleted}</th>
                            <th>{registerDisplay[language].total}</th>
                            <th></th>
                        </tr>
                        </thead>

                        <tbody>
                        {tableBody}
                        </tbody>

                    </table>
                </div>}

        </div>
    )
}
