import { useEffect, useState } from "react";
import "./quiz.scss";
import getBrowserLocales from "../utils/getBrowserLocales";
import { translations } from "./translation";
import Radio from "./Radio";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import ReactPixel from "react-facebook-pixel";

export default function Quiz() {
  const stepTotal = 5;

  if (!localStorage.language) {
    getBrowserLocales();
  }
  const language = localStorage.language;

  const [countryCode, setCountryCode] = useState("ua");
  const [step, setStep] = useState(0);
  const [disabled, setDisabled] = useState(false);
  const [client, setClient] = useState({
    countryCode,
    language,
    businessType: "",
    pos: "",
    loyaltyType: "",
    clientIdentifyBy: "",
    name: "",
    tel: "",
    readPrivacy: false,
    date: new Date().toLocaleString("uk-UA"),
    comment: "",
  });

  useEffect(() => {
    ReactPixel.init("301893265039096", {
      autoConfig: true, // автоматична конфігурація параметрів пікселя
      debug: false, // режим налагодження (включити для виведення логів в консоль)
    });
    ReactPixel.pageView();

    axios
      .get(`https://ipwhois.app/json/?objects=country_code,message`)
      .then((response) => {
        if (response.data.message) {
          console.error("ERROR: ", response.data.message);
        }
        setCountryCode(response?.data?.country_code?.toLowerCase() ?? "ua");
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);

  useEffect(() => {
    if (step === 0) {
      setDisabled(false);
    }
    if (step === 5) {
      setDisabled(true);
      if (
        client.name?.length > 2 &&
        client.tel?.length >= 11 &&
        client.readPrivacy
      ) {
        setDisabled(false);
      }
    }
  }, [step, client]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    type === "checkbox"
      ? setClient({ ...client, [name]: checked })
      : setClient({
          ...client,
          [name]: value?.replace(/[^\p{L}\s,\/()1']/gu, ""),
        });
  };

  const handleSubmit = async () => {
    setStep((prevStep) => prevStep + 1);
    try {
      await axios.post("/api/pass/quiz", client);
      ReactPixel.track("Lead");
    } catch (error) {
      console.log(error);
    }
  };

  let body = (
    <>
      <div>
        <span className="label">{translations[language].startHeader}</span>
        <span className="text">{translations[language].startText}</span>
      </div>
      <img src="/img/cards1.png" alt="Cards" />
    </>
  );

  if (step === 1) {
    body = (
      <>
        <span className="question">{translations[language].question1}</span>
        <div style={{ fontSize: "1.25rem", textAlign: "left" }}>
          <Radio
            handleChange={handleChange}
            client={client}
            setDisabled={setDisabled}
            data={[
              {
                name: "businessType",
                value: translations[language].response1_1,
              },
              {
                name: "businessType",
                value: translations[language].response1_2,
              },
              {
                name: "businessType",
                value: translations[language].response1_3,
              },
              {
                name: "businessType",
                value: translations[language].response1_4,
              },
              {
                name: "businessType",
                value: translations[language].response1_5,
              },
              // {
              //   name: "businessType",
              //   value: translations[language].response1_6,
              // },
              {
                name: "businessType",
                value: translations[language].response1_7,
              },
              // {
              //   name: "businessType",
              //   value: translations[language].response1_8,
              // },
            ]}
          />
          <input
            type="text"
            name="businessType"
            value={client.businessType}
            placeholder={translations[language].response1_8}
            onChange={handleChange}
          />
        </div>
      </>
    );
  }
  if (step === 2) {
    body = (
      <>
        <span className="question">{translations[language].question2}</span>
        <div style={{ fontSize: "1.25rem", textAlign: "left" }}>
          <Radio
            handleChange={handleChange}
            client={client}
            setDisabled={setDisabled}
            data={[
              { name: "pos", value: translations[language].response2_1 },
              { name: "pos", value: translations[language].response2_2 },
              { name: "pos", value: translations[language].response2_3 },
              { name: "pos", value: translations[language].response2_4 },
              { name: "pos", value: translations[language].response2_5 },
              { name: "pos", value: translations[language].response2_6 },
              { name: "pos", value: translations[language].response2_7 },
              { name: "pos", value: translations[language].response2_8 },
              { name: "pos", value: translations[language].response2_9 },
              // { name: "pos", value: translations[language].response2_10 },
            ]}
          />
          <input
            type="text"
            name="pos"
            value={client.pos}
            placeholder={translations[language].response1_8}
            onChange={handleChange}
          />
        </div>
      </>
    );
  }
  if (step === 3) {
    body = (
      <>
        <span className="question">{translations[language].question3}</span>
        <div style={{ fontSize: "1.25rem", textAlign: "left" }}>
          <Radio
            handleChange={handleChange}
            client={client}
            setDisabled={setDisabled}
            data={[
              {
                name: "loyaltyType",
                value: translations[language].response3_1,
              },
              {
                name: "loyaltyType",
                value: translations[language].response3_2,
              },
              {
                name: "loyaltyType",
                value: translations[language].response3_3,
              },
              {
                name: "loyaltyType",
                value: translations[language].response3_4,
              },
              // {
              //   name: "loyaltyType",
              //   value: translations[language].response3_5,
              // },
            ]}
          />
          <input
            type="text"
            name="loyaltyType"
            value={client.loyaltyType}
            placeholder={translations[language].response1_8}
            onChange={handleChange}
          />
        </div>
      </>
    );
  }
  if (step === 4) {
    body = (
      <>
        <span className="question">{translations[language].question4}</span>
        <div style={{ fontSize: "1.25rem", textAlign: "left" }}>
          <Radio
            handleChange={handleChange}
            client={client}
            setDisabled={setDisabled}
            data={[
              {
                name: "clientIdentifyBy",
                value: translations[language].response4_1,
              },
              {
                name: "clientIdentifyBy",
                value: translations[language].response4_2,
              },
              {
                name: "clientIdentifyBy",
                value: translations[language].response4_3,
              },
              {
                name: "clientIdentifyBy",
                value: translations[language].response4_4,
              },
              // {
              //   name: "clientIdentifyBy",
              //   value: translations[language].response4_5,
              // },
            ]}
          />
          <input
            type="text"
            name="clientIdentifyBy"
            value={client.clientIdentifyBy}
            placeholder={translations[language].response1_8}
            onChange={handleChange}
          />
        </div>
      </>
    );
  }
  if (step === 5) {
    body = (
      <>
        <span className="question">{translations[language].question5}</span>
        <div style={{ fontSize: "1.25rem", textAlign: "left" }}>
          <input
            type="text"
            name={"name"}
            placeholder={translations[language].name}
            onChange={handleChange}
          />
          <PhoneInput
            inputStyle={{ width: "100%", height: "50px", fontSize: "1.25rem" }}
            type={"tel"}
            country={countryCode}
            id="tel"
            // autoComplete={"nope"}
            required
            onChange={(tel) =>
              setClient({ ...client, tel: tel.replace(/[^+\d]/g, "") })
            }
          />
          <div className="form-check">
            <input
              type="checkbox"
              id={"privacy"}
              name={"readPrivacy"}
              className="form-check-input"
              onChange={handleChange}
            />
            <label className="form-check-label" htmlFor={"privacy"}>
              {translations[language].confirm}
              <a
                href={`https://loyalcards.com.ua/${language}/privacy-policy.html`}
              >
                {translations[language].confirmLink}
              </a>
            </label>
          </div>
        </div>
        <button disabled={disabled} onClick={() => handleSubmit()}>
          {translations[language].submit}
        </button>
      </>
    );
  }
  if (step === 6) {
    body = (
      <>
        <span className="label">{translations[language].thank}</span>
        <span className="text">{translations[language].staffWillContact}</span>
        <span className="text">
          {translations[language].visitSite}{" "}
          <a href={`https://loyalcards.com.ua/${language}/`}>
            loyalcards.com.ua
          </a>
        </span>
      </>
    );
  }

  return (
    <div className="quiz">
      <div className="grid-container">
        <div className="header">
          {step === 0 || step > stepTotal ? (
            <img
              src="/img/logo-wide.png"
              alt="loyalCards logo"
              height={"60px"}
            />
          ) : (
            <span>
              {translations[language].question} {step}{" "}
              {translations[language].from} {stepTotal}
            </span>
            // <span>{`Вопрос ${step} из ${stepTotal}`}</span>
          )}
          {step !== 0 && step <= stepTotal && (
            <progress style={{ width: "100%" }} value={step / stepTotal} />
          )}
        </div>

        <div className="body">{body}</div>

        <div className="footer">
          <div className="btn-group">
            <button onClick={() => setStep((prevStep) => prevStep - 1)}>
              {step !== 0 && step <= stepTotal
                ? translations[language].back
                : ""}
            </button>
            {step < stepTotal && (
              <button
                disabled={disabled}
                onClick={() => setStep((prevStep) => prevStep + 1)}
              >
                {step === 0
                  ? translations[language].start
                  : translations[language].next}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
