import React, { useState, useEffect } from "react";
import axios from "../utils/axiosConfig";
import CopyToClipboard from "react-copy-to-clipboard";
import QRCode from "react-qr-code";
import Modal from "react-modal";
import { useNavigate, Link } from "react-router-dom";
import Header from "../layout/Header";
import { alerts, cardsDisplay, header } from "../constants/translations";
import XLSX from "xlsx";
import "react-phone-input-2/lib/style.css";

import android from "../img/icons/android@2x.png";
import androidActive from "../img/icons/android_active@2x.png";
import apple from "../img/icons/apple@2x.png";
import appleActive from "../img/icons/apple_active@2x.png";

import { ThreeDots } from "react-loader-spinner";

export default () => {
  const language = localStorage.language;

  const [cards, setCards] = useState([]);
  const [receivedCards, setReceivedCards] = useState([]);
  const [passSettings, setPassSettings] = useState({});
  const [totalPass, setTotalPass] = useState(0);
  const [qty, setQty] = useState(100);
  const [copied, setCopied] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [passTypeIds, setPassTypeIds] = useState([]);
  const [passTypeId, setPassTypeId] = useState("");
  const [modalStyle, setModalStyle] = useState({});
  const [modalData, setModalData] = useState();
  const [isError, setIsError] = useState(false);
  const [isInfo, setIsInfo] = useState(false);
  const [alert, setAlert] = useState("Error");
  const [isLoading, setIsLoading] = useState(true);
  const [searchField, setSearchField] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get("/api/passes/passes?qty=100")
      .then((response) => {
        if (response.status !== 200) return setIsLoading(false);
        setReceivedCards(response.data);
        setCards(response.data);
        axios
          .get("/api/passes/settings")
          .then((response) => {
            setPassSettings(response.data);
            setPassTypeIds(response.data.passTypeIdentifier);
            // response.data?.language && (localStorage.language = response.data.language)
            // setPassTypeId(response.data.passTypeIdentifier[0])
          })
          .catch((err) => console.log(err));
        setIsLoading(false);
      })
      .catch((e) => {
        console.log("ERRORR", e.response.status);
        setIsLoading(false);
        e.response.status === 401 && navigate("/user/login");
        e.response.status === 402 && navigate("/402");
        //todo to error page
        e.response.status === 403 && navigate("/403");
        e.response.status === 404 && setCards([]);
      });
  }, [navigate]);

  useEffect(() => {
    passTypeId === ""
      ? setCards(receivedCards)
      : setCards(
          receivedCards.filter((item) => item.passTypeIdentifier === passTypeId)
        );
  }, [passTypeId]);

  useEffect(() => {
    axios
      .get("/api/v1/passes/qty")
      .then((res) => {
        if (res.status === 200) setTotalPass(res.data.qty);
      })
      .catch((e) => {
        console.log(e.response.status);
        e.response.status === 401 && navigate("/user/login");
        e.response.status === 402 && navigate("/402");
        //todo to error page
        e.response.status === 403 && navigate("/403");
        e.response.status === 404 && setCards([]);
      });
  }, []);

  const handleChange = (event) => {
    let { name, value } = event.target;

    if (name === "search") {
      if (typeof value === "string") {
        value = value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
      }
      const filteredCards = receivedCards.filter(
        (item) =>
          (item?.placeholders?.memberId &&
            item?.placeholders?.memberId.includes(value)) ||
          (item?.placeholders?.tel &&
            item?.placeholders?.tel.includes(value)) ||
          (item?.placeholders?.fullName &&
            item?.placeholders?.fullName.includes(value)) ||
          (item?.placeholders?.firstName &&
            item?.placeholders?.firstName.includes(value)) ||
          (item?.placeholders?.lastName &&
            item?.placeholders?.lastName.includes(value))
      );

      // TODO Search in db
      if (filteredCards.length < 1) setSearchField(value);

      setCards(filteredCards);
    }

    if (name === "passTypeId") {
      setPassTypeId(value);
    }
  };

  const receiveQtyCards = (qty) => {
    setQty(qty);

    setIsLoading(true);
    // setIsInfo(true)
    // setAlert(alerts[language].successDeleted)

    axios
      .get(`/api/passes/passes?qty=${qty}`)
      .then((response) => {
        if (response.status !== 200) return setIsLoading(false);
        setReceivedCards(response.data);
        setCards(response.data);
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e.response.status);
        e.response.status === 401 && navigate("/user/login");
        //todo to error page
        e.response.status === 402 && navigate("/402");
        e.response.status === 403 && navigate("/403");
        e.response.status === 404 && setCards([]);
        setIsLoading(false);
      });
  };

  const handleSearchCard = (searchValue) => {
    console.log(searchValue);
    //todo search in DB
    setIsLoading(true);
    axios
      .get(`/api/passes/passes?find=${searchValue}`)
      .then((response) => {
        if (response.status !== 200) return setIsLoading(false);
        setCards(response.data);
        setIsLoading(false);
      })
      .catch((e) => {
        console.log("ERRORR", e.response.status);
        setIsLoading(false);
        e.response.status === 401 && navigate("/user/login");
        e.response.status === 402 && navigate("/402");
        //todo to error page
        e.response.status === 403 && navigate("/403");
        e.response.status === 404 && setCards([]);
      });
  };

  const showQr = (url) => {
    setIsOpen(true);
    setModalStyle({
      overlay: {
        zIndex: 2,
        backgroundColor: "rgba(0, 0, 0, .8)",
      },
      content: {
        height: "296px",
        width: "296px",
        margin: "auto",
        padding: "20px",
      },
    });
    setModalData(<QRCode value={url} />);
  };

  const showPhoto = (url) => {
    setIsOpen(true);
    setModalStyle({
      overlay: {
        zIndex: 2,
        backgroundColor: "rgba(0, 0, 0, .8)",
      },
      content: {
        maxHeight: "320px",
        maxWidth: "320px",
        margin: "auto",
        padding: "10px",
      },
    });
    setModalData(<img src={url} alt="hello" />);
  };

  const deletePass = (passTypeIdentifier, serialNumber, _id) => {
    axios
      .delete(`/api/v1/passes/${passTypeIdentifier}/${serialNumber}`)
      .then((response) => {
        if (response.status !== 200) return;
        const newCardsArray = receivedCards.filter((item) => item._id !== _id);
        setReceivedCards(newCardsArray);
        setCards(newCardsArray);
        setTotalPass((prevState) => prevState - 1);

        setIsInfo(true);
        setAlert(alerts[language].successDeleted);
        setTimeout(() => {
          setIsInfo(false);
        }, 2000);
      })
      .catch((e) => {
        console.log(e.response.status);
        e.response.status === 401 && navigate("/user/login");
        //todo to error page
        e.response.status === 402 && navigate("/402");
        e.response.status === 403 && navigate("/403");
        e.response.status === 404 && setCards([]);
      });
  };

  const downloadRegister = () => {
    const data = cards.map((el) => {
      // delete el._id
      el.placeholders.status = el.status;
      return el.placeholders;
    });
    const fileName = new Date().toLocaleString().replace(/[, .:]/g, "_");
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "register");
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  };

  const editCard = (card) => {
    navigate("/cards/edit", { state: card });
  };

  const devIcon = (card) => {
    let androidIcon = "",
      appleIcon = "";
    if (card.created.gpass && !card.installed.android) {
      androidIcon = android;
    } else if (card.installed.android) {
      androidIcon = androidActive;
    }
    if (card.created.pass && !card.installed.apple) {
      appleIcon = apple;
    } else if (card.installed.apple) {
      appleIcon = appleActive;
    }
    return (
      <>
        {appleIcon && (
          <img src={appleIcon} width={"24"} height={"24"} alt="iOS icon" />
        )}
        {androidIcon && (
          <img
            src={androidIcon}
            width={"24"}
            height={"24"}
            alt="Android icon"
          />
        )}
      </>
    );
  };

  const tableBody = cards.map((item, index) => {
    let fullName = item.placeholders.fullName;
    if (!fullName)
      fullName = `${item.placeholders.firstName} ${item.placeholders.lastName}`;
    let userInfo = item.placeholders.userInfo;
    if (!userInfo) userInfo = {};
    let createdAt = new Date(item.createdAt) || "";
    let updatedAt = new Date(item.updatedAt) || "";
    if (createdAt) createdAt = createdAt.toLocaleDateString();
    if (updatedAt) updatedAt = updatedAt.toLocaleDateString();
    const colors = [
      "#212529",
      "#787276",
      "#88807B",
      "#999DA0",
      "#D9DDDC",
      "#D6CFC7",
      "#BDB7AB",
    ];

    return (
      <tr
        key={index}
        style={{
          color:
            colors[
              passSettings?.templateId?.indexOf(item?.placeholders?.templateId)
            ] ?? "#212529",
        }}
      >
        <td>{createdAt}</td>
        <td>{updatedAt}</td>
        <td>{fullName}</td>
        <td>{item.placeholders.memberId}</td>
        {passSettings?.selfCreation && passSettings?.formData?.tel && (
          <td>{item.placeholders.tel}</td>
        )}
        {passSettings?.selfCreation && passSettings?.formData?.birthday && (
          <td>{new Date(item.placeholders.birthday).toLocaleDateString()}</td>
        )}
        {passSettings?.selfCreation && passSettings?.formData?.email && (
          <td>{item.placeholders.email}</td>
        )}
        {passSettings?.loyaltyProgram?.active &&
          (passSettings?.balance || passSettings?.discount) &&
          (passSettings?.balance ? (
            <td>
              {passSettings.integration ? (
                <Link
                  to={`/transaction/${item.passTypeIdentifier}/${item.serialNumber}`}
                >
                  {item.placeholders.balance}
                </Link>
              ) : (
                // item.placeholders.balance :
                <Link
                  to={`/scan/${item.passTypeIdentifier}/${item.serialNumber}`}
                >
                  {item.placeholders.balance}
                </Link>
              )}
            </td>
          ) : (
            <td>{item.placeholders.discount}</td>
          ))}

        {/*PHOTO*/}
        {passSettings?.photo &&
          (item.placeholders.photo ? (
            <td>
              <button
                className={"btn btn-outline-secondary btn-sm"}
                onClick={() => showPhoto(item.placeholders.photo)}
              >
                &#10004;
              </button>
            </td>
          ) : (
            <td></td>
          ))}

        {/*<td>{devIcon(item)}</td>*/}
        <td>
          {passSettings.marketing ? (
            devIcon(item)
          ) : (
            <>
              <img src={apple} width={"24"} height={"24"} alt="iOS icon" />
              <img
                src={android}
                width={"24"}
                height={"24"}
                alt="Android icon"
              />
            </>
          )}
        </td>
        <td>
          <div className="btn-toolbar justify-content-space-between">
            <div style={{ width: "100%" }} className="btn-group" role="group">
              <CopyToClipboard
                text={item.url}
                onCopy={() => setCopied(item._id)}
              >
                <button
                  className={
                    "btn btn-outline-secondary btn-sm" +
                    (item._id === copied ? " active" : "")
                  }
                >
                  Copy
                </button>
              </CopyToClipboard>
              <button
                className={"btn btn-outline-secondary btn-sm"}
                onClick={() => showQr(item.url)}
              >
                QR
              </button>
            </div>
          </div>
        </td>
        <td>
          {/*<Link to={{pathname: '/cards/edit', state: {card: item.placeholders} }}> </Link>*/}
          <button
            style={{ width: "100%" }}
            className={"btn btn-outline-secondary btn-sm"}
            onClick={() => editCard({ card: item.placeholders })}
          >
            {cardsDisplay[language].edit}
          </button>
        </td>
        <td>
          <button
            style={{ width: "100%" }}
            className={"btn btn-outline-danger btn-sm float-end"}
            onClick={() =>
              deletePass(item.passTypeIdentifier, item.serialNumber, item._id)
            }
          >
            {cardsDisplay[language].delete}
          </button>
        </td>
      </tr>
    );
  });

  return (
    <div>
      {/*<Header />*/}
      {/*<Sidebar/>*/}
      <h4 className={"text-primary"}>{header[language].cards}</h4>
      <hr className={"mt-0 mb-5"} />

      {isInfo && (
        <div className="alert alert-info" role="alert">
          {alert}
        </div>
      )}
      {isError && (
        <div className="alert alert-danger" role="alert">
          {alert}
        </div>
      )}

      <div>
        <div className="btn-toolbar justify-content-between" role="toolbar">
          {passTypeIds.length > 1 && (
            <div className="input-group mb-3">
              <span className="input-group-text">Pass Type Id</span>
              <select
                className="form-select"
                id={"passType"}
                name={"passTypeId"}
                onChange={handleChange}
              >
                <option value={""}>...</option>
                {passTypeIds.map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </div>
          )}
          <div className="input-group mb-3">
            <span className="input-group-text">
              {cardsDisplay[language].search}
            </span>
            <input
              type="text"
              className="form-control m-0"
              placeholder={
                cardsDisplay[language].tel +
                " / " +
                cardsDisplay[language].name +
                " / " +
                cardsDisplay[language].cardNumber
              }
              name={"search"}
              onChange={handleChange}
            />
            {/*    TODO If not found - find in DB*/}
            {cards.length === 0 && searchField?.length > 3 && (
              <button
                className="btn btn-outline-secondary"
                type="button"
                onClick={() => handleSearchCard(searchField)}
              >
                &#128269;
              </button>
            )}
          </div>
          <div className="btn-group mb-3">
            {totalPass > 100 && (
              <button
                type="button"
                className={
                  "btn btn-outline-secondary" + (100 === qty ? " active" : "")
                }
                onClick={() => receiveQtyCards(100)}
              >
                100
              </button>
            )}
            {totalPass > 500 && (
              <button
                type="button"
                className={
                  "btn btn-outline-secondary" + (500 === qty ? " active" : "")
                }
                onClick={() => receiveQtyCards(500)}
              >
                500
              </button>
            )}
            {totalPass > 1000 && (
              <button
                type="button"
                className={
                  "btn btn-outline-secondary" + (1000 === qty ? " active" : "")
                }
                onClick={() => receiveQtyCards(1000)}
              >
                1 000
              </button>
            )}
            {totalPass > 10000 && (
              <button
                type="button"
                className={
                  "btn btn-outline-secondary" + (10000 === qty ? " active" : "")
                }
                onClick={() => receiveQtyCards(10000)}
              >
                10 000
              </button>
            )}
            {totalPass > 100000 && (
              <button
                type="button"
                className={
                  "btn btn-outline-secondary" +
                  (100000 === qty ? " active" : "")
                }
                onClick={() => receiveQtyCards(100000)}
              >
                100 000
              </button>
            )}
            {totalPass > 1000000 && (
              <button
                type="button"
                className={
                  "btn btn-outline-secondary" +
                  (1000000 === qty ? " active" : "")
                }
                onClick={() => receiveQtyCards(1000000)}
              >
                1 000 000
              </button>
            )}
            {
              <button
                type="button"
                className={
                  "btn btn-outline-secondary" + ("" === qty ? " active" : "")
                }
                onClick={() => receiveQtyCards("")}
              >
                {cardsDisplay[language].allCards} [ {totalPass} ]
              </button>
            }
          </div>
        </div>
      </div>

      {isLoading ? (
        <ThreeDots
          height="80"
          width="80"
          radius="9"
          color="#0d6efd"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClassName=""
          visible={true}
        />
      ) : (
        <div className="table-responsive">
          <table className="table mt-5">
            <thead>
              <tr>
                <th>{cardsDisplay[language].createdAt}</th>
                <th>{cardsDisplay[language].updatedAt}</th>
                <th>{cardsDisplay[language].fullName}</th>
                <th>{cardsDisplay[language].memberId}</th>
                {passSettings?.selfCreation && passSettings?.formData?.tel && (
                  <th>{cardsDisplay[language].tel}</th>
                )}
                {passSettings?.selfCreation &&
                  passSettings?.formData?.birthday && (
                    <th>{cardsDisplay[language].birthday}</th>
                  )}
                {passSettings?.selfCreation &&
                  passSettings?.formData?.email && <th>Email</th>}
                {passSettings?.loyaltyProgram?.active &&
                  (passSettings?.balance || passSettings?.discount) &&
                  (passSettings?.balance ? (
                    <th>{cardsDisplay[language].balance} </th>
                  ) : (
                    <th>{cardsDisplay[language].discount} </th>
                  ))}

                {passSettings?.photo && <th>{cardsDisplay[language].photo}</th>}

                <th>{!passSettings.marketing && "n/a"}</th>
                <th>{cardsDisplay[language].cardUrl}</th>
                <th>
                  <Link to={"/cards/create"}>
                    <button
                      style={{ width: "100%" }}
                      className="btn btn-outline-primary btn-sm"
                    >
                      {cardsDisplay[language].newCard}
                    </button>
                  </Link>
                </th>
                <th>
                  <button
                    style={{ width: "100%" }}
                    className="btn btn-outline-primary btn-sm"
                    onClick={downloadRegister}
                  >
                    {cardsDisplay[language].download}
                  </button>
                </th>
              </tr>
            </thead>

            <tbody>{tableBody}</tbody>
          </table>
        </div>
      )}

      {!passSettings.marketing && (
        <div className={"mb-5 mt-5 text-danger"}>
          <i>"n/a" - {cardsDisplay[language].availableWithMarketing}</i>
        </div>
      )}

      <Modal
        style={modalStyle}
        ariaHideApp={false}
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
      >
        {modalData}
      </Modal>
    </div>
  );
};
