import React, { useEffect, useState } from "react";
import axios from "../utils/axiosConfig";

const LiqPay = ({ organisationId, email, role }) => {
  const [btnData, setBtnData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          `/api/pass/payments/liqpay/${role !== "test" ? organisationId : ""}`,
          { email: role === "test" ? email : "" }
        );
        setBtnData(response.data);
      } catch (error) {
        console.error(error);
        setError("Error fetching data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [organisationId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const btn = (
    <form
      method="POST"
      action="https://www.liqpay.ua/api/3/checkout"
      acceptCharset="utf-8"
    >
      <input type="hidden" name="data" value={btnData.data} />
      <input type="hidden" name="signature" value={btnData.signature} />
      <button>{btnData.buttonText}</button>
    </form>
  );

  const telegram = (
    <form method="POST" action={"https://t.me/loyalcards"} acceptCharset="utf-8">
      <button>{"Contact us"}</button>
    </form>
  );

  return btnData.data && btnData.signature && btnData.buttonText ? btn : telegram;
};

export default LiqPay;
