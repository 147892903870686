import React, { useEffect, useState } from "react";
import axios from "../utils/axiosConfig";
import jwtDecode from "jwt-decode";
import { useNavigate, useLocation } from "react-router-dom";
import Swal from "sweetalert2";

import getBrowserLocales from "../utils/getBrowserLocales";
import { alerts, userLogin } from "../constants/translations";
import LiqPayButton from "../components/LiqPayButton";

export default () => {
  // localStorage.language = navigator.language || navigator.userLanguage

  if (!localStorage.language) {
    getBrowserLocales();
  }

  const language = localStorage.language;

  const [user, setUser] = useState({ email: "", password: "" });
  const [isError, setIsError] = useState(false);
  const [alert, setAlert] = useState("Error");
  const [disabled, setDisabled] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    fetch("https://ipwhois.app/json/?objects=country_code")
      .then((res) => res.json())
      .then((response) => {
        // console.log(response);
        response?.country_code
          ? (localStorage.countryCode = response?.country_code.toLowerCase())
          : (localStorage.countryCode = "ua");
      })
      .catch((data, status) => {
        localStorage.countryCode = "ua";
        console.error("Request failed:", data);
      });
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUser({ ...user, [name]: value });
  };

  const handlerSubmit = (event) => {
    event.preventDefault();
    axios
      .post("/api/auth/login", user)
      .then((r) => {
        let decoded = jwtDecode(r.data.token);

        localStorage.email = decoded.email;
        localStorage.role = decoded.role;
        localStorage.organisationId = decoded.organisationId;
        localStorage.hp = decoded.hp;

        if (
          +localStorage.hp &&
          +localStorage.informed !== new Date().getDate()
        ) {
          (+localStorage.hp === 140 || +localStorage.hp === 141) &&
            axios
              .post(
                `/api/pass/payments/liqpay/${
                  localStorage.role !== "test"
                    ? localStorage.organisationId
                    : ""
                }`,
                {
                  email: localStorage.role === "test" ? localStorage.email : "",
                }
              )
              .then((res) => {
                Swal.fire({
                  title:
                    language === "uk" ? "Потрібна оплата" : "Payment required",
                  html: `
            <form method="POST" action="https://www.liqpay.ua/api/3/checkout" acceptCharset="utf-8">
            <input type="hidden" name="data" value=${res.data.data} />
            <input type="hidden" name="signature" value=${res.data.signature} />
            <button style="color:white; background:#0d6efd; padding:10px 20px; border-radius:10px">${res.data.buttonText}</button>
            </form>
        `,
                  showConfirmButton: false,
                  showCloseButton: true,
                });
              })
              .catch((e) => console.log(e));

          +localStorage.hp === 150 &&
            axios
              .get(`/api/pass/payments/mono/${localStorage.organisationId}`)
              .then((res) => {
                if (!res?.data?.url) {
                  return
                }
                Swal.fire({
                  title:
                    language === "uk" ? "Потрібна оплата" : "Payment required",
                  html: `
            <form method="POST" action="${
              res?.data?.url
            }" acceptCharset="utf-8">
            <button style="color:white; background:#0d6efd; padding:10px 20px; border-radius:10px">${
              language === "uk" ? "Сплатити карткою" : "Pay by Card"
            }</button>
            </form>
        `,
                  showConfirmButton: false,
                  showCloseButton: true,
                });
              })
              .catch((e) => console.log(e));

          +localStorage.hp === 110 &&
            axios
              .get(`/api/pass/payments/card/${localStorage.organisationId}`)
              .then((res) => {
                Swal.fire({
                  title:
                    language === "uk" ? "Потрібна оплата" : "Payment required",
                  html: `
            <form method="POST" action="${
              res?.data?.url
            }" acceptCharset="utf-8">
            <button style="color:white; background:#0d6efd; padding:10px 20px; border-radius:10px">${
              language === "uk" ? "Сплатити карткою" : "Pay by Card"
            }</button>
            </form>
        `,
                  showConfirmButton: false,
                  showCloseButton: true,
                });
              })
              .catch((e) => console.log(e));

          +localStorage.hp === 120 &&
            Swal.fire({
              title: language === "uk" ? "Потрібна оплата" : "Payment required",
              text:
                language === "uk"
                  ? `Будь ласка, сплатіть рахунок, надісланий на ${localStorage.email}`
                  : `Please pay the Invoice sent to ${localStorage.email}`,
              showConfirmButton: false,
              showCloseButton: true,
            });
          localStorage.informed = new Date().getDate();
        }

        if (location?.search === "?scan") {
          navigate(-1);
        } else navigate("/cards");
        // navigate(-1) || navigate('/cards')
        // todo GO BACK
      })
      .catch((e) => {
        if (e.response.status === 429) {
          setIsError(true);
          setDisabled(true);
          setAlert(
            `${alerts[language].tooManyReq} ${e.response.headers["retry-after"]} sec`
          );
          setTimeout(() => {
            setIsError(false);
            setDisabled(false);
          }, e.response.headers["retry-after"] * 1000);
        }
        if (e.response.status === 401) {
          setIsError(true);
          setAlert(alerts[language].badAuth);
          setTimeout(() => {
            setIsError(false);
          }, 3000);
        }
        if (e.response.status === 404) {
          setIsError(true);
          setAlert(alerts[language].userNotFound);
          setTimeout(() => {
            setIsError(false);
          }, 3000);
        }
        if (e.response.status === 500) {
          setIsError(true);
          setAlert(alerts[language].serverError);
          setTimeout(() => {
            setIsError(false);
          }, 3000);
        }
      });
  };

  // setTimeout(() => {
  //     localStorage.clear()
  // }, 10000)

  // console.log(location);
  // console.log(user)

  return (
    <div className="auth-form d-grid">
      {isError && (
        <div className="alert alert-danger" role="alert">
          {alert}
        </div>
      )}

      <div className="form-card">
        <h4>{userLogin[language].header}</h4>
        <form onSubmit={handlerSubmit}>
          <input
            type="email"
            id="email"
            placeholder={userLogin[language].email}
            autoComplete={"email"}
            required
            name={"email"}
            value={user.email}
            onChange={handleChange}
          />
          <input
            type="password"
            id="password"
            placeholder={userLogin[language].password}
            autoComplete={"current-password"}
            required
            name={"password"}
            value={user.password}
            onChange={handleChange}
          />
          <div className="d-grid">
            <button
              type="submit"
              className="btn btn-primary mt-3"
              disabled={disabled}
            >
              {userLogin[language].submit}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
