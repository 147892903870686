import React, {useEffect, useState} from "react";
import Modal from "react-modal"
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import getBrowserLocales from "../utils/getBrowserLocales";
import {useNavigate} from "react-router-dom";
import {alerts, createPass, header} from "../constants/translations";
import axios from "../utils/axiosConfig";
import Header from "../layout/Header";

export default () => {

    if (!localStorage.language) {
        getBrowserLocales()
    }

    const language = localStorage.language
    const navigate = useNavigate()

    const [client, setClient] = useState({})
    const [countryCode, setCountryCode] = useState('ua')
    const [isError, setIsError] = useState(false)
    const [isInfo, setIsInfo] = useState(false)
    const [alert, setAlert] = useState('Error')
    const [disabled, setDisabled] = useState(false)
    const [passSettings, setPassSettings] = useState({})
    const [passTypeIds, setPassTypeIds] = useState([])
    const [templateIds, setTemplateIds] = useState([])
    const [formData, setFormData] = useState({})


    useEffect(() => {
        axios.get(`/api/passes/settings`)
            .then( res => {
                if(!res.data) navigate('/404')
                setPassSettings(res.data)
                setPassTypeIds(res.data.passTypeIdentifier)
                setTemplateIds(res.data.templateId)
                setClient({...client, passTypeIdentifier: res.data.passTypeIdentifier[0], templateId: res.data.templateId[0]})
                setFormData(res.data.formData)
            })
            .catch((e) => {
                console.error(e)
                navigate('/404')
            });
    },[])

    useEffect(() => {
        setCountryCode(localStorage.countryCode)
        // fetch('https://extreme-ip-lookup.com/json/')
        // fetch('https://ipwhois.app/json/?objects=country_code')
        //     .then( res => res.json())
        //     .then(response => {
        //         setCountryCode(response?.country_code.toLowerCase())
        //     })
        //     .catch((data, status) => {
        //         console.error('Request failed:', data);
        //     });
    },[])

    const handleChange = (event) => {
        const {name, value} = event.target
        setClient({...client, [name]: value})
    }

    const bDayHandler = e => {
        e.target.type = 'date'
        e.target.min = '1950-01-01'
        e.target.max = '2010-01-01'
    }

    const expirationHandler = e => {
        e.target.type = 'date'
    }

    const toDateTypeHandler = e => {
        e.target.value ? e.target.type = 'date' : e.target.type = 'text'
    }

    const handlerSubmit = (event) => {
        event.preventDefault()
        setDisabled(true)
        // if (client.firstName.length < 2 || client.lastName.length < 2 || client.tel.length < 11) {
        //     setIsError(true)
        //     setAlert(alerts[language].formError)
        //     return setTimeout(() => {
        //         setIsError(false)
        //     }, 5000)
        // }
        axios
            .post(`/api/v1/passes/${client.passTypeIdentifier}/pass`, client)
            .then(r => {
                if (r.status === 201) {
                    setIsInfo(true)
                    setAlert(`${alerts[language].successCreated}`)
                    setTimeout(() => {
                        setIsInfo(false)
                        navigate('/cards')
                    }, 2000)
                }
            })
            .catch(e => {
                if (e.response.status === 429){
                    setIsError(true)
                    setDisabled(true)
                    setAlert(`${alerts[language].tooManyReq} ${e.response.headers['retry-after']} sec`)
                    setTimeout(() => {
                        setIsError(false)
                        setDisabled(false)
                    }, (e.response.headers['retry-after'] * 1000))
                }
                if (e.response.status === 401){
                    setIsError(true)
                    setAlert(alerts[language].badAuth)
                    setTimeout(() => {
                        setIsError(false)
                    }, 3000)
                }
                if (e.response.status === 404){
                    setIsError(true)
                    setAlert(alerts[language].userNotFound)
                    setTimeout(() => {
                        setIsError(false)
                    }, 3000)
                }
                if (e.response.status === 409){
                    setIsError(true)
                    setAlert(alerts[language].clientExist)
                    setTimeout(() => {
                        navigate('/cards')
                    }, 3000)
                }
                if (e.response.status === 500){
                    setIsError(true)
                    setAlert(alerts[language].serverError)
                    setTimeout(() => {
                        setIsError(false)
                    }, 3000)
                }
            })

    }

    return (
        <div>
            {/*<Header />*/}
            <h4 className={"text-primary"}>{header[language].cards}</h4>
            <hr className={"mt-0 mb-5"} />

            <div  className="d-grid mt-5">
                {isInfo && (
                    <div className="alert alert-info" role="alert">
                        {alert}
                    </div>
                )}
                {isError && (
                    <div className="alert alert-danger" role="alert">
                        {alert}
                    </div>
                )}
                <div className="form-card">
                    <h4>{createPass[language].title}</h4>
                    <form onSubmit={handlerSubmit} autoComplete={"nope"}>
                        {passTypeIds.length > 1 &&
                        <>
                            <label htmlFor="passTypeIdentifier">Pass Type Id</label>
                            <select className="form-select mb-3" id={"passTypeIdentifier"} name={"passTypeIdentifier"} onChange={handleChange}>
                                {passTypeIds.map((item, index) => (<option key={index} value={item}>{item}</option>))}
                            </select>
                        </>
                        }
                        {templateIds.length > 1 &&
                        <>
                            <label htmlFor="templateId">{createPass[language].templateId}</label>
                            <select className="form-select mb-3" id={"templateId"} name={"templateId"} onChange={handleChange}>
                                {templateIds.map((item, index) => (<option key={index} value={item}>{item}</option>))}
                            </select>
                        </>
                        }
                        {formData.fullName &&
                        <input
                            type="text"
                            id="fullName"
                            placeholder={createPass[language].fullName}
                            autoComplete={"nope"}
                            required
                            name={"fullName"}
                            // value={client.fullName}
                            onChange={handleChange}
                        />
                        }
                        {formData.firstName &&
                        <input
                            type="text"
                            id="firstName"
                            placeholder={createPass[language].firstName}
                            autoComplete={"nope"}
                            required
                            name={"firstName"}
                            // value={client.firstName}
                            onChange={handleChange}
                        />
                        }
                        {formData.lastName &&
                        <input
                            type="text"
                            id="lastName"
                            placeholder={createPass[language].lastName}
                            autoComplete={"nope"}
                            required
                            name={"lastName"}
                            // value={client.lastName}
                            onChange={handleChange}
                        />
                        }
                        {formData.tel &&
                        <PhoneInput
                            inputStyle={{width: "100%", height: "38px", fontSize: "1rem"}}
                            type={"tel"}
                            country={countryCode}
                            id="tel"
                            autoComplete={"nope"}
                            placeholder={createPass[language].tel}
                            required
                            // value={client.tel}
                            onChange={tel => setClient({...client, tel: tel.replace(/[^+\d]/g, '')})}
                        />
                        }
                        {formData.birthday &&
                        <input
                            type="text"
                            id="birthday"
                            autoComplete={"nope"}
                            placeholder={createPass[language].birthday}
                            required
                            name={"birthday"}
                            // value={client.birthday}
                            onChange={handleChange}
                            onFocus={bDayHandler}
                            onBlur={toDateTypeHandler}
                        />
                        }
                        {formData.email &&
                        <input
                            type="email"
                            id="email"
                            autoComplete={"nope"}
                            placeholder={"Email"}
                            required
                            name={"email"}
                            // value={client.email}
                            onChange={handleChange}
                        />
                        }
                        {passSettings.balance &&
                        <input
                            type="number"
                            id="balance"
                            autoComplete={"nope"}
                            placeholder={createPass[language].balance}
                            // required
                            name={"balance"}
                            // value={client.balance}
                            onChange={handleChange}
                        />
                        }
                        {passSettings.discount &&
                        <input
                            type="number"
                            id="discount"
                            autoComplete={"nope"}
                            placeholder={createPass[language].discount}
                            // required
                            name={"discount"}
                            // value={client.discount}
                            onChange={handleChange}
                        />
                        }
                        {passSettings?.loyaltyProgram?.certificate?.templateId === client.templateId &&
                        <input
                            type="number"
                            id="certAmount"
                            autoComplete={"nope"}
                            placeholder={createPass[language].certAmount}
                            // required
                            name={"certAmount"}
                            // value={client.discount}
                            onChange={handleChange}
                        />
                        }
                        {!passSettings.currentNumber &&
                        <input
                            type="text"
                            id="memberId"
                            autoComplete={"nope"}
                            placeholder={createPass[language].memberId}
                            required
                            name={"memberId"}
                            // value={client.discount}
                            onChange={handleChange}
                        />
                        }
                        {passSettings.expirationDate &&
                        <input
                            type="datetime-local"
                            id="expirationDate"
                            autoComplete={"nope"}
                            placeholder={createPass[language].expirationDate}
                            required
                            name={"expirationDate"}
                            // value={client.birthday}
                            onChange={handleChange}
                            // onFocus={expirationHandler}
                            // onBlur={toDateTypeHandler}
                        />
                        }
                        {passSettings.relevantDate &&
                        <input
                            type="datetime-local"
                            id="relevantDate"
                            autoComplete={"nope"}
                            placeholder={createPass[language].relevantDate}
                            required
                            name={"relevantDate"}
                            // value={client.birthday}
                            onChange={handleChange}
                            // onFocus={expirationHandler}
                            // onBlur={toDateTypeHandler}
                        />
                        }
                        {passSettings.period &&
                            <>
                                <input
                                    type="text"
                                    id="from"
                                    autoComplete={"nope"}
                                    placeholder={createPass[language].from}
                                    required
                                    name={"from"}
                                    // value={client.birthday}
                                    onChange={handleChange}
                                    // onFocus={expirationHandler}
                                    // onBlur={toDateTypeHandler}
                                />
                                <input
                                    type="text"
                                    id="till"
                                    autoComplete={"nope"}
                                    placeholder={createPass[language].till}
                                    required
                                    name={"till"}
                                    // value={client.birthday}
                                    onChange={handleChange}
                                    // onFocus={expirationHandler}
                                    // onBlur={toDateTypeHandler}
                                />
                            </>
                        }
                        <div className="d-grid">
                            <button
                                type="submit"
                                className="btn btn-primary mt-3"
                                disabled={disabled}
                            >{createPass[language].createCardButton}
                            </button>
                            <button
                                className="btn btn-secondary mt-3"
                                disabled={disabled}
                                onClick={() => navigate(-1)}
                            >{createPass[language].goBack}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
