import React from "react";
import {error} from "../constants/translations"
export default (props) => {
    const language = localStorage.language
    return (
        <div id="notfound">
            <div className="notfound">
                <div className="notfound-404">
                    <h1>Oops!</h1>
                    <h2>403 - {error[language].forbidden}</h2>
                </div>
                <a href="/">{error[language].goToMain}</a>
            </div>
        </div>
    )
}
