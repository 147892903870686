import React, {useState, useEffect} from "react"
import axios from "../../utils/axiosConfig"
import {useNavigate, useLocation} from "react-router-dom";
import Header from "../../layout/Header";
import {settingsHeader, editPass, cardsDisplay, alerts} from "../../constants/translations"
import SettingsHeader from "../../layout/SettingsHeader";
import SettingsInput from "../../components/inputs/SettingsInput"
import SettingsSelect from "../../components/inputs/SettingsSelect"

export default () => {
    const language = localStorage.language
    const role = localStorage.role

    const navigate = useNavigate()
    const location = useLocation()
    const {message, passSettings} = location.state

    const [isError, setIsError] = useState(false)
    const [isInfo, setIsInfo] = useState(false)
    const [alert, setAlert] = useState('Error')
    const [isLoading, setIsLoading] = useState(true)
    const [disabled, setDisabled] = useState(!passSettings?.marketing)
    const [msg, setMsg] = useState(message)

    const handleChange = (event) => {
        let {name, value} = event.target
        if (name === 'hours') value = +value + (new Date().getTimezoneOffset() / 60)
        setMsg({...msg, [name]: value})
    }

    const handleSubmit = async () => {
        setIsLoading(true)
        setDisabled(true)
        try {
            await axios.post('/api/marketing/messages', msg)
                .then(response => {

                    if (response.status !== 200) return setIsLoading(false)
                    setIsLoading(false)
                    setIsInfo(true)
                    setAlert(alerts[language].success)
                    setTimeout(() => {
                        setIsInfo(false)
                        setDisabled(false)
                        navigate('/settings/marketing')
                    }, 2000)

                })
                .catch(e => {
                    console.log('ERRORR', e.response.status)
                    setIsLoading(false)

                    e.response.status === 401 && navigate('/user/login')
                    e.response.status === 402 && navigate('/402')
                    e.response.status === 403 && navigate('/403')
                })
        } catch (e) {
            console.error(e);
        }
    }

    const segments = ['']
    // for (const segment of passSettings.segment) {
    //     segments.push(segment.name)
    // }
    passSettings.segment.map(segment=>segments.push(segment.name))

    let inputArr = []
    for (const i in message) {
        if (i === '_id' || i === 'organisationId') continue
        if (i === 'name') inputArr.push({label: settingsHeader[language].regularMessageName, name: i, value: message[i]})
        if (i === 'description') inputArr.push({label: settingsHeader[language].regularMessageDescription, name: i, value: message[i]})
        if (i === 'hours') inputArr.push({label: settingsHeader[language].regularMessageTime, name: i, value: message[i], options: ['',8,9,10,11,12,13,14,15,16,17,18,19,20,21,22]})
        // if (i === 'date') inputArr.push({label: settingsHeader[language].regularMessageDate, name: i, value: message[i]})
        if (i === 'weekday') inputArr.push({label: settingsHeader[language].regularMessageWeekday, name: i, value: message[i], options: ['',0,1,2,3,4,5,6]})
        if (i === 'nthWeek') inputArr.push({label: settingsHeader[language].regularMessageNthWeek, name: i, value: message[i], options: ['',1,2,3,4]})
        //todo Segment from passSettings
        if (i === 'segment') inputArr.push({label: settingsHeader[language].regularMessageSegment, name: i, value: message[i], options: segments})
        if (i === 'message') inputArr.push({label: settingsHeader[language].regularMessageText, name: i, value: message[i]})
    }

    const inputs = inputArr.map((i, index)=>{
        if (i.name === 'message'){
            return
        }
        if (i.options) {
            return (
                <SettingsSelect
                    key={index}
                    handleChange={handleChange}
                    name={i.name}
                    value={i.value}
                    label={i.label}
                    options={i.options}
                />)
        }
        return (
            <SettingsInput
                key={index}
                handleChange={handleChange}
                name={i.name}
                value={i.value}
                label={i.label}
            />)
    })

    return (<>
        {isInfo && (
            <div className="alert alert-info" role="alert">
                {alert}
            </div>
        )}
        {isError && (
            <div className="alert alert-danger" role="alert">
                {alert}
            </div>
        )}

        {passSettings &&
        <div className='card p-3 col-md-12 col-xl-9 m-auto mb-5 mt-5'>
            <div className="input-group-sm">
                <h4 className='mt-3'>{settingsHeader[language].regularMessage}</h4>

                {<div className="input-group-sm">

                    {inputs}

                    <label htmlFor="message">{settingsHeader[language].regularMessageText}</label>
                    <textarea
                        className="form-control"
                        name="message"
                        id="message"
                        rows="3"
                        defaultValue={message?.message}
                        onChange={handleChange}/>

                    <div className='float-end'>
                        <button
                            className='btn btn-outline-danger me-3'
                            onClick={() => navigate('/settings/marketing')}>
                            {editPass[language].goBack}
                        </button>
                        <button
                            className='btn btn-outline-primary'
                            disabled={!passSettings?.marketing || disabled || !msg.name || !msg.description || !msg.hours || (!msg.weekday && msg.weekday !==0 ) || !msg.nthWeek || !msg.message || role !== 'admin'}
                            onClick={handleSubmit}>
                            {editPass[language].submitButton}
                        </button>
                    </div>
                </div>}
            </div>

        </div>}
    </>)

}