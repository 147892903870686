import React, {useState} from "react";
import axios from "../utils/axiosConfig"
import {useNavigate} from "react-router-dom";
import {alerts, uploadRegister} from "../constants/translations"

export default ({uploaded}) => {

    const language = localStorage.language

    const EXTERNAL_URL =`${window.location.protocol}//${window.location.hostname}`

    const [selectedFile, setSelectedFile] = useState(null)

    const [isLoading, setIsLoading] = useState(false)

    const [alert, setAlert] = useState(alerts[language].processing)

    const navigate = useNavigate()

    const fileSelectedHandler = event => {
        setSelectedFile(event.target.files[0]);
    }

    const fileUploadHandler = () => {
        const fd = new FormData()
        setIsLoading(true)
        fd.append('file', selectedFile, selectedFile.name)
        axios.post('/api/passes/register', fd, {
            onUploadProgress: progressEvent => {
                console.log(`Uploaded progress: ${progressEvent.loaded / progressEvent.total}`)
            }
        })
            .then(res => {
                if (res.status === 201) {
                    setSelectedFile(null)
                    uploaded(1)
                    setIsLoading(false)
                }
                if (res.status === 202) setAlert(alerts[language].longProcessing)
            })
            .catch(e => {
                if (!e.response) {
                    console.log(e);
                    return setAlert(e.message ? e.message : e)
                }
                if (e.response.status === 400) setAlert(alerts[language].registerErr)
                if (e.response.status === 401) return navigate('/user/login')


            })
    }

    return (
        <div>
            {isLoading && (
                <div className="alert alert-info" role="alert">
                    {alert}
                </div>
            )}
            <div className="input-group">
                <input type="file" className="form-control m-0" id="inputGroupFile04"
                       aria-describedby="inputGroupFile" aria-label="Upload"
                       onChange={fileSelectedHandler}
                />
                <button disabled={!selectedFile} className="btn btn-outline-secondary" type="button" id="inputGroupFile"
                        onClick={fileUploadHandler}
                >{uploadRegister[language].uploadButton}
                </button>
            </div>
            <div className="mt-3 d-flex justify-content-end">
                <a href={`${EXTERNAL_URL}/api/passes/register/templatefile`}>{uploadRegister[language].downloadButton}</a>
            </div>
        </div>
    )
}
