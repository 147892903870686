import React from "react"
import {settingsHeader} from "../../constants/translations";

export default props => {

    let value = props.value
    if (Array.isArray(value)) value = value[0]
    if (props.name === 'hours') value = value - new Date().getTimezoneOffset() / 60

    const opts = props.options && props.options.map((i,index) => {

        return (
            <option key={index} >{i}</option>
        )
    })
    return (
        <>
            <label htmlFor={props.name}>{props.label}</label>
            <select
                className="form-select mt-1"
                id={props.name}
                aria-label={props.name}
                name={props.name}
                defaultValue={value}
                onChange={props.handleChange}
            >
                {opts}
            </select>
        </>
    )
}