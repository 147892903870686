import React, {useState, useEffect} from "react"
import SettingsHeader from "../../layout/SettingsHeader";
import {cardsDisplay, settingsHeader} from "../../constants/translations";

import {useNavigate} from "react-router-dom";

export default ({passSettings}) => {

    const language = localStorage.language

    const navigate = useNavigate()

    return (
        <>
            {passSettings &&
            <div className='card p-3 col-md-12 col-xl-9 m-auto mb-5'>
                <div className="input-group-sm">

                    {/**/}
                    <h4 className='mt-3'>{settingsHeader[language].birthday}</h4>
                    <hr/>
                    <h6 className='mb-1 mt-1'>{passSettings?.birthday?.bDayBeforeDays} {settingsHeader[language].bDayBeforeDays}</h6>
                    <p>{passSettings?.birthday?.bDayBeforeText}</p>

                    <h6 className='mb-1 mt-1'>{settingsHeader[language].birthDayText}</h6>
                    <p>{passSettings?.birthday?.birthDayText}</p>

                    <h6 className='mb-1 mt-1'>{passSettings?.birthday?.bDayAfterDays} {settingsHeader[language].bDayAfterDays}</h6>
                    <p>{passSettings?.birthday?.bDayAfterText}</p>

                    <div className='float-end'>
                        {/*<button*/}
                        {/*    className='btn btn-outline-danger me-3'*/}
                        {/*    onClick={handleDelete}>*/}
                        {/*    {cardsDisplay[language].delete}*/}
                        {/*</button>*/}
                        <button
                            className={(!passSettings?.birthday?.bDayBeforeDays ||
                                !passSettings?.birthday?.birthDayText ||
                                !passSettings?.birthday?.bDayAfterDays) ? 'btn btn-outline-primary' : 'btn btn-outline-warning'
                            }
                            onClick={() => navigate('/settings/marketing/editbmessage', {state: passSettings})}>
                            {(passSettings?.birthday?.bDayBeforeDays ||
                                passSettings?.birthday?.birthDayText ||
                                passSettings?.birthday?.bDayAfterDays) ? cardsDisplay[language].edit : cardsDisplay[language].newCard
                            }
                        </button>
                    </div>
                </div>
            </div>
            }
        </>
    )
}