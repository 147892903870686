import React, {useEffect, useState} from 'react';
import {useNavigate, useSearchParams} from "react-router-dom";
import axios from '../../utils/axiosConfig';
import {alerts, header, swal, imageSizes} from "../../constants/translations";
import SettingsHeader from "../../layout/SettingsHeader";
import "./design.scss"
import Swal from 'sweetalert2'
import { RgbStringColorPicker } from "react-colorful";

const ImageUploadForm = () => {
    const [searchParams] = useSearchParams()
    const strip = searchParams.get('strip')
    // console.log(strip);

    const [disabled, setDisabled] = useState(true)
    const [passSettings, setPassSettings] = useState({});
    const [templateId, setTemplateId] = useState('');
    const [walletType, setWalletType] = useState('');
    const [selectedType, setSelectedType] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [editedImage, setEditedImage] = useState(null)

    const [imageSize, setImageSize] = useState("")
    const [backgroundColor, setBackgroundColor] = useState("rgb(255, 255, 255)")
    const [backgroundToggle, setBackgroundToggle] = useState(false)
    const [foregroundColor, setForegroundColor] = useState("rgb(0, 0, 0)")
    const [foregroundToggle, setForegroundToggle] = useState(false)
    const [labelColor, setLabelColor] = useState("rgb(0, 0, 0)")
    const [labelToggle, setLabelToggle] = useState(false)

    const language = localStorage.language
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(true)
    const [isError, setIsError] = useState(false)
    const [isInfo, setIsInfo] = useState(false)
    const [alert, setAlert] = useState('Error')

    useEffect(() => {
        axios.get('/api/passes/settings')
            .then(response => {
                if (response.status !== 200) return setIsLoading(false)
                setPassSettings(response.data)
                setTemplateId(response.data?.templateId[0])
                setIsLoading(false)
                if(response.data?.test){
                    Swal.fire({
                        title: 'Demo',
                        text: swal[language].demoAccSettingsText,
                        confirmButtonText: 'OK',
                        confirmButtonColor: "#0d6efd"
                    })
                }
            })
            .catch(e => {
                console.error('ERRORR', e.response.status)
                setIsLoading(false)
                e.response.status === 401 && navigate('/user/login')
                e.response.status === 402 && navigate('/402')
                e.response.status === 403 && navigate('/403')
                e.response.status === 404 && setPassSettings([])
            })
    }, [])

    useEffect(() => {
        const handleCrop = async () => {
            if (selectedFile && selectedType) {
                try {
                    const convertedFile = await convertToPNG(selectedFile, selectedType);
                    const editedImageUrl = URL.createObjectURL(convertedFile);
                    setEditedImage(editedImageUrl);
                } catch (error) {
                    console.error('Error cropping image:', error);
                }
            }
        };

        handleCrop();
    }, [selectedFile, selectedType]);



    const handleTemplateId = (e) => {
        setTemplateId(e.target.value);
        setWalletType('');
        setSelectedType('');
        setImageSize('')
    };
    const handleWalletChange = (e) => {
        setWalletType(e.target.value);
        setSelectedType('');
        setImageSize('')
    };

    const handleTypeChange = (e) => {
        setSelectedType(e.target.value);
        setImageSize(e.target.value)
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setSelectedFile(file);
        setDisabled(false)
    };

    const templateIdOpts = passSettings?.templateId?.map((item, index) => {
        return (
            <option key={index} value={item}>{item}</option>
        )
    })


    const convertToPNG = async (file, selectedType) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        // setDisabled(false)

        return new Promise((resolve) => {
            reader.onload = (event) => {
                const img = new Image();
                img.src = event.target.result;
                img.onload = () => {
                    let canvas = document.createElement('canvas');

                    // Встановлюємо розміри canvas в залежності від вибраного типу
                    if (selectedType === 'icon') {
                        canvas.width = 87;
                        canvas.height = 87;
                    } else if (selectedType === 'logo') {
                        canvas.width = img.naturalWidth > img.naturalHeight ? 480 : 150;
                        canvas.height = 150;
                    } else if (selectedType === 'strip') {
                        canvas.width = 1125;
                        canvas.height = 432;
                    } else if (selectedType === 'logo_g') {
                        canvas.width = 1200;
                        canvas.height = 1200;
                    } else if (selectedType === 'hero') {
                        canvas.width = 1032;
                        canvas.height = 336;
                    }

                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

                    canvas.toBlob((blob) => {
                        resolve(blob);
                    }, 'image/png');
                };
            };
        });
    };

    const handleSubmit = async (e) => {
        console.log('handleSubmit');
        setDisabled(true)
        e.preventDefault();

        if (!walletType || !selectedType || !selectedFile) {
            return;
        }

        try {
            const convertedFile = await convertToPNG(selectedFile, selectedType);
            const fileName = `${selectedType}.png`;

            // Отримуємо Blob URL для відображення на UI
            const editedImageUrl = URL.createObjectURL(convertedFile);
            setEditedImage(editedImageUrl);

            const formData = new FormData();
            formData.append('file', convertedFile, fileName);

            const url = strip ? `/api/v1/templates/images/${templateId}?strip=${strip}` : `/api/v1/templates/images/${templateId}`
            const response = await axios.post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });


            setIsInfo(true)
            setAlert(`${alerts[language].success}`)
            setTimeout(() => {
                setIsInfo(false)
            }, 2000)

            // console.log('File uploaded successfully:', response.data);
        } catch (error) {
            console.error('Error uploading file:', error);
            setIsError(true)
            setAlert(`${alerts[language].serverError}`)
            setTimeout(() => {
                setIsError(false)
            }, 2000)
        }
    };

    function setBackgroundColorFromForm(e) {
        setBackgroundColor(e?.target?.value)
    }
    function setForegroundColorFromForm(e) {
        setForegroundColor(e?.target?.value)
    }
    function setLabelColorFromForm(e) {
        setLabelColor(e?.target?.value)
    }

    async function handleSubmitColors () {
        const url = `/api/v1/templates/${templateId}`
        const template = {backgroundColor, foregroundColor, labelColor}
        try {
            const newTemplate = await axios.put(url, template)
            console.log(newTemplate);
        } catch (e) {
            console.log(e);
        }
    }

    // console.log(templateId);
    console.log(backgroundColor);

    return (
        <>
            {isInfo && (
                <div className="alert alert-info" role="alert">
                    {alert}
                </div>
            )}
            {isError && (
                <div className="alert alert-danger" role="alert">
                    {alert}
                </div>
            )}


            <h4 className={"text-primary"}>{header[language].settings}</h4>
            <hr className={"mt-0 mb-0"} />
            <SettingsHeader/>

            <div className="container">
                <div className="row d-flex justify-content-around">
                    <div className='card col-lg-5 mb-5 p-4'>

                        <div className={"design"}>
                            <h2>Image Upload Form</h2>
                            <form onSubmit={handleSubmit}>
                                {passSettings?.templateId?.length > 1 && <div>
                                    <label htmlFor="walletType">Select Template:</label>
                                    <select id="walletType" value={templateId} onChange={handleTemplateId}>
                                        {templateIdOpts}
                                        {/*<option value="">Select Template</option>*/}
                                        {/*<option value="1">Template 1</option>*/}
                                        {/*<option value="2">Template 2</option>*/}
                                    </select>
                                </div>}
                                <div>
                                    <label htmlFor="walletType">Select Wallet Type:</label>
                                    <select id="walletType" value={walletType} onChange={handleWalletChange}>
                                        <option value="">Select Wallet Type</option>
                                        <option value="apple">Apple Wallet</option>
                                        <option value="google">Google Wallet</option>
                                    </select>
                                </div>
                                <div>
                                    <label htmlFor="imageType">Select Image Type:</label>
                                    <select id="imageType" value={selectedType} onChange={handleTypeChange}>
                                        <option value="">Select Type</option>
                                        {walletType === 'apple' && (
                                            <>
                                                <option value="icon">Icon</option>
                                                <option value="logo">Logo</option>
                                                <option value="strip">Strip</option>
                                            </>
                                        )}
                                        {walletType === 'google' && (
                                            <>
                                                <option value="logo_g">Logo G</option>
                                                <option value="hero">Hero</option>
                                            </>
                                        )}
                                    </select>
                                </div>
                                {imageSize && <i style={{color: 'brown'}}>{imageSizes[language][imageSize]}</i>}
                                <div>
                                    {/*<label htmlFor="imageFile">Select Image File:</label>*/}
                                    <input
                                        type="file"
                                        id="imageFile"
                                        accept=".jpg, .jpeg, .png"
                                        onChange={handleFileChange}
                                    />
                                </div>
                                {/*<div>*/}
                                {/*    <label htmlFor="editedImage">Edited Image:</label>*/}
                                {/*    {editedImage && <img className={`editedImage ${walletType === 'google' && selectedType === 'logo_g' ? 'rounded' : ''}`} src={editedImage} alt="Edited" />}*/}
                                {/*</div>*/}
                                <button disabled={disabled || localStorage.role !== "admin" || !templateId || !walletType || !selectedType || !selectedFile} className={"btn btn-primary"} type="submit">Upload</button>
                            </form>
                        </div>

                    </div>
                </div>
            </div>



            <div className="container">
                <div className="row d-flex justify-content-around">
                    <div className='card col-lg-5 mb-5 p-4'>

                        <div className={""}>
                            <h2>Colors</h2>
                            <form onSubmit={handleSubmitColors}>
                                {passSettings?.templateId?.length > 1 && <div>
                                    <label htmlFor="walletType">Select Template:</label>
                                    <select id="walletType" value={templateId} onChange={handleTemplateId}>
                                        {templateIdOpts}
                                        {/*<option value="">Select Template</option>*/}
                                        {/*<option value="1">Template 1</option>*/}
                                        {/*<option value="2">Template 2</option>*/}
                                    </select>
                                </div>}
                                <div>
                                    <label htmlFor="backgroundColor">Background Color</label>
                                    <div
                                        style={{height: '40px', width: '40px', backgroundColor: backgroundColor, border: 'solid 1px gray'}}
                                        onClick={() => setBackgroundToggle(!backgroundToggle)}
                                    ></div>
                                    {backgroundToggle && <RgbStringColorPicker color={backgroundColor} onChange={setBackgroundColor}/>}
                                    <input
                                        type="text"
                                        id={'backgroundColor'}
                                        name={'backgroundColor'}
                                        value={backgroundColor}
                                        onChange={setBackgroundColorFromForm}
                                    />
                                </div>
                                <div>
                                    <label htmlFor="labelColor">Label Color</label>
                                    <div
                                        style={{height: '40px', width: '40px', backgroundColor: labelColor, border: 'solid 1px gray'}}
                                        onClick={() => setLabelToggle(!labelToggle)}
                                    ></div>
                                    {labelToggle && <RgbStringColorPicker color={labelColor} onChange={setLabelColor}/>}
                                    <input
                                        type="text"
                                        id={'labelColor'}
                                        name={'labelColor'}
                                        value={labelColor}
                                        onChange={setLabelColorFromForm}
                                    />
                                </div>
                                <div>
                                    <label htmlFor="backgroundColor">Text Color</label>
                                    <div
                                        style={{height: '40px', width: '40px', backgroundColor: foregroundColor, border: 'solid 1px gray'}}
                                        onClick={() => setForegroundToggle(!foregroundToggle)}
                                    ></div>
                                    {foregroundToggle && <RgbStringColorPicker color={foregroundColor} onChange={setForegroundColor}/>}
                                    <input
                                        type="text"
                                        id={'foregroundColor'}
                                        name={'foregroundColor'}
                                        value={foregroundColor}
                                        onChange={setForegroundColorFromForm}
                                    />
                                </div>
                                <button disabled={localStorage.role !== "admin" || !templateId || !backgroundColor || !foregroundColor || !labelColor} className={"btn btn-primary"} type="submit">Save</button>
                            </form>
                        {/*    todo UPDATE GOOGLE WALLET PASS*/}
                        </div>

                    </div>
                </div>
            </div>



        </>



    );
};

export default ImageUploadForm;