import React, {useEffect, useState} from "react";
import Helmet from 'react-helmet';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import getBrowserLocales from "../utils/getBrowserLocales";
import {useNavigate, useParams,  useLocation} from "react-router-dom";
import {alerts, createPass} from "../constants/translations";
import axios from "../utils/axiosConfig";

export default () => {
    const EXTERNAL_URL =`${window.location.protocol}//${window.location.hostname}`

    if (!localStorage.language) {
        getBrowserLocales()
    }

    const language = localStorage.language
    const navigate = useNavigate()

    const query = new URLSearchParams(useLocation().search);
    const referrer = query.get("referrer");
    const source = query.get("source");

    const {organisationId, templateId} = useParams()

    const [client, setClient] = useState({firstName: '', lastName:'', fullName: '', tel: '', birthday: '', email: ''})
    const [countryCode, setCountryCode] = useState('ua')
    // onChange={phone => setClient({ ...client, tel: phone.replace(/[^+\d]/g, '') })}
    const [isError, setIsError] = useState(false)
    const [alert, setAlert] = useState('Error')
    const [disabled, setDisabled] = useState(false)
    const [formData, setFormData] = useState({})

    //todo
    // get passSettings by organisationId and templateId
    // if selfCreation => get form

    useEffect(() => {
        axios.get(`/api/pass/c/${organisationId}/${templateId}/create`)
            .then( res => {
                if(res.data && !res.data.selfCreation){
                    navigate('/404')
                } else {
                    setFormData(res.data.formData)
                }
            })
            .catch((e) => {
                console.error(e)
                navigate('/404')
            });
    },[])

    useEffect(() => {
        // fetch('https://extreme-ip-lookup.com/json/')
        fetch('https://ipwhois.app/json/?objects=country_code')
            .then( res => res.json())
            .then(response => {
                setCountryCode(response?.country_code.toLowerCase())
            })
            .catch((data, status) => {
                console.error('Request failed (ipwhois.io):', data)
                fetch('https://ip2c.org/s')
                    .then( res => res.text())
                    .then(res => {
                        let [, countryCode] = res.split(";")
                        setCountryCode(countryCode.toLowerCase())
                    })
                    .catch((data, status) => {
                        console.error('Request failed (https://ip2c.org/s):', data);
                    })

            });

    },[])

    // console.log(formData);
    // console.log(countryCode);

    const handleChange = (event) => {
        const {name, value} = event.target
        console.log(name, value);
        setClient({...client, [name]: value})
        // setClient({...client, [name]: (name === 'tel') ? (value.replace(/[^+\d]/g, '')) : value})
    }

    const bDayHandler = e => {
        e.target.type = 'date'
        e.target.min = '1950-01-01'
        e.target.max = '2010-01-01'
    }

    const handlerSubmit = (event) => {
        event.preventDefault()
        if (client.firstName.length < 2 || client.lastName.length < 2 || client.tel.length < 11) {
            setIsError(true)
            setAlert(alerts[language].formError)
            return setTimeout(() => {
                setIsError(false)
            }, 5000)
        }
        let url = `/api/pass/c/${organisationId}/${templateId}/create`
        if (referrer) url = `/api/pass/c/${organisationId}/${templateId}/create?referrer=${referrer}`
        if (source) url = `/api/pass/c/${organisationId}/${templateId}/create?source=${source}`
        axios
            .post(url, client)
            .then(r => {
                if (r.data.url) window.location.href = r.data.url
            })
            .catch(e => {
                if (e.response.status === 409){
                    if (e.response.data.url) window.location.href = e.response.data.url
                }
                if (e.response.status === 429){
                    setIsError(true)
                    setDisabled(true)
                    setAlert(`${alerts[language].tooManyReq} ${e.response.headers['retry-after']} sec`)
                    setTimeout(() => {
                        setIsError(false)
                        setDisabled(false)
                    }, (e.response.headers['retry-after'] * 1000))
                }
                if (e.response.status === 401){
                    setIsError(true)
                    setAlert(alerts[language].badAuth)
                    setTimeout(() => {
                        setIsError(false)
                    }, 3000)
                }
                if (e.response.status === 404){
                    console.log(e);
                    setIsError(true)
                    setAlert(alerts[language].userNotFound)
                    setTimeout(() => {
                        setIsError(false)
                    }, 3000)
                }
                if (e.response.status === 500){
                    setIsError(true)
                    setAlert(alerts[language].serverError)
                    setTimeout(() => {
                        setIsError(false)
                    }, 3000)
                }
            })

    }

    return (
        <>
            <div className={"card-registration"}>
                <Helmet>
                    <title>{createPass[language].title}</title>

                    {/*Facebook Meta Tags*/}
                    <meta property="og:url" content={`https://loyal.ws/pass/c/${organisationId}/${templateId}`}/>
                    <meta property="og:type" content="website"/>
                    <meta property="og:title" content="Get your Pass!"/>
                    <meta property="og:description" content="Get your Pass!"/>
                    <meta property="og:image"
                          content={`https://loyal.ws/d/img/org/${organisationId}/${templateId}/logo@2x.png`}/>

                    {/*Twitter Meta Tags*/}
                    <meta name="twitter:card" content="summary_large_image"/>
                    <meta property="twitter:domain" content="loyal.ws"/>
                    <meta property="twitter:url"
                          content={`https://loyal.ws/pass/c/${organisationId}/${templateId}`}/>
                    <meta name="twitter:title" content="Get your Pass!"/>
                    <meta name="twitter:description" content="Get your Pass!"/>
                    <meta name="twitter:image"
                          content={`https://loyal.ws/d/img/org/${organisationId}/${templateId}/logo@2x.png`}/>

                </Helmet>
                <h4 className={"text-primary"}>Реестры карт</h4>
                <hr className={"mt-0 mb-5"} />

                <div className="form">
                    {isError && (
                        <div className="alert alert-danger" role="alert">
                            {alert}
                        </div>
                    )}

                    <div className="preview mt-5">
                        <div className="card" style={{"background": "#fff"}}>
                            <img className="logo" src={`${EXTERNAL_URL}/d/img/org/${organisationId}/${templateId}/logo@2x.png`} alt="" />
                            <img className="strip" src={`${EXTERNAL_URL}/d/img/org/${organisationId}/${templateId}/strip@2x.png`} alt=""/>
                        </div>
                    </div>

                    <h5 className={"mt-5 mb-3"}>{createPass[language].titleClient}</h5>

                    <form onSubmit={handlerSubmit}>
                        {formData.fullName &&
                        <div className="mb-3">
                            <input
                                type="text"
                                id="fullName"
                                autoComplete={"name"}
                                placeholder={createPass[language].fullName}
                                required
                                name={"fullName"}
                                value={client.fullName}
                                onChange={handleChange}
                            />
                        </div>
                        }
                        {formData.firstName &&
                        <div>
                            <input
                                type="text"
                                id="firstName"
                                autoComplete={"given-name"}
                                placeholder={createPass[language].firstName}
                                required
                                name={"firstName"}
                                value={client.firstName}
                                onChange={handleChange}
                            />
                        </div>
                        }
                        {formData.lastName &&
                        <div>
                            <input
                                type="text"
                                id="lastName"
                                autoComplete={"family-name"}
                                placeholder={createPass[language].lastName}
                                required
                                name={"lastName"}
                                value={client.lastName}
                                onChange={handleChange}
                            />
                        </div>
                        }
                        {formData.tel &&
                        <div>
                            <PhoneInput
                                inputStyle={{width: "100%", height: "38px", fontSize: "1rem"}}
                                type={"tel"}
                                country={countryCode}
                                id="tel"
                                autoComplete={"tel-national"}
                                placeholder={createPass[language].tel}
                                required
                                value={client.tel}
                                onChange={tel => setClient({...client, tel: tel.replace(/[^+\d]/g, '')})}
                            />
                        </div>
                        }
                        {formData.birthday &&
                        <div>
                            <input
                                type="text"
                                id="birthday"
                                autoComplete={"bday"}
                                placeholder={createPass[language].birthday}
                                required
                                name={"birthday"}
                                value={client.birthday}
                                onChange={handleChange}
                                onFocus={bDayHandler}
                            />
                        </div>
                        }
                        {formData.email &&
                        <div>
                            <input
                                type="email"
                                id="email"
                                autoComplete={"email"}
                                placeholder={"Email"}
                                required
                                name={"email"}
                                value={client.email}
                                onChange={handleChange}
                            />
                        </div>
                        }
                        <div className="d-grid mt-4 mb-3">
                            <button
                                type="submit"
                                className="btn btn-primary"
                                disabled={disabled}
                            >{createPass[language].submitButton}</button>
                        </div>
                    </form>
                </div>
            </div>

            <footer className="footer">
                <p>Powered by <a href="https://loyalcards.com.ua">LOYAL CARDS"</a></p>
                <p>2005 - 2023</p>
            </footer>
        </>

    )
}
