import React, {useState, useEffect} from "react"
import SettingsHeader from "../../layout/SettingsHeader";
import {cardsDisplay, settingsHeader, settingsSegment} from "../../constants/translations";

import {useNavigate} from "react-router-dom";
import axios from "../../utils/axiosConfig";

export default ({passSettings, messages}) => {

    const language = localStorage.language
    const navigate = useNavigate()

    const handleEdit = message => {
        let state = {message, passSettings}
        navigate('/settings/marketing/editrmessage', {state})
        console.log(message);
    }

    const handleDeleteMsg = async (_id) => {
        console.log(_id);
        try {
            await axios.delete('/api/marketing/messages', {data: {_id}})
                //todo reload
                .then(() => window.location.reload(false))
                .catch(e => console.log(e))
        } catch (e) {
            console.log(e);
        }
    }

    const tableBody = messages.map((item, index) => {

        return (
            <tr key={index}>
                <th scope="row">{index + 1}</th>
                {/*<td>{item.name}</td>*/}
                <td>{item.segment}</td>
                <td>{item.message}</td>
                <td>
                    <div className="d-grid mx-auto m-1">
                        <button
                            className='btn btn-outline-warning'
                            onClick={() => handleEdit(item)}>
                            {cardsDisplay[language].edit}
                        </button>
                    </div>
                    <div className="d-grid mx-auto ">
                        <button
                            className='btn btn-outline-danger'
                            onClick={() => handleDeleteMsg(item._id)}>
                            {cardsDisplay[language].delete}
                        </button>
                    </div>
                </td>
            </tr>
        )
    })

    return (
        <>
            { tableBody &&
            <div className='card p-3 col-md-12 col-xl-9 m-auto mb-5'>
                <div className="input-group-sm">

                    {/**/}
                    <h4 className='mt-3'>{settingsHeader[language].regularMessage}</h4>
                    <hr/>

                    <table className="table">
                        <thead>
                        <tr>
                            <th scope="col">#</th>
                            {/*<th scope="col">{settingsHeader[language].regularMessageName}</th>*/}
                            <th scope="col">Segment</th>
                            <th scope="col">{settingsHeader[language].regularMessageText}</th>
                            <th scope="col">
                                <div className="d-grid mx-auto">
                                    <button
                                        className='btn btn-outline-primary full-width'
                                        onClick={() => handleEdit({name: '', description: '', hours: '', date: '', weekday: '', nthWeek: '', segment: '', message:''})}>
                                        {cardsDisplay[language].newCard}
                                    </button>
                                </div>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {tableBody}
                        </tbody>
                    </table>

                </div>
            </div>
            }
        </>
    )
}