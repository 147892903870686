import React, {useState, useEffect} from "react"
import SettingsHeader from "../../layout/SettingsHeader";
import {cardsDisplay, settingsHeader, settingsSegment} from "../../constants/translations";

import {useNavigate} from "react-router-dom";
import axios from "../../utils/axiosConfig";

export default ({passSettings, segments}) => {

    const language = localStorage.language
    const role = localStorage.role
    const navigate = useNavigate()

    const handleEdit = segment => {
        let state = {segment, passSettings}
        navigate('/settings/marketing/editsegment', {state})
    }

    const handleDeleteSeg = async (_id) => {
        console.log(_id);
        try {
            await axios.delete('/api/marketing/segments', {data: {_id}})
                //todo reload
                .then(() => window.location.reload(false))
                .catch(e => {
                    console.log('ERRORR', e.response.status)

                    e.response.status === 401 && navigate('/user/login')
                    e.response.status === 402 && navigate('/402')
                    e.response.status === 403 && navigate('/403')
                })
        } catch (e) {
            console.log(e);
        }
    }

    const tableBody = segments.map((item, index) => {

        return (
            <tr key={index}>
                <th scope="row">{index + 1}</th>
                <td>{item.name}</td>
                <td>{item.description}</td>
                <td>
                    <div className="d-grid mx-auto m-1">
                        <button
                            disabled={role !== 'admin'}
                            className='btn btn-outline-warning'
                            onClick={() => handleEdit(item)}>
                            {cardsDisplay[language].edit}
                        </button>
                    </div>
                    <div className="d-grid mx-auto ">
                        <button
                            disabled={role !== 'admin'}
                            className='btn btn-outline-danger'
                            onClick={() => handleDeleteSeg(item._id)}>
                            {cardsDisplay[language].delete}
                        </button>
                    </div>
                </td>
            </tr>
        )
    })

    return (
        <>
            { tableBody &&
            <div className='card p-3 col-md-12 col-xl-9 m-auto mb-5'>
                <div className="input-group-sm">

                    {/**/}
                    <h4 className='mt-3'>{settingsHeader[language].segment}</h4>
                    <hr/>

                    <table className="table">
                        <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Segment</th>
                            <th scope="col">{settingsSegment[language].description}</th>
                            <th scope="col">
                                <div className="d-grid mx-auto">
                                    <button
                                        className='btn btn-outline-primary full-width'
                                        onClick={() => handleEdit({name: '', description: '', lastVisit: {}, balance: {}, discount: {}, status: '', installed: '', gender: '', referrer: false, passTypeIdentifier: [], templateId: [], source: [], createdByUser: [], department: []})}>
                                        {cardsDisplay[language].newCard}
                                    </button>
                                </div>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {tableBody}
                        </tbody>
                    </table>

                </div>
            </div>
            }
        </>
    )
}