import React, {useEffect, useState} from "react"
import axios from "../utils/axiosConfig"
import {useNavigate} from "react-router-dom";
import jwtDecode from "jwt-decode";
import Header from "../layout/Header";
import {alerts, createPass, header, userCreate} from "../constants/translations"

export default () => {

    const language = localStorage.language

    const navigate = useNavigate()

    const [user, setUser] = useState({department: '', email: ''})
    const [isValid, setIsValid] = useState(true)
    const [isError, setIsError] = useState(false)
    const [alert, setAlert] = useState(alerts[language].processing)
    const [values, setValues] = useState([])
    const [disabled, setDisabled] = useState(false)

    useEffect(() => {
        axios.get(`/api/organisations/${localStorage.organisationId}`)
            .then(res => {
                if (res.data.departments.length === 1) {
                    setUser({...user, department: res.data.departments[0]})
                } else {
                    setValues(res.data.departments)
                }
            })
            .catch(e => console.log(e))
    }, [navigate])

    useEffect(() => {
        if (!user.department || !user.email){
            setIsValid(false)
        } else setIsValid(true)
    }, [user])

    const handleChange = (event) => {
        const {name, value, type, checked} = event.target
        type === 'checkbox' ? setUser({...user, [name]: checked}) : setUser({...user, [name]: value})
    }

    const handlerSubmit = (event) => {
        event.preventDefault()
        axios
            // .post('/api/users', user, {headers: {Authorization: localStorage.token}})
            .post('/api/users', user)
            .then(r => {
                if (r.status === 201) navigate('/user')
            })
            .catch(e => {
                if (e.response.status === 409){
                    setIsError(true)
                    setAlert(alerts[language].userExist)
                    setTimeout(() => {
                        navigate('/user')
                    }, 3000)
                }
                if (e.response.status === 401){
                    setIsError(true)
                    setAlert(alerts[language].notAuthorized)
                    setTimeout(() => {
                        navigate('/user/login')
                    }, 3000)
                }
                console.log(e)
                // navigate('/user/login')
            })
    }

    return (
        <div>
            {/*<Header />*/}
            <h4 className={"text-primary"}>{header[language].users}</h4>
            <hr className={"mt-0 mb-5"} />
            {isError && (
                <div className="alert alert-danger" role="alert">
                    {alert}
                </div>
            )}
            <div className="d-grid mt-5">
                <div className="form-card">
                    <h4>{userCreate[language].header}</h4>
                    <form onSubmit={handlerSubmit}>
                        {values.length > 1 &&
                            (<div>
                            <label htmlFor="department">{userCreate[language].department}</label>
                            <select
                                className="form-select mb-3"
                                id="department"
                                required
                                name={"department"}
                                value={user.department}
                                onChange={handleChange}
                            >
                                <option value={0}>...</option>
                                {values.map(value => (
                                    <option key={value} value={value}>{value}</option>
                                ))}
                            </select>
                        </div>)
                        }
                        <input
                            type="email"
                            id="email"
                            placeholder={"Email"}
                            aria-describedby="emailHelp"
                            required
                            autoComplete={"nope"}
                            name={"email"}
                            value={user.email}
                            onChange={handleChange}
                        />
                        <div id="emailHelp" className="form-text mb-3">{userCreate[language].emailHelpText}</div>

                        <div className="d-grid">
                            <button
                                type="submit"
                                className="btn btn-primary mt-3"
                                disabled={!isValid}
                            >{userCreate[language].registration}</button>
                            <button
                                className="btn btn-secondary mt-3"
                                disabled={disabled}
                                onClick={() => navigate(-1)}
                            >{createPass[language].goBack}
                            </button>
                        </div>
                    </form>
                </div>
            </div>




        </div>
    )
}
