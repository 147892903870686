import React, {useState, useEffect} from "react"
import axios from "../utils/axiosConfig"
import {useNavigate} from "react-router-dom";
import Header from "../layout/Header";
import {billing, alerts, header} from "../constants/translations"
import SettingsHeader from "../layout/SettingsHeader";

export default () => {
    const language = localStorage.language
    const role = localStorage.role
    const [locale, setLocale] = useState('uk-UA')
    const [currency, setCurrency] = useState('UAH')
    const [point, setPoint] = useState(500)
    const [isError, setIsError] = useState(false)
    const [isInfo, setIsInfo] = useState(false)
    const [alert, setAlert] = useState('Error')
    const [disabled, setDisabled] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const navigate = useNavigate()
    const [passSettings, setPassSettings] = useState()
    const [organisationSettings, setOrganisationSettings] = useState()

    const [order, setOrder] = useState({
            base: {active: false, paid: false, testTill: '', point: 1},
            loyalty: {active: false, paid: false, testTill: '', point: 1},
            marketing: {active: false, paid: false, testTill: '', point: 1},
            integration: {active: false, paid: false, testTill: '', point: 1},
            whiteLabel: {active: false, paid: false, testTill: '', point: 40},
            plus4: {active: false, paid: false, testTill: '', point: 1},
            plus9: {active: false, paid: false, testTill: '', point: 2},
            plus24: {active: false, paid: false, testTill: '', point: 4},

        }
    )

    const [paid, setPaid] = useState(0)
    const [unpaid, setUnpaid] = useState(0)
    const [count, setCount] = useState(0)


    useEffect(() => {
        axios.get('/api/passes/settings')
            .then(response => {
                if (response.status !== 200) return setIsLoading(false)
                setPassSettings(response.data)
                setIsLoading(false)
            })
            .catch(e => {
                console.log('ERRORR', e.response.status)
                setIsLoading(false)
                e.response.status === 401 && navigate('/user/login')
                e.response.status === 402 && navigate('/402')
                e.response.status === 403 && navigate('/403')
                e.response.status === 404 && setPassSettings([])
            })
        axios.get('/api/organisations/' + localStorage.organisationId)
            .then(response => {
                if (response.status !== 200) return setIsLoading(false)
                setOrganisationSettings(response.data)
                setIsLoading(false)
                setOrder(prevState => {
                    const tariff = prevState
                    tariff.base.paid = !response.data?.tariff?.name?.includes('test')
                    tariff.loyalty.paid = response.data?.tariff?.name?.includes('loyalty')
                    tariff.marketing.paid = response.data?.tariff?.name?.includes('marketing')
                    tariff.integration.paid = response.data?.tariff?.name?.includes('integration')
                    tariff.whiteLabel.paid = response.data?.tariff?.name?.includes('organisationSettings?.whiteLabel?.domen && organisationSettings?.whiteLabel?.link')
                    tariff.plus4.paid = response.data?.tariff?.name?.includes('5000')
                    tariff.plus9.paid = response.data?.tariff?.name?.includes('10000')
                    tariff.plus24.paid = response.data?.tariff?.name?.includes('25000')

                    tariff.base.active = !response.data?.tariff?.name?.includes('test')
                    tariff.loyalty.active = response.data?.tariff?.name?.includes('loyalty')
                    tariff.marketing.active = response.data?.tariff?.name?.includes('marketing')
                    tariff.integration.active = response.data?.tariff?.name?.includes('integration')
                    tariff.whiteLabel.active = response.data?.tariff?.name?.includes('organisationSettings?.whiteLabel?.domen && organisationSettings?.whiteLabel?.link')
                    tariff.plus4.active = response.data?.tariff?.name?.includes('5000')
                    tariff.plus9.active = response.data?.tariff?.name?.includes('10000')
                    tariff.plus24.active = response.data?.tariff?.name?.includes('25000')

                    return tariff
                })
            })
            .catch(e => {
                console.log('ERRORR', e.response.status)
                setIsLoading(false)
                e.response.status === 401 && navigate('/user/login')
                e.response.status === 402 && navigate('/402')
                e.response.status === 403 && navigate('/403')
                e.response.status === 404 && setPassSettings([])
            })

        if ((new Intl.Locale(navigator.language))?.region !== 'UA'){
            setLocale(navigator.language)
            setCurrency('EUR')
            setPoint(15)
        }
    }, [])

    useEffect(() => {
        let paid = 0
        let unpaid = 0
        for (const orderKey in order) {
            if(order[orderKey].active && order[orderKey].paid) {
                setPaid(() => {
                    paid = paid + order[orderKey].point
                    return paid
                })
            }
            if(order[orderKey].active && !order[orderKey].paid) {
                setUnpaid(() => {
                    unpaid = unpaid + order[orderKey].point
                    return unpaid
                })
            }
            if(!order[orderKey].active && !order[orderKey].paid) {
                setUnpaid(() => {
                    unpaid = unpaid
                    return unpaid
                })
            }
        }
    }, [count])

    // console.log(organisationSettings);
    // console.log(order);
    // console.log('paid: ', paid);
    // console.log('unpaid: ', unpaid);

    const handleChange = (event) => {
        const {name, value, type, checked} = event.target
        setCount(prevState => prevState + 1)

        if (type === 'checkbox') {
            setOrder(prevState => {
                const newOrder = prevState
                newOrder[name].active = checked
                checked ? newOrder[name].testTill = Date.now() + 3 * 24 * 3600000 : newOrder[name].testTill = ''

                return newOrder
            })
        } else {
            console.log(name, value, type, checked);
            setOrder(prevState => {
                const newOrder = prevState
                newOrder[value].active = !!value
                newOrder[value].testTill = Date.now() + 3 * 24 * 3600000
                if (value === 'plus4'){
                    newOrder.plus9.testTill = ''
                    newOrder.plus24.testTill = ''
                } else if (value === 'plus9'){
                    newOrder.plus4.testTill = ''
                    newOrder.plus24.testTill = ''
                } else if (value === 'plus24'){
                    newOrder.plus4.testTill = ''
                    newOrder.plus9.testTill = ''
                }

                return newOrder
            })

        }
    }

    const handleSubmit = async () => {
        setIsLoading(true)
        setDisabled(true)
        try {
            await axios.put('/api/passes/settings', passSettings)
                .then(response => {

                    if (response.status !== 200) return setIsLoading(false)
                    setIsLoading(false)
                    setIsInfo(true)
                    setAlert(alerts[language].success)
                    setTimeout(() => {
                        setIsInfo(false)
                        setDisabled(false)
                    }, 2000)

                })
                .catch(e => {
                    console.log('ERRORR', e.response.status)
                    setIsLoading(false)

                    e.response.status === 401 && navigate('/user/login')
                    e.response.status === 402 && navigate('/402')
                    e.response.status === 403 && navigate('/403')
                })
        } catch (e) {
            console.error(e);
        }
    }

    const tariffUrl = `https://loyalcards.com.ua/${language}/tariffs.html`
    const pmntCardUrl3 = `https://next.privat24.ua/money-transfer/form/%7B%22form%22:%7B%22receiver%22:%7B%22source%22:%22manual%22,%22number%22:%225168745613272811%22%7D,%22amount%22:%22${((paid+unpaid)*point)*3}%22,%22currency%22:%22${currency}%22,%22comment%22:%22${organisationSettings?.name}%22%7D%7D`
    const pmntCardUrl10 = `https://next.privat24.ua/money-transfer/form/%7B%22form%22:%7B%22receiver%22:%7B%22source%22:%22manual%22,%22number%22:%225168745613272811%22%7D,%22amount%22:%22${((paid+unpaid)*point)*10}%22,%22currency%22:%22${currency}%22,%22comment%22:%22${organisationSettings?.name}%22%7D%7D`

    return (
        <>
            {isInfo && (
                <div className="alert alert-info" role="alert">
                    {alert}
                </div>
            )}
            {isError && (
                <div className="alert alert-danger" role="alert">
                    {alert}
                </div>
            )}


            <h4 className={"text-primary"}>{header[language].settings}</h4>
            <hr className={"mt-0 mb-0"} />
            <SettingsHeader/>
            <div className="container">
                <div className="row d-flex justify-content-around">
                    <div className='card col-lg-5 mb-5 p-4'>
                        <h4>{billing[language].tariffs}</h4>

                        <div className="form-check form-switch">
                            <label className="form-check-label" htmlFor="base">{billing[language].base}</label>
                            <input
                                className="form-check-input" type="checkbox" role="switch"
                                id="base"
                                name='base'
                                defaultChecked={!organisationSettings?.tariff?.name?.includes('test')}
                                disabled={!organisationSettings?.tariff?.name?.includes('test')}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="form-check form-switch">
                            <label className="form-check-label" htmlFor="loyalty">{billing[language].loyalty}</label>
                            <input
                                className="form-check-input" type="checkbox" role="switch"
                                id="loyalty"
                                name='loyalty'
                                defaultChecked={organisationSettings?.tariff?.name?.includes('loyalty')}
                                disabled={organisationSettings?.tariff?.name?.includes('loyalty')}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="form-check form-switch">
                            <label className="form-check-label" htmlFor="marketing">{billing[language].marketing}</label>
                            <input
                                className="form-check-input" type="checkbox" role="switch"
                                id="marketing"
                                name='marketing'
                                defaultChecked={organisationSettings?.tariff?.name?.includes('marketing')}
                                disabled={organisationSettings?.tariff?.name?.includes('marketing')}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="form-check form-switch">
                            <label className="form-check-label" htmlFor="integration">{billing[language].integration}</label>
                            <input
                                className="form-check-input" type="checkbox" role="switch"
                                id="integration"
                                name='integration'
                                defaultChecked={organisationSettings?.tariff?.name?.includes('integration')}
                                disabled={organisationSettings?.tariff?.name?.includes('integration')}
                                onChange={handleChange}
                            />
                        </div>

                        {/*<div className="form-check form-switch">*/}
                        {/*    <label className="form-check-label" htmlFor="whiteLabel">{billing[language].white_label}</label>*/}
                        {/*    <input*/}
                        {/*        className="form-check-input" type="checkbox" role="switch"*/}
                        {/*        id="whiteLabel"*/}
                        {/*        name='whiteLabel'*/}
                        {/*        defaultChecked={organisationSettings?.whiteLabel?.domen && organisationSettings?.whiteLabel?.link}*/}
                        {/*        disabled={organisationSettings?.tariff?.name?.includes('integration')}*/}
                        {/*        onChange={handleChange}*/}
                        {/*    />*/}
                        {/*</div>*/}

                        <h5>{billing[language].additionalCards}</h5>
                        <div className="form-check form-switch">
                            <label className="form-check-label" htmlFor="plus4">+ 4'000 {billing[language].cards}</label>
                            <input
                                className="form-check-input" type="radio"
                                id="plus4"
                                name='radio'
                                value="plus4"
                                defaultChecked={organisationSettings?.tariff?.name?.includes('5000')}
                                // disabled={organisationSettings?.tariff?.name?.includes('5000')}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-check form-switch">
                            <label className="form-check-label" htmlFor="plus9">+ 9'000 {billing[language].cards}</label>
                            <input
                                className="form-check-input" type="radio"
                                id="plus9"
                                value="plus9"
                                name='radio'
                                defaultChecked={organisationSettings?.tariff?.name?.includes('10000')}
                                // disabled={organisationSettings?.tariff?.name?.includes('10000')}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-check form-switch">
                            <label className="form-check-label" htmlFor="plus24">+ 24'000 {billing[language].cards}</label>
                            <input
                                className="form-check-input" type="radio"
                                id="plus24"
                                name='radio'
                                value="plus24"
                                defaultChecked={organisationSettings?.tariff?.name?.includes('25000')}
                                // disabled={organisationSettings?.tariff?.name?.includes('25000')}
                                onChange={handleChange}
                            />
                        </div>
                        <a href={tariffUrl} target='_blank'><i>{billing[language].tariffDescription}</i></a>
                    </div>


                    <div className='card col-lg-5 mb-5 p-4'>
                        {/*<h4>Платежі</h4>*/}
                        { unpaid ?
                            <div>
                                <h5>{billing[language].nextPayment}: <span
                                    style={{color: "#0d6efd"}}>{new Date(organisationSettings?.tariff?.nextPayment).toLocaleDateString(locale, {
                                    year: "numeric",
                                    month: "short",
                                    day: "numeric"
                                })}</span></h5>
                                <h5>{billing[language].amountQuarter}: <span
                                    style={{color: "#0d6efd"}}>{(((paid + unpaid) * point || organisationSettings?.tariff?.amount) * 3).toLocaleString(locale, {
                                    style: 'currency',
                                    currency
                                })}</span></h5>
                                <h5>{billing[language].amountYear}: <span
                                    style={{color: "#0d6efd"}}>{(organisationSettings?.tariff?.amount * 10).toLocaleString(locale, {
                                    style: 'currency',
                                    currency
                                })}</span></h5>
                                <i style={{color: '#dc3545'}}>ваш поточний тариф діє до {new Date(organisationSettings?.tariff?.nextPayment).toLocaleDateString(locale, {
                                    year: "numeric",
                                    month: "short",
                                    day: "numeric"
                                })}<br/></i>

                            </div> : <div>
                                <h5>{billing[language].nextPayment}: <span
                                    style={{color: "#0d6efd"}}>{new Date(organisationSettings?.tariff?.nextPayment).toLocaleDateString(locale, {
                                    year: "numeric",
                                    month: "short",
                                    day: "numeric"
                                })}</span></h5>
                                <h5>{billing[language].amountQuarter}: <span
                                    style={{color: "#0d6efd"}}>{(((paid + unpaid) * point) * 3).toLocaleString(locale, {
                                    style: 'currency',
                                    currency
                                })}</span></h5>
                                <h5>{billing[language].amountYear}: <span
                                    style={{color: "#0d6efd"}}>{(((paid + unpaid) * point) * 10).toLocaleString(locale, {
                                    style: 'currency',
                                    currency
                                })}</span></h5>
                                <i style={{color: '#dc3545'}}>Будь ласка, сплачуйте послуги вчасно або попередьте про
                                    можливу затримку оплати заздалегідь.<br/>Дякуємо за розуміння.</i>

                            </div>}


                        <h5 className='mt-5'>{billing[language].askBill}</h5>
                        <button
                            disabled={disabled}
                            className='btn btn-outline-primary'
                            onClick={handleSubmit}>
                            {billing[language].askQuarterBill}
                        </button>
                        <button
                            disabled={disabled}
                            className='btn btn-outline-primary'
                            onClick={handleSubmit}>
                            {billing[language].askYearBill}
                        </button>
                        <h5 className='mt-5'>{billing[language].payByCard}</h5>
                        <button
                            disabled={disabled}
                            className='btn btn-outline-primary'
                            onClick={() => { window.location.href = pmntCardUrl3}}>
                            {billing[language].payByCardQuarter}
                        </button>
                        <button
                            disabled={disabled}
                            className='btn btn-outline-primary'
                            onClick={() => { window.location.href = pmntCardUrl10}}>
                            {billing[language].payByCardYear}
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}
